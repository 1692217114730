import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import Header from '../Layouts/Header';
import MenuSidebar from '../Layouts/MenuSidebar';
import $ from 'jquery';
import Select from 'react-select';
import { lang } from '../Helpers/lang';
import { PageTitle, CheckAuth } from "../Helpers/SettingHelper";


import {GET_ADDVEHICLE }  from '../../actions'; 


const initialState = {
			vehicle_type:'',
			vehicle_passenger:'',
			vehicle_lagguage:'',
			selectedvalue: '',
            selectedOption:{ value: 'active', label: 'Active'},
			statusmessage:'',
			Loading:false
		};
		
class Add extends Component {
	
	constructor(props)
	{
		CheckAuth();
		super(props);	
		this.state =  { ...initialState }
		
		this.handleInputChange = this.handleInputChange.bind(this);
	   this.handleChange = this.handleChange.bind(this);
	   this.handleReset = this.handleReset.bind(this);

    }
	
	
	handleReset() {
         this.setState(initialState);
		 
    }

    handleChange = selectedOption => {
        this.setState({selectedOption})
        this.setState({  selectedvalue : selectedOption.value});
    };
   handleInputChange(event) {

      const {name, value} = event.target;      
      this.setState({
          [name]: value
        });
    }

	componentDidMount() {
		document.title = PageTitle('Vehicle Add');
    }


        handleFormSubmit = () => {
			if(this.validateForm()){
				this.setState({Loading:true});
				const formPayload = this.state;
				var qs = require('qs');
				var status = '';
				if(formPayload.selectedvalue === '' && formPayload.selectedvalue!== 0){
				status =formPayload.selectedOption.value;
				}else{
				status = formPayload.selectedvalue;
				}
				var postObject = {
					admin_id : localStorage.getItem("admin_id"),
					vehicle_type: formPayload.vehicle_type,
					vehicle_passenger:formPayload.vehicle_passenger,
					vehicle_lagguage:formPayload.vehicle_lagguage,
					vehicle_status:status,
				};
				this.props.getAddVehicle(qs.stringify(postObject));
			}
	}

	validateForm() {
		const {vehicle_type,vehicle_lagguage,vehicle_passenger} = this.state;
		let errors = {};
      	let formIsValid = true;
	
		if (!vehicle_type) {
			formIsValid = false;
			$('.errorvehicle_type').html('<span class="errorspan">Please fill the field</span>');
		}else if(vehicle_type){
			$('.errorvehicle_type').html('');
		}

		if (!vehicle_lagguage) {
			formIsValid = false;
			$('.errorvehicle_lagguage').html('<span class="errorspan">Please fill the field</span>');
		}else if(vehicle_lagguage){
			$('.errorvehicle_lagguage').html('');
		}

		if (!vehicle_passenger) {
			formIsValid = false;
			$('.errorvehicle_passenger').html('<span class="errorspan">Please fill the field</span>');
		}else if(vehicle_passenger){
			$('.errorvehicle_passenger').html('');
		}




		this.setState({
			errors: errors
		});
		return formIsValid;
    }

    componentWillReceiveProps(Props){

   	if(Props.vehicleadd !== this.props.vehicleadd){
    		if(Object.keys(Props.vehicleadd).length > 0){
				this.setState({Loading:false});

					if(Props.vehicleadd[0].status === "success"){

						$('.success_message').html('<div class="status_sucess"><h3>'+ Props.vehicleadd[0].message+'</h3></div>');
						setTimeout(
						function() {
							$('.success_message').html('');
							this.handleReset();
						}
						.bind(this),
						3000
						);
					}else{
						$('.success_message').html('<div class="status_sucess"><h3>'+ Props.vehicleadd[0].message+'</h3></div>');
						setTimeout(
						function() {
							$('.success_message').html('');
						}
						.bind(this),
						3000
						);
					}

    		}
    	}
    }
	
  render() {
  	  const {selectedOption} = this.state;
    return (
      <div className="wrapper"> 
 
	  <Header />
    <MenuSidebar currentpage="vehiclelist" />  

	
	<div className="content">	
		<div className="content-wrapper">
			<div className="form-wrapper">
			<div className="success_message"></div>
			<div className="title">
				<h4>Add Vehicle Type</h4>				
			</div>
			 <form className="edit" id="edit" onSubmit={ e => { this.handleFormSubmit(this); e.preventDefault(); }}>
			<div className="form-row">
			<div className="form-left">
				<div className="form-group">
					<label>Vehicle Type:</label>
				    <input type="text" name="vehicle_type" onChange={this.handleInputChange} className="form-control" value={this.state.vehicle_type} />
					<div className="errorvehicle_type"></div>
				</div>
				<div className="form-group">					
					<label>No of Passengers:</label>
						<input type="number" name="vehicle_passenger" onChange={this.handleInputChange} className="form-control" value={this.state.vehicle_passenger} />
					<div className="errorvehicle_passenger"></div>
				</div>
			</div>
			<div className="form-right">	
				<div className="form-group">
					<label>No of Luggages:</label>
						<input type="number" name="vehicle_lagguage" onChange={this.handleInputChange} className="form-control" value={this.state.vehicle_lagguage} />
					<div className="errorvehicle_lagguage"></div>
				</div>
				<div className="form-group">					
					<label>Status:</label>
						   <Select 
						   value={selectedOption?selectedOption:{ value: 'active', label: 'Active'}}
                            options={lang.common.status_option} 
                            onChange={this.handleChange}
                            />
					<div className="erroradmin_email_address"></div>
				</div>
			</div>	
			</div>		

			<div className="btn-group export">	
				{/*  <button className="btn btn_orange btn_minwid login_submit animate-btn2" type="button" onClick={this.handleFormSubmit.bind(this)} disabled={(this.state.Loading ===true)?true:false}> {this.state.Loading ===true &&
                                        <span className="load-data"></span> 
                                        }Submit
                   </button>*/}
                    <button className="btn btn_orange btn_minwid login_submit animate-btn2" type="submit" disabled={(this.state.Loading ===true)?true:false}> {this.state.Loading ===true &&
                                        <span className="load-data"></span> 
                                        }Submit
                   </button>
			</div>
			</form>
			</div>
				
		</div>	
	</div>
		 	
    </div>
    );
  }
}


const mapStateTopProps = (state) => {
  return {
  	vehicleadd: state.vehicleadd
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
	 getAddVehicle: (formPayload) =>{
       dispatch({ type: GET_ADDVEHICLE, formPayload});
    }
  }
}
export default connect(mapStateTopProps, mapDispatchToProps)(withRouter(Add));