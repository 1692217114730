import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import Header from '../Layouts/Header';
import MenuSidebar from '../Layouts/MenuSidebar';
import $ from 'jquery';
import Select from 'react-select';
import { lang } from '../Helpers/lang';
import { PageTitle, CheckAuth } from "../Helpers/SettingHelper";


import {GET_EDITCOMPANY,GET_COMPANYDETAIL  }  from '../../actions'; 

class Edit extends Component {
	
	constructor(props)
	{
		CheckAuth();
		super(props);	
	    const CompanyId = (typeof this.props.match.params.ID === 'undefined') ? '' : this.props.match.params.ID;
		this.state = {
			admin_company_name:'',

			admin_fname:'',
			admin_username:'',
			admin_contact:'',
			admin_email_address:'',
			selectedvalue: '',
            selectedOption:{ value: 'active', label: 'Active'},
			statusmessage:'',
			c_company_id: CompanyId,
			Loading:false
		};
		this.props.getCompanyDetail(CompanyId);
		this.handleInputChange = this.handleInputChange.bind(this);
	    this.handleChange = this.handleChange.bind(this);
		//console.log(this.state.c_company_id+"-test");

    }
	

    handleChange = selectedOption => {
        this.setState({selectedOption})
        this.setState({  selectedvalue : selectedOption.value});
    };
   handleInputChange(event) {

      const {name, value} = event.target;      
      this.setState({
          [name]: value
        });
    }

	componentDidMount() {
		document.title = PageTitle('Company Edit');
    }


        handleFormSubmit = () => {
			if(this.validateForm()){ 
				this.setState({Loading:true});
				const formPayload = this.state;
				var qs = require('qs');
				var status = '';
				if(formPayload.selectedvalue === '' && formPayload.selectedvalue!== 0){
				status =formPayload.selectedOption.value;
				}else{
				status = formPayload.selectedvalue;
				}
				var postObject = {
					admin_id : localStorage.getItem("admin_id"),
					c_company_id : formPayload.c_company_id,
					admin_company_name: formPayload.admin_company_name,					
					admin_username:formPayload.admin_username,
					admin_contact:formPayload.admin_contact,
					admin_email_address:formPayload.admin_email_address,					
					admin_status:status,
				};
				//console.log(postObject);
				this.props.getEditCompany(qs.stringify(postObject));
			}
	}

	validateForm() {
		//const {admin_company_name,admin_fname} = this.state;
		const {admin_company_name,admin_fname,admin_username,admin_email_address,admin_contact} = this.state;
		let errors = {};
      	let formIsValid = true;
	
		if (!admin_company_name) {
			formIsValid = false;
			$('.erroradmin_company_name').html('<span class="errorspan">Please fill the field</span>');
		}else if(admin_company_name){
			$('.erroradmin_company_name').html('');
		}	

		if (!admin_username) {
			formIsValid = false;
			$('.erroradmin_username').html('<span class="errorspan">Please fill the field</span>');
		}else if(admin_username){
			$('.erroradmin_username').html('');
		}				

		
		if (!admin_contact) {
			formIsValid = false;
			$('.erroradmin_username').html('<span class="errorspan">Please fill the field</span>');
		}else if(admin_contact){
			$('.erroradmin_contact').html('');
		}

		if (!admin_email_address) {
			formIsValid = false;
			$('.erroradmin_email_address').html('<span class="errorspan">Please fill the field</span>');
		}else if(admin_email_address){
			$('.erroradmin_email_address').html('');
		}


		this.setState({
			errors: errors
		});
		return formIsValid;
    }

    componentWillReceiveProps(Props){

   	if(Props.companydetail !== this.props.companydetail){
    		if(Object.keys(Props.companydetail).length > 0){
				this.setState({Loading:false});

					if(Props.companydetail[0].status === "success"){

						const formdata = Props.companydetail[0].companydetail;
						if(formdata.admin_status === 'Yes'){
							this.setState({selectedOption:{value: 'active', label: 'Active'}});
						}else{
							this.setState({selectedOption:{value: 'inactive', label: 'Inactive'}});

						}
						this.setState({admin_company_name:formdata.admin_company_name});
						
						//this.setState({c_company_id: formdata.c_company_id});						
						this.setState({admin_contact: formdata.admin_contact});
						this.setState({admin_email_address: formdata.admin_email_address});
						this.setState({admin_username: formdata.admin_username});
					}
    		}
    	}
    	if(Props.companyedit !== this.props.companyedit){
    		if(Object.keys(Props.companyedit).length > 0){
				if(Props.companyedit[0].status === "success"){
    					this.setState({ Loading: false });

    			const formpayload = Props.companyedit[0].companydetail;
				if(formpayload.admin_status === 'Yes'){
					this.setState({selectedOption:{value: 'active', label: 'Active'}});
				}else{
				this.setState({selectedOption:{value: 'inactive', label: 'Inactive'}});

				}
				this.setState({admin_company_name:formpayload.admin_company_name});
				
				//this.setState({c_company_id: formpayload.c_company_id});
				this.setState({admin_contact: formpayload.admin_contact});
				this.setState({admin_email_address: formpayload.admin_email_address});
				this.setState({admin_username: formpayload.admin_username});
               	$('.success_message').html('<div class="status_sucess"><h3>'+ Props.companyedit[0].message+'</h3></div>');


    					setTimeout(
						function() {
							$('.success_message').html('');
						}
						.bind(this),
						3000
						);
    		}else{
					this.setState({ Loading: false });
					$('.success_message').html('<div class="status_sucess"><h3>'+ Props.companyedit[0].message+'</h3></div>');
    					setTimeout(
						function() {
							$('.success_message').html('');
						}
						.bind(this),
						3000
						);
			}
			}
    	}
		
    }
	
  render() {
  	  const {selectedOption} = this.state;
    return (
      <div className="wrapper"> 
 
	  <Header />
    <MenuSidebar currentpage="companydetail" />  

	
	<div className="content">	
		<div className="content-wrapper">
			<div className="form-wrapper">
			<div className="success_message"></div>
			<div className="title">
				<h4>Edit Details</h4>
			</div>
			 <form className="edit" id="edit" onSubmit={ e => { this.handleFormSubmit(this); e.preventDefault(); }}>
			<div className="form-row">
			<div className="form-left">
				<div className="form-group">
					<label>Company Name:</label>
				    <input type="text" name="admin_company_name" onChange={this.handleInputChange} className="form-control" value={this.state.admin_company_name} />
					<div className="erroradmin_company_name"></div>
				</div>
				
				
				<div className="form-group">					
					<label>Contact:</label>
						<input type="text" name="admin_contact" onChange={this.handleInputChange} className="form-control" value={this.state.admin_contact} />
					<div className="erroradmin_contact"></div>
				</div>
				<div className="form-group">
					<label>Email:</label>
						<input type="text" name="admin_email_address" onChange={this.handleInputChange} className="form-control" value={this.state.admin_email_address} />
					<div className="erroradmin_email_address"></div>
				</div>
				
			</div>	
			<div className="form-right">
				<div className="form-group">
					<label>User Name:</label>
				    <input type="text" name="admin_username" onChange={this.handleInputChange} className="form-control" value={this.state.admin_username} />
					<div className="erroradmin_username"></div>
				</div>
				<div className="form-group">					
					<label>Status:</label>
						   <Select 
						   value={selectedOption?selectedOption:{ value: 'active', label: 'Active'}}
                            options={lang.common.status_option} 
                            onChange={this.handleChange}
                            />
					<div className="erroradmin_email_address"></div>
				</div>
			</div>
			</div>		

			<div className="btn-group export">	
				  <button className="btn btn_orange btn_minwid login_submit animate-btn2" type="submit" disabled={(this.state.Loading ===true)?true:false}> {this.state.Loading ===true &&
                                        <span className="load-data"></span> 
                                        }Submit
                   </button>
			</div>
			</form>
			</div>				
		</div>	
	</div>
		 	
    </div>
    );
  }
}


const mapStateTopProps = (state) => {
  return {
   companydetail: state.companydetail,
   companyedit: state.companyedit
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getCompanyDetail: (CompanyId) =>{
       dispatch({ type: GET_COMPANYDETAIL,CompanyId });
    },
     getEditCompany: (formPayload) =>{
       dispatch({ type: GET_EDITCOMPANY, formPayload});
    }
  }
}
export default connect(mapStateTopProps, mapDispatchToProps)(withRouter(Edit));