import React, { Component } from 'react';
import { Link, withRouter } from "react-router-dom";
import { connect } from 'react-redux';

import $ from 'jquery';
import {
  Row,
  Col,
  Modal,
  Table,
  OverlayTrigger,
  ButtonToolbar,
  Tooltip,
  Button
} from "react-bootstrap";
import Header from '../Layouts/Header';
import MenuSidebar from '../Layouts/MenuSidebar';
import Pagination from "react-js-pagination";
import { GET_COMPANYLIST  } from '../../actions';

class List extends Component {
  
  constructor(props)
  {
    super(props); 
    this.state = {
      companylist:'',
	  activePage:1
    };
    //this.props.getCompanyList();
	
	var admin_id = localStorage.getItem("admin_id");
      var qs = require('qs');
      var postobject = {
        activePage: 1,
        search_all: '',
        admin_id: admin_id,		
		totalRecord: ''		
      };
	  this.props.getCompanyList(qs.stringify(postobject));
	  
	  //this.handleInputChange = this.handleInputChange.bind(this);
      this.handleFormSubmit = this.handleFormSubmit.bind(this);
	  
    }

   
   componentDidMount() {
   }
   
    handlePageChange =(pageNumber) => {
     // console.log(`active page is ${pageNumber}`);
      this.setState({activePage: pageNumber});
      var qs = require('qs');
      var admin_id = localStorage.getItem("admin_id");
      var postobject = {
        activePage: pageNumber,
        search_all: this.state.search_all,
        admin_id: admin_id
      };
    this.props.getCompanyList(qs.stringify(postobject))
    }
	
	handleInputChange=(event)=> {
		const {name, value} = event.target;      
		this.setState({
		  [name]: value
		});
    
	}
	
	handleFormSubmit() {
		 const formPayload = this.state;
		 
        console.log(formPayload.activePage,'activePage')
          this.setState({Loading:true});
          var qs = require('qs');
         
          var admin_id = localStorage.getItem("admin_id");
          var postObject = {             
              activePage : this.state.activePage,
              search_all : this.state.search_all,
              admin_id  : admin_id         
          };
          //console.log(postObject);
          this.props.getCompanyList(qs.stringify(postObject));
        
    }


   componentWillReceiveProps(Props){
console.log(Props.companylist[0].records_count,'Props.companylist[0].records_count')
    if (Object.keys(Props.companylist).length > 0) {
      if (Props.companylist[0]["status"] === "success") {
        this.setState({
          companylist: Props.companylist[0]["companylist"],
        });
		
		this.setState({ totalRecord : Props.companylist[0].records_count, companylist: Props.companylist[0]["companylist"] }, function() {
            this.companylist();
          });
      }
    }
	
	
     
   }

  companylist() {
    var companylist = this.props.companylist[0];
    if (companylist != "undefined" && companylist != null) {
      if (companylist.status == "success") {
        if (Object.keys(companylist).length > 0) {
          const companylistDetails = companylist.companylist.map(
            (companylist, Index) => {
              let sno = Index+1;
              if(companylist.admin_status == 'Yes'){
                var status = 'Active';
              }else{
                  status = 'Inactive';
              }
              return (
                <tr key={companylist.admin_id}>
					<td>{sno}</td>
					<td>
					{companylist.admin_company_name}
					</td>                 
					<td>{companylist.admin_email_address}</td>
					<td>{companylist.admin_contact}</td>
				
					<td>{companylist.admin_username}</td>
					
                   <td>{status}</td>
                  <td className="actiongroup">

                    <Link to={{ pathname: '/company-edit/'+companylist.admin_id}} className="" title="Edit">  <i className="fa fa-edit" aria-hidden="true"></i></Link>
                   
                  </td>
                </tr>
              );
            }
          );
          return companylistDetails;
        }
      } else {
        return (
          <tr>
            <td colspan="7" className="v-align-nr">
              No Result
            </td>
          </tr>
        );
      }
    }
  }
  
  render() {
    return (
      <div className="wrapper"> 
 
    <Header />
    <MenuSidebar currentpage="companylist" />  

  
  <div className="content"> 
    <div className="content-wrapper">
    <div className="car-listing-wrap">

        <div className="listing-header"> 
          <div className="title">
            <h3>Companies</h3>
          </div>

			<div className="search-list">
			 <form className="edit" id="edit" onSubmit={ e => { this.handleFormSubmit(this); e.preventDefault(); }} >     
				<div className="search-inner">
				  <input type="text" name="search_all" onChange={this.handleInputChange} className="form-control" value={this.state.search_all} placeholder="Search" /> 
					<button className="" type="submit">Submit</button>
					<div className="erroremail"></div>
				</div>
			 </form>
            </div>
				
          <div className="add_button">  
              <a className="add" href='/company-add'>Add</a> 
          </div>
        </div>
		
		
        <div className="car-listing-row">
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>S.No</th>
                <th>Company</th>               
				<th>Email</th>
				<th>Contact</th>
				<th>Username</th>
				
               
                <th>Status</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>{this.companylist()}</tbody>
          </Table>
		  <div className="text-center">
            <Pagination
            activePage={this.state.activePage}
            itemsCountPerPage={10}
            totalItemsCount={this.state.totalRecord}
            pageRangeDisplayed={5}
            onChange={this.handlePageChange.bind(this)}
            />
          </div>
        </div>
                      
      </div>  
    </div>  
  </div>
      
    </div>
    );
  }
}

const mapStateTopProps = (state) => {
  return {
    companylist: state.companylist,
   // deletecompany: state.deletecompany

  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getCompanyList: (formVal) =>{
       dispatch({ type: GET_COMPANYLIST, formVal });
    },
   
  }
}
export default connect(mapStateTopProps, mapDispatchToProps)(withRouter(List));