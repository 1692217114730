import { SET_IMPORTDRIVER } from '../actions';

const driverimport = (state = [], action) => {
  switch (action.type) {
	case SET_IMPORTDRIVER:
      return [...action.value];  
    default: return state;
  }
}

export default driverimport;
