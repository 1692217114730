import React, { Component } from 'react';
import { Link, withRouter } from "react-router-dom";
import { connect } from 'react-redux';
import Pagination from "react-js-pagination";
import $ from 'jquery';
import {
  Row,
  Col,
  Modal,
  Table,
  OverlayTrigger,
  ButtonToolbar,
  Tooltip,
  Button
} from "react-bootstrap";
import Header from '../Layouts/Header';
import MenuSidebar from '../Layouts/MenuSidebar';
import { scrollToTopValidate,PageTitle } from "../Helpers/SettingHelper";

import { GET_BOOKINGHISTORY } from '../../actions';

class List extends Component {
  
  constructor(props)
  {
    super(props); 
    this.state = {
        bookinghistory:'',
        editable: false,
        priorityid:'',
        priorityvalue:'',
        activePage: 1,
        totalRecord: '',
        search_all:''
    };
	
    var qs = require('qs');
    var postobject = {
    	activePage: 1,
    	search_all: '',
      oldrecords:'',
      admin_id: localStorage.getItem('admin_id')
    };
    this.props.getBookingHistory(qs.stringify(postobject));
	
	
	 this.handleInputChange = this.handleInputChange.bind(this);
	 this.handleFormSubmit = this.handleFormSubmit.bind(this);
	 this.capitalize = this.capitalize.bind(this);
    }

   
   componentDidMount() {
      document.title = PageTitle('Car List');
      if(localStorage.getItem('admin_id') === null){
      this.props.history.push('/');
      }
   }


   componentWillReceiveProps(Props){
    	if(Props.bookinghistory !== undefined && Props.bookinghistory !== this.state.bookinghistory) {
    		if(Object.keys(Props.bookinghistory).length > 0){
          this.setState({bookinghistory:''})
          this.setState({ totalRecord : Props.bookinghistory[0].records_count, bookinghistory: Props.bookinghistory[0].records }, function() {
            this.vehiclelist();
          });
    		}
    	}
   }

deletevehicle(id){
        var qs = require('qs');
        var postObject = {
          admin_id : localStorage.getItem("admin_id"),
          car_id  :id
        };
      this.props.getDeleteCar(qs.stringify(postObject));
}
handleChange = (name,carid,event) => {
  this.setState({priorityid: name,priorityvalue: event.target.value})
   var qs = require('qs');
        var postObject = {
          admin_id: localStorage.getItem('admin_id'),
          value :event.target.value,
          car_id  :carid
    };
  this.props.getPriorityUpdate(qs.stringify(postObject));
}



handlePageChange(pageNumber) {
   // console.log(`active page is ${pageNumber}`);
    this.setState({activePage: pageNumber});
    var qs = require('qs');
	var postobject = {
    admin_id: localStorage.getItem('admin_id'),
		activePage: pageNumber,
		search_all: this.state.search_all
	};
	this.props.getBookingHistory(qs.stringify(postobject))
  }
  
handleInputChange(event) {
  const {name, value} = event.target;      
  this.setState({
	  [name]: value
	});
  
}
 handleFormSubmit = () => {
			
				this.setState({Loading:true});
				var qs = require('qs');
				const formPayload = this.state;
			   
				var postObject = {	
          admin_id: localStorage.getItem('admin_id'),
					activePage   : 1,
					search_all : formPayload.search_all					
				};
				//console.log(postObject);
				this.props.getBookingHistory(qs.stringify(postObject));
			
	}  
  
  capitalize(s) {
	  if(s=="tostart"){
		  s="running";
	  }
    return s[0].toUpperCase() + s.slice(1);
}

  vehiclelist() {
	 
		var ToDate = new Date();
	  
    var bookinglist = this.state.bookinghistory;
    if (bookinglist != "undefined" && bookinglist != null) {
        if (Object.keys(bookinglist).length > 0) {
          const vehiclelistDetails = bookinglist.map(
            (bookinglist, Index) => {
              let sno = Index+1;
			   let bookingDate = bookinglist.date;
			   let showAssign = false;
			   if (new Date(bookingDate).getTime() >= ToDate.getTime()) {
					 showAssign = true;
				}
	 
              return (
                <tr key={bookinglist.id}>
                <td>{sno}</td>
                <td> {bookinglist.username?bookinglist.username:'Guest User'}</td>
                <td>{bookinglist.booking_id}</td>
                <td>{bookinglist.from_location}</td>
                <td>{bookinglist.to_location}</td>
                <td>{bookinglist.driver_fname != ''?bookinglist.driver_fname:"-"}</td>
                <td>{bookinglist.driver_mobile != ''?bookinglist.driver_mobile:"-"}</td>
                <td>{this.capitalize(bookinglist.ride_status)}</td>
                <td>{bookinglist.car_name}</td>
                <td>${bookinglist.paid_amount}</td>
                <td>{(bookinglist.final_discount !='')?bookinglist.final_discount:'-'}</td>
                <td>{(bookinglist.discounted_type !='')?bookinglist.discounted_type:'-'}</td>
				<td className="actiongroup">	
                 {(bookinglist.ride_status =='pending' && showAssign == true)? 
                    <Link to={{ pathname: '/car/assign_driver/'+bookinglist.id}} className="" title="Assign to driver">  <i className="fa fa-user" aria-hidden="true"></i></Link>:''
				 }
                  </td>
                </tr>
              );
            }
          );
          console.log(vehiclelistDetails)
          return vehiclelistDetails;
        } else {
        return (
          <tr>
            <td colspan="11" className="v-align-nr">
              No Result
            </td>
          </tr>
        );
      }
    }
  }
  
  render() {
    console.log(this.state.totalRecord)
    return (
      <div className="wrapper"> 
 
    <Header />
    <MenuSidebar currentpage="bookinghistory" />  
      
      <div className="content"> 
        <div className="content-wrapper">
            <div className="car-listing-wrap">

              <div className="listing-header">
                <div className="title">
                  <h3>Rides History</h3>
                </div>
          				<div className="search-list">
          				 <form className="edit" id="edit" onSubmit={ e => { this.handleFormSubmit(this); e.preventDefault(); }} >     
          				  <div className="search-inner">
          						<input type="text" name="search_all" onChange={this.handleInputChange} className="form-control" value={this.state.search_all} placeholder="Search" />	
          				   <button className="" type="submit">Submit</button>
          					<div className="erroremail"></div>
          				</div>
				  </form>
                </div>
              </div>            
              {/*<div className="statusmessage"></div>*/}

              <div className="car-listing-row">
                <Table striped bordered hover>
                  <thead>
                    <tr>
                      <th>S.NO</th>
                      <th>Rider</th>
                      <th>Booking ID</th>
                      <th style={{width: '180px'}}>From Location</th>                     
                      <th style={{width: '180px'}}>To Location</th>
					  <th>Driver Info</th>
					  <th>Driver Mobile</th>
					  <th>Ride Status</th>
                      <th>Selected Car</th>
                      <th>Ride Amount</th>
                      <th>Discount Applied</th>
                      <th>Discount Type</th>
                     <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>{this.vehiclelist()}</tbody>
                </Table>
				<div className="text-center">
				 <Pagination
          activePage={this.state.activePage}
          itemsCountPerPage={10}
          totalItemsCount={this.state.totalRecord}
          pageRangeDisplayed={5}
          onChange={this.handlePageChange.bind(this)}
        />
		</div>
		
		
              </div>

            </div> 
        </div>  
      </div>
      
    </div>
    );
  }
}

const mapStateTopProps = (state) => {
  return {
    bookinghistory: state.bookinghistory,
  }
  
}

const mapDispatchToProps = (dispatch) => {
  return {
    getBookingHistory: (formVal) =>{
       dispatch({ type: GET_BOOKINGHISTORY, formVal });
    },
  }
}
export default connect(mapStateTopProps, mapDispatchToProps)(withRouter(List));