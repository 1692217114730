import { SET_COMPANYDETAIL } from '../actions';

const companydetail = (state = [], action) => {
  switch (action.type) {
	case SET_COMPANYDETAIL:
      return [...action.value];  
    default: return state;
  }
}

export default companydetail;
