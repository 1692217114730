import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import Header from '../Layouts/Header';
import MenuSidebar from '../Layouts/MenuSidebar';
import $ from 'jquery';
import Select from 'react-select';
import { lang } from '../Helpers/lang';
import { PageTitle, CheckAuth, scrollToTopValidate } from "../Helpers/SettingHelper";
import { sampleFileUrl} from'../Config/Config';
import { Scrollbars } from 'react-custom-scrollbars';

import { GET_IMPORTUSER,GET_COMPANYLIST,GET_DOMAINLIST }  from '../../actions'; 

class ImportUser extends Component {
	
	constructor(props)
	{
		CheckAuth();
		super(props);	
		this.state = {
			
			status:'',
		    selectedFile: null,
			Loading:false,
			selectedvalue: '',
            selectedOption:{ value: 'active', label: 'Active'},
			companylist:[],
			selectedCompany:'',
			companyvalue:'',
			domainlist:'',
			selectedDomain:'',
			domainvalue:''
		};
		
		
	
		var admin_id = localStorage.getItem("admin_id");
		var qs = require('qs');
		var postobject = {
		activePage: 1,
		search_all: '',
		admin_id: admin_id,		
		totalRecord: ''		
		};
		this.props.getCompanyList(qs.stringify(postobject));

		  
	   this.handleInputChange = this.handleInputChange.bind(this);
	   this.handleChange = this.handleChange.bind(this);
    }
	
   handleInputChange(event) {

      const {name, value} = event.target;      
      this.setState({
          [name]: value
        });
    }

     handleChange = selectedOption => {
        this.setState({selectedOption})
        this.setState({  selectedvalue : selectedOption.value});
    };
	componentDidMount() {
		document.title = PageTitle('Import User');
    }

  onFileChange = event => { 
     
      // Update the state 
      this.setState({ selectedFile: event.target.files[0] }); 
     
    }; 

/*  onChangeHandler=event=>{
    let reader = new FileReader();
    const file = event.target.files[0];
    reader.onloadend = () => {
      this.setState({
        user_image_name: file.name,
        user_image_preview: reader.result,
        image: file
      });
    };
    reader.readAsDataURL(file);
  }*/

	 handleFormSubmit = () => {
			if(this.validateForm()){
				this.setState({Loading:true});
				 const config = {
			        headers: {
			          'Content-Type': 'multipart/form-data'
			        }
			      };

				const formPayload = this.state;
				var qs = require('qs');
				var status = '';
				if(formPayload.selectedvalue === '' && formPayload.selectedvalue!== 0){
				status =formPayload.selectedOption.value;
				}else{
				status = formPayload.selectedvalue;
				}
				var admin_id = localStorage.getItem("admin_id");
				var postObject = {
					admin_id     : admin_id,
					companyvalue : formPayload.companyvalue,
					domain_id    : formPayload.domainvalue,
					selectedFile : formPayload.selectedFile,
					status       : status,
				};

				let formData = new FormData();
				for(let k in postObject) {
				formData.append(k, postObject[k]);
				}
				this.props.getImportUser(formData,config);
				//this.props.getAddCar(qs.stringify(postObject));
			}
	}

	validateForm() {

		const {selectedFile} = this.state;

		let errors = 0;


		if (selectedFile === null || selectedFile === '') {
		$('.errorfile').html('<span class="errorspan">Please choose the file</span>');
		errors++;
		}else if(selectedFile){
	    var filePath = selectedFile.name;
	    var allowedExtensions =  
                    /(\.csv)$/i; 
        if(!allowedExtensions.exec(filePath)){
        	errors++;
		    $('.errorfile').html('<span class="errorspan">Please choose only csv format</span>');
        }else{
            $('.errorfile').html('');
        } 
        }else{             
		$('.errorfile').html('');
		}



		if(this.state.companyvalue === ''){
		errors++;
		  $('.errorcompany').html('<span class="errorspan">Please choose company</span>');
		  }else{
		 $('.errorcompany').html('');
		}

		if(this.state.domainvalue === ''){
		errors++;
		$('.errordomain').html('<span class="errorspan">Please choose domain</span>');
		}else{
		$('.errordomain').html('');
		}
		if(errors>0) {
		setTimeout(function () {
		scrollToTopValidate();
		}, 100);
		return false;
		}
		else {
		return true;
		}

		/*this.setState({
			errors: errors
		});
		return formIsValid;*/
    }

    componentWillReceiveProps(Props){
		
		
			$(document).on('click','.close',function(e){
				console.log('gfufu');
			   e.stopPropagation();
			   $(this).parent().remove();	
			});
 
    	 	if(Props.userimport !== this.props.userimport){
    		if(Object.keys(Props.userimport).length > 0){
				this.setState({Loading:false});
					if(Props.userimport[0].status === "success"){
		            	$('.success_message').html('<div class="status_sucess"><h3>'+Props.userimport[0].message+'</h3></div>');
						setTimeout(
						function() {
							$('.success_message').html('');
						}
						.bind(this),
						3000
						);
					}else{
						$('.success_message').html('<div class="status_sucess"><span class="close">X</span><div class="custom-scroll"><p class="error-txt">'+Props.userimport[0].message+'</p></div></div>');
						/*setTimeout(
						function() {
							$('.success_message').html('');
						}
						.bind(this),
						3000
						);*/
					}

    		}
    	}
		//console.log(Props,'companylist')
		if (Object.keys(Props.companylist).length > 0) {
	      if (Props.companylist[0]["status"] === "success") {
			  //console.log(Props.companylist[0])
	        this.setState({
	          companylist: Props.companylist[0]["companylist"],
	        });
	      }
	    }


	    if (Object.keys(Props.domainlist).length > 0) {
	      if (Props.domainlist[0]["status"] === "success") {
			  //console.log(Props.companylist[0])
	        this.setState({
	          domainlist: Props.domainlist[0]["domainlist"],
	        });
	      }
	    }

      }

    handleChangeCompany = selectedCompany => {
      this.setState({ selectedCompany});
      this.setState({ companyvalue : selectedCompany.value });
      this.setState({selectedDomain:''})
      if(selectedCompany.value!== ''){
      	this.props.getDomainList(selectedCompany.value);
      }
   }
   
   handleChangeDomain = selectedDomain => {
      this.setState({ selectedDomain});
      this.setState({ domainvalue : selectedDomain.value }); 
       $('.errordomain').html('');
   }

  render() {
	var FileUrl = sampleFileUrl+"/Sample.csv";
	  
    const companyArray = this.state.companylist.map ((companyname, index) => ({
	id: index,
	name: companyname,
	}));
	//console.log(this.state.companylist)
	let companies = [];
	// Dynamically create select list
	companyArray.map(item =>
	companies.push({ label: item.name.admin_company_name, value: item.name.admin_id }),
	);

  	const {selectedOption,selectedCompany,selectedDomain} = this.state;


    return (
      <div className="wrapper"> 
 
	  <Header />
    <MenuSidebar currentpage="user" />  

	<div className="content">	
		<div className="content-wrapper">
			<div className="form-wrapper">

			<div className="success_message"></div>
			<div className="title">
				<h4>Import User</h4>
			</div>
		<form className="login" id="login" onSubmit={ e => { this.handleFormSubmit(this); e.preventDefault(); }}>
			<div className="form-row import-user-record">
			<div className="form-left">		    	
			
               <div className="form-group">
				 <label>File: </label>
				 <input type="file" onChange={this.onFileChange} /> 
                <div className="errorfile"></div> 
                </div>				
				
				<div className="form-group">
					<label>Company Name:</label>
				     <Select 
                         options={companies}  
                         value={selectedCompany?selectedCompany:{ value: '0', label: 'Select Company' }}
                         onChange={this.handleChangeCompany}
                         placeholder="Select Company" />
						   <div className="errorcompany"></div> 
				</div>				
				<div className="form-group domainlist">
					<label>Domain List:</label>
				     <Select 
                         options={this.state.domainlist}  
                         value={selectedDomain?selectedDomain:{ value: '0', label: 'Select Domain' }}
                         onChange={this.handleChangeDomain}
                         placeholder="Select Domain" />
						   <div className="errordomain"></div> 
				</div>
			</div>				

			</div>		

			<div className="btn-group export">	
				   <button className="btn btn_orange btn_minwid login_submit animate-btn2" type="submit" disabled={(this.state.Loading ===true)?true:false}> {this.state.Loading ===true &&
                                        <span className="load-data"></span> 
                                        }Submit
                   </button>
			</div>
			</form>
			<div className="download">
	        <a className="" href={FileUrl} target="_blank" download >Sample File <i class="fa fa-download" aria-hidden="true"></i></a>
            </div>
			</div>
  
		
				
		</div>	
	</div>
		 	
    </div>
    );
  }
}


const mapStateTopProps = (state) => {
  return {
  	userimport     : state.userimport,
	companylist: state.companylist,
	domainlist: state.domainlist
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getImportUser: (formPayload) =>{
       dispatch({ type: GET_IMPORTUSER, formPayload});
    },
	getCompanyList: (formVal) =>{
       dispatch({ type: GET_COMPANYLIST, formVal });
    },
    getDomainList: (adminid) =>{
       dispatch({ type: GET_DOMAINLIST, adminid });
    },
  }
}
export default connect(mapStateTopProps, mapDispatchToProps)(withRouter(ImportUser));