import React, { Component , useState } from 'react';
import { Link, withRouter } from "react-router-dom";
import { connect } from 'react-redux';
import $ from 'jquery';
import DatePicker from "react-datepicker";
import {
  Row,
  Col,
  Modal,
  Table,
  OverlayTrigger,
  ButtonToolbar,
  Tooltip,
  Button
} from "react-bootstrap";
import Header from '../Layouts/Header';
import MenuSidebar from '../Layouts/MenuSidebar';
import { baseUrl } from '../Config/Config';
import Pagination from "react-js-pagination";
import moment from 'moment';

import "react-datepicker/dist/react-datepicker.css";

import { scrollToTopValidate,PageTitle } from "../Helpers/SettingHelper";

import { GET_PAYMENTLIST, GET_EXPORTPAYMENT } from '../../actions';
 
class List extends Component {
 
  
  constructor(props)
  {
    super(props); 
    this.state = {
      paymentlist:'',
      editable: false,
      priorityid:'',
      priorityvalue:'',
      activePage: 1,
      totalRecord: '',
      search_all:'',
	  startdate:'',
	  enddate:''
    };

     var admin_id = localStorage.getItem("admin_id");
      var qs = require('qs');
      var postobject = {
        activePage: 1,
        search_all: '',
		from_date : '',
        to_date : '',
        admin_id: admin_id
      };

    this.props.getPaymentList(qs.stringify(postobject));

    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleFormSubmit = this.handleFormSubmit.bind(this);
	this.handleChangeDate   = this.handleChangeDate.bind(this);
	this.handleChangeEndDate   = this.handleChangeEndDate.bind(this);
	
    }

   
   componentDidMount() {
      document.title = PageTitle('Fonts');
      if(localStorage.getItem('admin_id') === null){
      this.props.history.push('/');
      }
   }

   handlePageChange(pageNumber) {
     // console.log(`active page is ${pageNumber}`);
      this.setState({activePage: pageNumber});
      var qs = require('qs');
      var admin_id = localStorage.getItem("admin_id");
      var postobject = {
        activePage: pageNumber,
        search_all: this.state.search_all,
		from_date : '',
        to_date : '',
        admin_id: admin_id
      };
    this.props.getPaymentList(qs.stringify(postobject))
    }

    handleInputChange(event) {
    const {name, value} = event.target;      
    this.setState({
      [name]: value
    });
    
  }
   handleFormSubmit = () => {
        
          this.setState({Loading:true});
          var qs = require('qs');
          const formPayload = this.state;
          var admin_id = localStorage.getItem("admin_id");
		  
		  var s_date = '';
		  var e_date = '';
		 
		  if(formPayload.startdate!='' && formPayload.startdate!=null){
			var s_date = moment(formPayload.startdate).format('YYYY-MM-DD');
		  }
		  if(formPayload.enddate!='' && formPayload.enddate!=null){
			var e_date = moment(formPayload.enddate).format('YYYY-MM-DD');
		  }
          var postObject = {             
              activePage : 1,
              search_all : formPayload.search_all,
              from_date : s_date,
              to_date : e_date,
              admin_id  : admin_id         
          };
          //console.log(postObject);
          this.props.getPaymentList(qs.stringify(postObject));
        
    }

   componentWillReceiveProps(Props){

    if (Object.keys(Props.paymentlist).length > 0) {
      if (Props.paymentlist[0]["status"] === "success") {
       /* this.setState({
          paymentlist: Props.paymentlist[0]["paymentlist"],
        });*/
         this.setState({ totalRecord : Props.paymentlist[0].records_count, paymentlist: Props.paymentlist[0]["paymentlist"] }, function() {
            this.paymentlist();
          });
      }
    }
	
	if(Props.exportpayment !== this.props.exportpayment){
		console.log(Props.exportpayment)
      if (Props.exportpayment[0]["status"] === "success") {
		  let url =baseUrl+"/"+Props.exportpayment[0]["message"];
          window.open(url,
		'_blank' // <- This is what makes it open in a new window.
		);
      }
    }
    

   }

exportdata(){ console.log('exportPayment')
	let stat = '';		
	var qs = require('qs');

	var s_date = '';
	var e_date = '';
	 
	if(this.state.startdate!='' && this.state.startdate!=null){
		var s_date = moment(this.state.startdate).format('YYYY-MM-DD');
	}
	if(this.state.enddate!='' && this.state.enddate!=null){
		var e_date = moment(this.state.enddate).format('YYYY-MM-DD');
	}		  
	var postObject = {
	  admin_id : localStorage.getItem("admin_id"),
	  search_all : this.state.search_all,
	  from_date : s_date,
	  to_date : e_date
	};
    this.props.getExportPayment(qs.stringify(postObject));
}

  paymentlist() {
    var paymentlist = this.props.paymentlist[0];
    if (paymentlist != "undefined" && paymentlist != null) {
      if (paymentlist.status == "success") {
        if (Object.keys(paymentlist).length > 0) {
          const paymentlistDetails = paymentlist.paymentlist.map(
            (paymentlist, Index) => {
              let sno = Index+1;
               var startdata = moment(paymentlist.created_date).format('MM/DD/YYYY  hh:mm A')  
              return (
                <tr key={paymentlist.id}>
				  <td>{sno}</td>
				  <td>{paymentlist.booking_id}</td>
				  <td>{paymentlist.firstname} {paymentlist.lastname}</td>
                  <td>{paymentlist.order_number}</td>				  
                  <td>${paymentlist.received_amount}</td>
                  <td>{paymentlist.transfer_status}</td>				  
                  <td>${paymentlist.company_commission_amount} <span>({paymentlist.company_comission}%)</span></td>
                  <td>${paymentlist.final_payout}</td>
                 <td>{startdata}</td> 
                </tr>					  
              );
            }
          );
          return paymentlistDetails ;
        }
      } else {
        return (
          <tr>
            <td colspan="6" className="v-align-nr">
              No Result
            </td>
          </tr>
        );
      }
    }
  }
  

    handleChangeDate(date) {	
      this.setState({
        startdate: date
      });
    }
   handleChangeEndDate(date){
	   this.setState({
        enddate: date
      });
   }
  
  render() {
    return (
      <div className="wrapper"> 
 
    <Header />
    <MenuSidebar currentpage="payments" />  
      
      <div className="content"> 
        <div className="content-wrapper">
            <div className="car-listing-wrap">

              <div className="listing-header">
                <div className="title">
                  <h3>Payments</h3>
                </div>
                <div class="data-picker">
                <DatePicker
                     placeholderText="select start date"
                      dateFormat="dd/MM/yyyy"
                       selected={this.state.startdate}
                        className="form-control"
                        onChange={this.handleChangeDate}  />
				    <DatePicker
                     placeholderText="select end date"
                      dateFormat="dd/MM/yyyy"
                       selected={this.state.enddate}
                        className="form-control"
                        onChange={this.handleChangeEndDate}  />
                </div>

                 <div className="search-list">
                   <form className="edit" id="edit" onSubmit={ e => { this.handleFormSubmit(this); e.preventDefault(); }} >     
				 
                    <div className="search-inner">
					
                      <input type="text" name="search_all" onChange={this.handleInputChange} className="form-control" value={this.state.search_all} placeholder="Search" /> 
                     <button className="" type="submit">Submit</button>
                    <div className="erroremail"></div>
                  </div>
                 </form>
                </div>
              <div className="add_button payment-add-button">  
                    <Link onClick={this.exportdata.bind(this)} className="" title="Export"> Export <i className="fa fa-download" aria-hidden="true"></i></Link>
                </div>
              </div>            
              {/*<div className="statusmessage"></div>*/}

              <div className="car-listing-row">
                <Table striped bordered hover>
                  <thead>
                    <tr>
                      <th>S.NO</th>
                      <th>Booking Id</th>
                      <th>Name</th>
                      <th>Order Id</th>
					 <th>Paid Amount</th>
                      <th>Transfer Status</th>
                      <th>Company Commission</th>
                      <th>Driver Split Amount</th>
                      <th>Created On</th>
                    </tr>
                  </thead>
                  <tbody>{this.paymentlist()}</tbody>
                </Table>
                 <div className="text-center">
            <Pagination
            activePage={this.state.activePage}
            itemsCountPerPage={10}
            totalItemsCount={this.state.totalRecord}
            pageRangeDisplayed={5}
            onChange={this.handlePageChange.bind(this)}
            />
          </div>
              </div>

            </div> 
        </div>  
      </div>
      
    </div>
    );
  }
}

const mapStateTopProps = (state) => {
  return {
    paymentlist: state.paymentlist,
	exportpayment: state.exportpayment	
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getPaymentList: (formVal) =>{
       dispatch({ type: GET_PAYMENTLIST , formVal});
    },
	getExportPayment: (formPayload) => {
		 dispatch({ type: GET_EXPORTPAYMENT, formPayload });
	}
    
  }
}
export default connect(mapStateTopProps, mapDispatchToProps)(withRouter(List));