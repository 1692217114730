import React, { Component } from 'react';
import { Scrollbars } from 'react-custom-scrollbars';

class MenuSidebar extends Component {
	
	constructor(props)
	{
		super(props);
		this.state = {
			  currentpage:this.props.currentpage,
			  admin_id:localStorage.getItem('admin_id')
		};
		
    }
	
	activeMenu(currentPage) {
        let status = '';
            if(this.state.currentpage===currentPage) {
                status = 'active'
            }else{
            status = 'enable';
        }
        return status;
    }
	
  render() {
    return (	
	<div className="sidebar-menu mobi-close">
	   <div className="main-menu">
	   <Scrollbars className="custom-scroll-wrap">
			<ul className="menulist">
				<li className={this.activeMenu('dashboard')}>
					<a title="Dashboard" href="#">
						<i className="dashboard-icon icon"></i><span>Dashboard</span>
					</a>
				</li>	
				{/*<li>
					<a title="Documents" href="javascript:void(0)" className="dropdown-toggle">
						<i className="docs-icon icon" aria-hidden="true"></i>
						<span>Documents</span>
					</a>
					<ul className="submenu dropdown-menu">
						<li>
							<a href="#" title="Upload Docs">Documents 1</a>
						</li>
						<li>
							<a href="#" title="Modify Docs">Documents 2</a>
						</li>
					</ul>
				</li>*/}

				<li className={this.activeMenu('user')}>
					<a title="Users" href="/user">
						<i className="userlist-icon icon" aria-hidden="true"></i>
						<span>Users</span>
					</a>
				</li>
				<li className={this.activeMenu('drivers')}>
					<a title="Drivers" href="/driveruser">
						<i className="driver-icon icon" aria-hidden="true"></i>
						<span>Drivers</span>
					</a>
				</li>
				{this.state.admin_id == 1 ?<li className={this.activeMenu('companylist')}>
					<a title="Company" href="/company">
						<i className="company-icon icon" aria-hidden="true"></i>
						<span>Company</span>
					</a>
				</li>:''}

				<li className={this.activeMenu('vehiclelist')}>
					<a title="Vehicle Types" href="/vehicle">
						<i className="vehicletype-icon icon"></i>
						<span>Vehicle Types</span>
					</a>
				</li>
				<li className={this.activeMenu('carlist')}>
					<a title="Vehicle" href="/car">
						<i className="vehicle-icon icon"></i>
						<span>Vehicle</span>
					</a>
				</li>
				<li className={this.activeMenu('bookinghistory')}>
					<a title="Rides History" href="/bookinghistory">
						<i className="history-icon icon" aria-hidden="true"></i>
						<span>Rides History</span>
					</a>
				</li>
				<li className={this.activeMenu('ongoingride')}>
					<a title="Ongoing Rides" href="/ongoingride">
						<i className="vehicle-icon icon" aria-hidden="true"></i>
						<span>Ongoing Rides</span>
					</a>
				</li>

				<li className={this.activeMenu('completedride')}>
					<a title="Completed Rides" href="/completedride">
						<i className="completed-icon icon" aria-hidden="true"></i>
						<span>Completed Rides</span>
					</a>
				</li>

				<li className={this.activeMenu('incompleteride')}>
					<a title="Incomplete Rides" href="/incompleteride">
						<i className="incompleted-icon icon" aria-hidden="true"></i>
						<span>Incomplete Rides</span>
					</a>
				</li>
				<li className={this.activeMenu('fonts')}>
					<a title="Fonts" href="/fonts">
						<i className="font-icon icon" aria-hidden="true"></i>
						<span>Fonts</span>
					</a>
				</li>
				<li className={this.activeMenu('coupon')}>
					<a title="Coupons" href="/coupons">
						<i className="cupoon-icon icon" aria-hidden="true"></i>
						<span>Coupons</span>
					</a>
				</li>
				<li className={this.activeMenu('locatedrivers')}>
					<a title="Locate Drivers" href="/locatedrivers">
						<i className="locate-icon icon" aria-hidden="true"></i>
						<span>Locate Drivers</span>
					</a>
				</li>
				<li className={this.activeMenu('payments')}>
					<a title="Payments" href="/payments">
						<i className="payment-icon icon" aria-hidden="true"></i>
						<span>Payments</span>
					</a>
				</li>
				<li className={this.activeMenu('templates')}>
					<a title="Templates" href="/templates">
						<i className="template-icon icon" aria-hidden="true"></i>
						<span>Templates</span>
					</a>
				</li>
				
				<li className={this.activeMenu('settings')}>
					<a title="Settings" href="/setting">
						<i className="setting-icon icon" aria-hidden="true"></i>
						<span>Settings</span>
					</a>
				</li>

				<li className={this.activeMenu('gallery')}>
					<a title="Settings" href="/gallery">
						<i className="setting-icon icon" aria-hidden="true"></i>
						<span>Gallery</span>
					</a>
				</li>

				<li className={this.activeMenu('notification')}>
					<a title="Notification" href="/notification">
						<i className="notification-icon icon" aria-hidden="true"></i>
						<span>User Notification</span>
					</a>
				</li>
				<li className={this.activeMenu('favourites')}>
					<a title="Favourite" href="/favourites">
						<i className="notification-icon icon" aria-hidden="true"></i>
						<span>Favourites</span>
					</a>
				</li>
				<li className={this.activeMenu('invitefriend')}>
					<a title="Invitefriend" href="/invitefriend">
						<i className="friend-icon icon" aria-hidden="true"></i>
						<span>Invite Friend</span>
					</a>
				</li>
			</ul>
			</Scrollbars>
		</div>
	</div>		

	
    );
  }
}

export default MenuSidebar;