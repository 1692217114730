import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import Header from '../Layouts/Header';
import MenuSidebar from '../Layouts/MenuSidebar';
import $ from 'jquery';
import Select from 'react-select';
import { lang } from '../Helpers/lang';
import { PageTitle, scrollToTopValidate, scrollToTop } from "../Helpers/SettingHelper";
import { carImageUrl } from '../Config/Config';

import {GET_EDITCAR,GET_CARLIST,GET_VEHICLETYPE  }  from '../../actions'; 

class Edit extends Component {
	
	constructor(props)
	{
		super(props);	
		const carId = (typeof this.props.match.params.ID === 'undefined') ? '' : this.props.match.params.ID;

		this.state = {
			car_name:'',
			car_id:'',
			car_price:'',
			car_colour:'',
			car_no_plate:'',
			car_model:'',
			ride_information: '',
			selectedvalue: '',
            selectedOption:{ value: 'active', label: 'Active'},
			user_image: [],
			vehicletype:[],
			user_image_name: "",
			user_image_preview: "",
			isHidden: false,
			selectedVehicle:'',
			vehiclevalue:'',
			carId: carId,
			Loading:false,
		};
		
	   this.handleInputChange = this.handleInputChange.bind(this);
	   this.onChangeHandler = this.onChangeHandler.bind(this);
	   this.handleChange = this.handleChange.bind(this);
	   this.removeImage = this.removeImage.bind(this);
	   this.props.getVehicleType();
	   this.props.getCarList(carId);

    }
	

    handleChange = selectedOption => {
        this.setState({selectedOption})
        this.setState({  selectedvalue : selectedOption.value});
    };
   handleInputChange(event) {

      const {name, value} = event.target;      
      this.setState({
          [name]: value
        });
    }

	componentDidMount() {
		document.title = PageTitle('Car Edit');
		if(localStorage.getItem('admin_id') === null){
		this.props.history.push('/');
		}
    }

   handleChangeVehicle = selectedVehicle => {
      this.setState({ selectedVehicle});
      this.setState({ vehiclevalue : selectedVehicle.value });
      if(selectedVehicle.value === ''){
        $('.errorvehicletype').html('<span class="errorspan">Please fill the field</span>');
      }
   }

  onChangeHandler=event=>{
    let reader = new FileReader();
    const file = event.target.files[0];
    reader.onloadend = () => {
      this.setState({
        user_image_name: file.name,
        user_image_preview: reader.result,
        user_image: file
      });
    };
    reader.readAsDataURL(file);
  }

 handleFormSubmit = () => {
			if(this.validateForm()){
				this.setState({Loading:true});
				 const config = {
			        headers: {
			          'Content-Type': 'multipart/form-data'
			        }
			      };

				const formPayload = this.state;
				var status = '';
				if(formPayload.selectedvalue === '' && formPayload.selectedvalue!== 0){
				status =formPayload.selectedOption.value;
				}else{
				status = formPayload.selectedvalue;
				}
				var vehicletype;
				if(formPayload.selectedVehicle !== ''){
				vehicletype =formPayload.selectedVehicle.value;
				}else{
				vehicletype =formPayload.vehiclevalue;
				}
				var postObject = {
					admin_id : localStorage.getItem("admin_id"),
					car_id : formPayload.car_id,
					car_name: formPayload.car_name,
					car_price:formPayload.car_price,
					car_colour:formPayload.car_colour,
					car_no_plate:formPayload.car_no_plate,
					car_model: formPayload.car_model,
					ride_information: formPayload.ride_information,
					vehicle_type    : vehicletype,
					car_image        : formPayload.user_image,
					car_status      :status,
				};

				let formData = new FormData();
				for(let k in postObject) {
				formData.append(k, postObject[k]);
				}

				this.props.getEditCar(formData,config);
				//this.props.getAddCar(qs.stringify(postObject));
			}
	}

	validateForm() {
		const {car_name,car_colour,car_no_plate,car_price,car_model,ride_information,selectedVehicle,user_image_preview} = this.state;
      	//let formIsValid = true;
		let errors = 0;
		if (!car_name) {
			errors++;
			$('.errorcar_name').html('<span class="errorspan">Please fill the field</span>');
		}else if(car_name){
			$('.errorcar_name').html('');
		}

		if (!car_colour) {
			errors++;
			$('.errorcar_colour').html('<span class="errorspan">Please fill the field</span>');
		}else if(car_colour){
			$('.errorcar_colour').html('');
		}

		if (!car_price) {
			errors++;
			$('.errorcar_price').html('<span class="errorspan">Please fill the field</span>');
		}else if(car_price){
			$('.errorcar_price').html('');
		}
		if (!car_no_plate) {
			errors++;
			$('.errorcar_no_plate').html('<span class="errorspan">Please fill the field</span>');
		}else if(car_no_plate){
			$('.errorcar_no_plate').html('');
		}
		if (!car_model) {
			errors++;
			$('.errorcar_model').html('<span class="errorspan">Please fill the field</span>');
		}else if(car_model){
			$('.errorcar_model').html('');
		}
		if (!ride_information) {
			errors++;
			$('.errorride_information').html('<span class="errorspan">Please fill the field</span>');
		}else if(ride_information){
			$('.errorride_information').html('');
		}

		if (selectedVehicle.value === undefined || selectedVehicle.value === '') {
		$('.errorvehicletype').html('<span class="errorspan">Please fill the field</span>');
		errors++;
		}
		else {
		$('.errorvehicletype').html('');
		}

		if (user_image_preview === undefined || user_image_preview === '') {
		$('.errorcarimage').html('<span class="errorspan">Please fill the field</span>');
		errors++;
		}
		else {
		$('.errorcarimage').html('');
		}


		if(errors>0) {
		setTimeout(function () {
		scrollToTopValidate();
		}, 100);
		return false;
		}
		else {
		return true;
		}

		/*this.setState({
			errors: errors
		});
		return formIsValid;*/
    }

    componentWillReceiveProps(Props){
    	   if(Props.carlist !== this.props.carlist){
    		if(Object.keys(Props.carlist).length > 0){
				this.setState({Loading:false});

					if(Props.carlist[0].status === "success"){

						const formdata = Props.carlist[0].carlist;
						if(formdata.car_status === 'A'){
							this.setState({selectedOption:{value: 'active', label: 'Active'}});
						}else{
							this.setState({selectedOption:{value: 'inactive', label: 'Inactive'}});

						}
						this.setState({car_name:formdata.car_name});
						this.setState({car_colour:formdata.car_colour});
						this.setState({car_no_plate:formdata.car_no_plate});
						this.setState({car_model:formdata.car_model});
						this.setState({car_price:formdata.car_price});
						this.setState({ride_information:formdata.ride_information});
						this.setState({car_id: formdata.car_id});
						this.setState({user_image_preview: formdata.car_image});
					 if(Props.carlist[0].vehicletype!== '' && Props.carlist[0].vehicletype!== null){
			              this.setselectedvalue(Props.carlist[0].vehicletype);
			           }

					if(Props.carlist[0].vehicletype.length > 0){
					if(Props.carlist[0].vehicletype[0].value!== '' && Props.carlist[0].vehicletype.value!== null){
						this.setState({ selectedVehicle : Props.carlist[0].vehicletype[0]})
						}
					}
					}
    		}
    	}
     if(Props.vehicletype.length > 0){
    	 if(Props.vehicletype[0].vehiclelist!== '' && Props.vehicletype[0].vehiclelist!== null){
              this.setState({ vehicletype : Props.vehicletype[0].vehiclelist})
         }
     }


         if(Props.caredit !== this.props.caredit){
    		if(Object.keys(Props.caredit).length > 0){
    					this.setState({ Loading: false });

    			const formdata = Props.caredit[0].carlist;
				if(formdata.car_status === 'A'){
				this.setState({selectedOption:{value: 'active', label: 'Active'}});
				}else{
				this.setState({selectedOption:{value: 'inactive', label: 'Inactive'}});

				}
				this.setState({car_name:formdata.car_name});
				this.setState({car_colour:formdata.car_colour});
				this.setState({car_no_plate:formdata.car_no_plate});
				this.setState({car_model:formdata.car_model});
				this.setState({car_price:formdata.car_price});
				this.setState({ride_information:formdata.ride_information});
			    this.setState({car_id: formdata.car_id});
				if(Props.caredit[0].vehicletype!== '' && Props.caredit[0].vehicletype!== null){
				this.setselectedvalue(Props.caredit[0].vehicletype);

				}
                $('.success_message').html('<div class="status_sucess"><h3>'+ Props.caredit[0].message+'</h3></div>');
                	scrollToTop();
    				setTimeout(
						function() {
							$('.success_message').html('');
						}
						.bind(this),
						3000
						);
    		}
    	}   
      }

   setselectedvalue(selectsers){
    const vehicleArray = selectsers.map ((vehicleType, index) => ({
     id: index,
     name: vehicleType,
    }));
    // Dynamically create select list
    let vehicles = [];
    vehicleArray.map(item =>
    vehicles.push({ label: item.name.label, value: item.name.value }),
    );
    this.setState({selectedVehicle : vehicles})
  }

  removeImage(){
  	this.setState({user_image_preview:''});
  }
	
  render() {

	let { user_image_preview} = this.state;

	let carImg = '';

	let preview = '';
	/*if(user_image_preview!== null && user_image_preview!=='' && user_image!==''){
		      preview = <img src={user_image_preview} alt="" />;
	}*/
	if (user_image_preview!== null && user_image_preview!== '') {
	    carImg = carImageUrl + "/" + user_image_preview;
		preview = <img className="img_class" src={carImg} alt="" />;
	}

	const vehicleArray = this.state.vehicletype.map ((vehicleType, index) => ({
	id: index,
	name: vehicleType,
	}));
	// Dynamically create select list
	let vehicles = [];
	vehicleArray.map(item =>
	vehicles.push({ label: item.name.vehicle_type, value: item.name.vehicle_id }),
	);
  	const {selectedOption,selectedVehicle} = this.state;
    return (
      <div className="wrapper"> 
 
	  <Header />
    <MenuSidebar currentpage="carlist" />  

	
	<div className="content">	
		<div className="content-wrapper">
			<div className="form-wrapper">
			<div className="success_message"></div>
			<div className="title">
				<h4>Edit Details</h4>
				
			</div>
			 <form className="edit" id="edit" onSubmit={ e => { this.handleFormSubmit(this); e.preventDefault(); }}>
			<div className="form-row">
			<div className="form-left">
		    	<div className="form-group">
					<label>Car Name:</label>
				    <input type="text" name="car_name" onChange={this.handleInputChange} className="form-control" value={this.state.car_name} />
					<div className="errorcar_name"></div>
				</div>
				<div className="form-group vehicles-select">
					<label>Vehicle Type:</label>
				     <Select 
                         options={vehicles}  
                         value={selectedVehicle?selectedVehicle:{ value: '0', label: 'Select Vehicle Type' }}
                         onChange={this.handleChangeVehicle}
                         placeholder="Select Vehicle Type" />
					<div className="errorvehicletype"></div>
				</div>
               <div className="form-group">
					<label>Car Image:</label>
				   <div className="choose-file">
                {this.state.isHidden && <div className="image_success"></div>}
                   <span className="profile_btn"><input type="file" name="file" onChange={this.onChangeHandler} /></span>
                </div>
                {preview}
                {user_image_preview?<a href="javascript:void(0);" onClick={this.removeImage}>X</a>:''}
                <div className="errorcarimage"></div> 
                </div>

				<div className="form-group">					
					<label>Price:</label>
						<input type="text" name="car_price" onChange={this.handleInputChange} className="form-control" value={this.state.car_price} />
					<div className="errorcar_price"></div>
				</div>
				
				<div className="form-group">
					<label>Color:</label>
						<input type="text" name="car_colour" onChange={this.handleInputChange} className="form-control" value={this.state.car_colour} />
					<div className="errorcar_colour"></div>
				</div>
				</div>
			<div className="form-right">
				<div className="form-group">
					<label>Car Number:</label>
						<input type="text" name="car_no_plate" onChange={this.handleInputChange} className="form-control" value={this.state.car_no_plate} />
					<div className="errorcar_no_plate"></div>
				</div>
				<div className="form-group">
					<label>Model:</label>
						<input type="text" name="car_model" onChange={this.handleInputChange} className="form-control" value={this.state.car_model} />
					<div className="errorcar_model"></div>
				</div>
				<div className="form-group">
					<label>Ride Information:</label>
						<input type="text" name="ride_information" onChange={this.handleInputChange} className="form-control" value={this.state.ride_information} />
					<div className="errorride_information"></div>
				</div>
				<div className="form-group">					
					<label>Status:</label>
						   <Select 
						   value={selectedOption?selectedOption:{ value: 'active', label: 'Active'}}
                            options={lang.common.status_option} 
                            onChange={this.handleChange}
                            />
				</div>
				</div>
			</div>		

			<div className="btn-group export">	
				  <button className="btn btn_orange btn_minwid login_submit animate-btn2" type="submit" disabled={(this.state.Loading ===true)?true:false}> {this.state.Loading ===true &&
                                        <span className="load-data"></span> 
                                        }Submit
                   </button>
			</div>
			</form>
			</div>
				
		</div>	
	</div>
		 	
    </div>
    );
  }
}


const mapStateTopProps = (state) => {
  return {
  	vehicletype : state.vehicletype,
  	carlist     : state.carlist,
  	caredit     : state.caredit
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
	 getVehicleType: () =>{
       dispatch({ type: GET_VEHICLETYPE});
    },
     getCarList: (carId) =>{
       dispatch({ type: GET_CARLIST,carId });
    },
    getEditCar: (formPayload) =>{
       dispatch({ type: GET_EDITCAR, formPayload});
    }
  }
}
export default connect(mapStateTopProps, mapDispatchToProps)(withRouter(Edit));