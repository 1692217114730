/* eslint-disable */
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import $ from 'jquery';

import Header from '../Layouts/Header';
import MenuSidebar from '../Layouts/MenuSidebar';
import { SketchPicker } from 'react-color'
import reactCSS from 'reactcss'
import { scrollToTopValidate, PageTitle,scrollToTop } from "../Helpers/SettingHelper";
import { GET_GALLERY } from '../../actions';
import Select from 'react-select';
import { carImageUrl,apiUrl } from '../Config/Config';
import axios from 'axios';


class Gallery extends Component {
  
    fileObj = [];
    fileArray = [];
    imageArray = [];

  constructor(props) {
    super(props);
	this.state = {
	}
	var qs = require('qs');	
	var postobject = {
		admin_id:  localStorage.getItem("admin_id")
	};
	this.removeImagebyindex = this.removeImagebyindex.bind(this);
	this.uploadMultipleFiles = this.uploadMultipleFiles.bind(this);
  }
  
  componentDidMount() {
      document.title = PageTitle('Gallery');
      if(localStorage.getItem('admin_id') === null){
      this.props.history.push('/');
      }
	   
   }

  handleFormSubmit = () => {
	  
		const config = {
			headers: {
			  'Content-Type': 'multipart/form-data'
			}
		};
				  			  
		var filesdata = this.state.user_image;
		let formData = new FormData();
		for (var i in filesdata) {
			formData.append('images[]',filesdata[i])
		}		       

		this.props.getGallery(formData,config);
  }
  
  componentWillReceiveProps(Props){

		if(Props.gallery !== this.props.gallery){					
			
			if(Object.keys(Props.gallery).length > 0){				
				this.setState({ Loading: false });
				$('.success_message').html('<div class="status_sucess"><h3>'+ Props.gallery[0].message+'</h3></div>');
                	scrollToTop();
    				setTimeout(
						function() {
							$('.success_message').html('');
							window.location.reload();
						}
						.bind(this),
						3000
						);
			}
		}

   }
  



   uploadMultipleFiles = (e) =>{
	this.fileObj = [];

        this.fileObj.push(e.target.files)
        for (let i = 0; i < this.fileObj[0].length; i++) {
            this.fileArray.push(URL.createObjectURL(this.fileObj[0][i]))
            //this.fileArray.push(this.fileObj[0][i])
            this.imageArray.push(this.fileObj[0][i])

        }
		this.setState({ user_image: this.imageArray })		
    }


     removeImagebyindex = (indexvalue) => {
  	 
	  var list = [...this.imageArray];
	  this.imageArray = [];
	  this.fileArray = [];
	  list.splice(indexvalue, 1);
	 
	  for (let i = 0; i < list.length; i++) {
		this.imageArray.push(list[i]);
		this.fileArray.push(URL.createObjectURL(list[i]));
	  }
	
		this.setState({ user_image: this.imageArray })
		
		
		/*var thumbDefault = this.state.thumbDefault;
		if(indexvalue <= thumbDefault){
			let thum = thumbDefault-1;
			 if(thum < 0 ){
			   this.setState({thumbDefault : 0})
			 }else{
				this.setState({thumbDefault : thum});
			}    
		}*/
	}
  
  render() {
  /*====Thumb image while choosing from front end===*/

    let imageslist = [];

	let indexlength =  imageslist.length;
	const imgThumblist =  this.fileArray.map((url,index) => (
					<li className="thumb" key={indexlength+index}  >
						<span><img src={url}  alt="..." /></span>
						<a href="javascript:void(0);" onClick={this.removeImagebyindex.bind(this,index)}>X</a>
					</li>
				))

 return (
	<div className="wrapper"> 
		<Header />
		<MenuSidebar />
			

		<div className="content">	
			<div className="content-wrapper">
				<div className="content-body">
					<form className="settings" onSubmit={ e => { this.handleFormSubmit(this); e.preventDefault(); }}>
						<div className="form-wrapper">
							<div className="success_message"></div>
							<div className="title">
								<h4>Upload image to gallery</h4>
							</div>
							<div className="form-row">
								<div className="form-group"> 
										<label>Upload Image:</label>
											<div className="choose-file">
											{this.state.isHidden && <div className="image_success"></div>}
											<span className="profile_btn">
											<input type="file" className="form-control" name="file" onChange={this.uploadMultipleFiles} multiple /></span>
											</div>
								 <ul className="carimagelisting">
								{/*imageslist*/}
								{imgThumblist}
								</ul>
								</div>	
						   </div>
							<div className="form-row">
								
								<div className="btn-group right">				
									<button className="btn btn_orange btn_minwid login_submit animate-btn2" type="submit" disabled={(this.state.Loading ===true)?true:false}> {this.state.Loading ===true &&
													<span className="load-data">Loading</span> 
													} Submit
									</button>	
								</div>
								
							</div>
						</div>
					</form>	
				</div>
			</div>
		</div>
	</div>
	
		)
  }
}

const mapStateTopProps = (state) => {
  return {
  		gallery : state.gallery
  }
}

const mapDispatchToProps = (dispatch) => {
  return {	   
	    getGallery: (formPayload) =>{
            dispatch({ type: GET_GALLERY, formPayload});
        },
   	  }
}
export default connect(mapStateTopProps, mapDispatchToProps)(withRouter(Gallery));