import { SET_EXPORTPAYMENT } from '../actions';

const exportpayment = (state = [], action) => {
  switch (action.type) {
	case SET_EXPORTPAYMENT:
      return [...action.value];  
    default: return state;
  }
}

export default exportpayment;
