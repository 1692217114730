/* eslint-disable */
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import $ from 'jquery';

import Header from '../Layouts/Header';
import MenuSidebar from '../Layouts/MenuSidebar';
import { SketchPicker } from 'react-color'
import reactCSS from 'reactcss'
import { scrollToTopValidate, PageTitle,scrollToTop } from "../Helpers/SettingHelper";
import { GET_USERNOTIFICATION,GET_UPDATENOTIFICATION,GET_FONTNAME } from '../../actions';
import Select from 'react-select';
import { carImageUrl,apiUrl } from '../Config/Config';
import axios from 'axios';


class Notification extends Component {
	
  constructor(props) {
    super(props);
	this.state = {
		first_page_label:'',
		first_page_static_block:'',
		map_label:'',
		map_static_block:'',
		date_time_label:'',
		date_time_static_block:'',
		car_list_label:'',
		car_list_static_block:'',
		booking_label:'',
		booking_static_block:'',
		favorites_label:'',
		favorites_static_block:'',
		
		
		pendingride_label:'',
		pendingride_static_block:'',
		recentride_label:'',
		recentride_static_block:'',
		
		plugin_id:'',
		admin_id:localStorage.getItem("admin_id"),
		Loading: false,
		notify_font_label_size:'',
		notify_font_text_size:'',
		notify_height:'',
		user_image1: [],
		user_image_name1: "",
		user_image_preview1: "",
		user_image2: [],
		user_image_name2: "",
		user_image_preview2: "",
		user_image3: [],
		user_image_name3: "",
		user_image_preview3: "",
		user_image4: [],
		user_image_name4: "",
		user_image_preview4: "",
		user_image5: [],
		user_image_name5: "",
		user_image_preview5: "",
		user_image6: [],
		user_image_name6: "",
		user_image_preview6: "",
		
		user_image7: [],
		user_image_name7: "",
		user_image_preview7: "",
		user_image8: [],
		user_image_name8: "",
		user_image_preview8: "",
		
		remove_preview1: false,
		remove_preview2: false,
		remove_preview3: false,
		remove_preview4: false,
		remove_preview5: false,
		remove_preview6: false,
		remove_preview7: false,
		remove_preview8: false,
		fonttype : [],
		selectedFont:'',
		fontvalue:'',
		styleimported:'',
		stylefontfamily:'',
		
		first_page_content_show: false,
		map_page_content_show: false,
		date_time_content_show: false,
		car_list_content_show: false,
		booking_content_show: false,
		favorites_content_show: false,
		
		pendingride_content_show: false,
		recentride_content_show: false,
	}
	var qs = require('qs');
		
	var postobject = {
		admin_id:  localStorage.getItem("admin_id")
	};
	this.props.getUserNotificationData(qs.stringify(postobject));
	this.props.getFontName();
		
	this.handleInputChange  = this.handleInputChange.bind(this);
	this.handleChangeFont = this.handleChangeFont.bind(this);
	this.onChangeHandler = this.onChangeHandler.bind(this);	
	this.removeImage = this.removeImage.bind(this);
  }
  
  componentDidMount() {
      document.title = PageTitle('Settings');
      if(localStorage.getItem('admin_id') === null){
      this.props.history.push('/');
      }
	   
   }
	 
  handleInputChange(event) {

      const {name, value} = event.target;      
      this.setState({
          [name]: value
        });
		
	if(name === 'first_page_content_show'){
		this.setState({first_page_content_show: !this.state.first_page_content_show});
    }
	if(name === 'map_page_content_show'){
		this.setState({map_page_content_show: !this.state.map_page_content_show});
    }
	if(name === 'date_time_content_show'){
		this.setState({date_time_content_show: !this.state.date_time_content_show});
    }
	if(name === 'car_list_content_show'){
		this.setState({car_list_content_show: !this.state.car_list_content_show});
    }
	if(name === 'booking_content_show'){
		this.setState({booking_content_show: !this.state.booking_content_show});
    }
	if(name === 'favorites_content_show'){
		this.setState({favorites_content_show: !this.state.favorites_content_show});
    }
	if(name === 'pendingride_content_show'){
		this.setState({pendingride_content_show: !this.state.pendingride_content_show});
    }
	if(name === 'recentride_content_show'){
		this.setState({recentride_content_show: !this.state.recentride_content_show});
    }
  }
  handleFormSubmit = () => {
	  
		const config = {
			headers: {
			  'Content-Type': 'multipart/form-data'
			}
		};
				  
	  const formPayload = this.state;
	  var qs = require('qs');
		if(this.state.first_page_content_show === true){
			var first_page_content_show = '1';
		}else{
			 var first_page_content_show = '0';
		}
	
				  
	  var fonttype;
		if(formPayload.selectedFont[0]==undefined){
			fonttype = formPayload.selectedFont.value;
		}else{
			fonttype = formPayload.selectedFont[0].value;
		}
		var first_page_content_show, map_page_content_show, date_time_content_show,car_list_content_show, booking_content_show,favorites_content_show, pendingride_content_show, recentride_content_show;
		
		first_page_content_show = map_page_content_show = date_time_content_show = car_list_content_show = booking_content_show = favorites_content_show  = pendingride_content_show = recentride_content_show ='0';
		
		if(this.state.first_page_content_show === true){
			 first_page_content_show = '1';
		}
		if(this.state.map_page_content_show === true){
					 map_page_content_show = '1';
				}
		if(this.state.date_time_content_show === true){
					 date_time_content_show = '1';
				}
		if(this.state.car_list_content_show === true){
					 car_list_content_show = '1';
				}
		if(this.state.booking_content_show === true){
					 booking_content_show = '1';
				}
		if(this.state.favorites_content_show === true){
					 favorites_content_show = '1';
				}
		if(this.state.pendingride_content_show === true){
					 pendingride_content_show = '1';
				}
		if(this.state.recentride_content_show === true){
					 recentride_content_show = '1';
				}
				
				
		var postObject = {
			admin_id : localStorage.getItem("admin_id"),
			plugin_id :  formPayload.plugin_id,
			first_page_label       : formPayload.first_page_label,
			first_page_static_block       : formPayload.first_page_static_block,
			first_page_content_show       : first_page_content_show,
			map_label    : formPayload.map_label,
			map_static_block    : formPayload.map_static_block,
			map_page_content_show    : map_page_content_show,
			date_time_label     : formPayload.date_time_label,
			date_time_static_block     : formPayload.date_time_static_block,
			date_time_content_show     : date_time_content_show,
			car_list_label    : formPayload.car_list_label,
			car_list_static_block    : formPayload.car_list_static_block,
			car_list_content_show    : car_list_content_show,
			booking_label     : formPayload.booking_label,
			booking_static_block     : formPayload.booking_static_block,
			booking_content_show     : booking_content_show,
			favorites_label     : formPayload.favorites_label,
			favorites_static_block     : formPayload.favorites_static_block,
			favorites_content_show     : favorites_content_show,
			
			pendingride_label    : formPayload.pendingride_label,
			pendingride_static_block     : formPayload.pendingride_static_block,
			pendingride_content_show     : pendingride_content_show,
			recentride_label     : formPayload.recentride_label,
			recentride_static_block     : formPayload.recentride_static_block,
			recentride_content_show     : recentride_content_show,
			
			notify_font_label_size     : formPayload.notify_font_label_size,
			notify_font_text_size     : formPayload.notify_font_text_size,
			notify_height     : formPayload.notify_height,
			notify_font_type 		  : fonttype,
			first_file: formPayload.user_image1,         
			map_file: formPayload.user_image2,          
			date_time_file: formPayload.user_image3,          
			car_list_file: formPayload.user_image4,          
			booking_page_file: formPayload.user_image5,       
			favorites_page_file: formPayload.user_image6,       
			pendingride_page_file: formPayload.user_image7,       
			recentride_page_file: formPayload.user_image8,       
			remove_preview1: formPayload.remove_preview1,          
			remove_preview2: formPayload.remove_preview2,          
			remove_preview3: formPayload.remove_preview3,          
			remove_preview4: formPayload.remove_preview4,          
			remove_preview5: formPayload.remove_preview5,          
			remove_preview6: formPayload.remove_preview6,          
			remove_preview7: formPayload.remove_preview7,          
			remove_preview8: formPayload.remove_preview8,          
		};
		
		let formData = new FormData();
		for(let k in postObject) {
		  formData.append(k, postObject[k]);
		}

		this.props.getUpdateNotificationData(formData,config);
  }
  
  componentWillReceiveProps(Props){

		if(Props.notification !== this.props.notification){
			if(Object.keys(Props.notification).length > 0){
				const formpayload = Props.notification[0].results;
				const defaultfont = Props.notification[0].defaultfont;
				this.setState({plugin_id:formpayload.plugin_id});
				this.setState({first_page_label: formpayload.first_page_label});
				this.setState({first_page_static_block: formpayload.first_page_static_block});
				this.setState({map_label: formpayload.map_label});
				this.setState({map_static_block: formpayload.map_static_block});
				this.setState({date_time_label: formpayload.date_time_label});
				this.setState({date_time_static_block: formpayload.date_time_static_block});
				this.setState({car_list_label: formpayload.car_list_label});
				this.setState({car_list_static_block: formpayload.car_list_static_block});
				this.setState({booking_label: formpayload.booking_label});		
				this.setState({booking_static_block: formpayload.booking_static_block});

				this.setState({favorites_label: formpayload.favorites_label});		
				this.setState({favorites_static_block: formpayload.favorites_static_block});
				
				this.setState({pendingride_label: formpayload.pendingride_label});		
				this.setState({pendingride_static_block: formpayload.pendingride_static_block});
				this.setState({recentride_label: formpayload.recentride_label});		
				this.setState({recentride_static_block: formpayload.recentride_static_block});
				
				
				this.setState({notify_font_label_size: formpayload.notify_font_label_size});		
				this.setState({notify_font_text_size: formpayload.notify_font_text_size});		
				this.setState({notify_height: formpayload.notify_height});	
				this.setState({styleimported: defaultfont.styleimported});
				this.setState({stylefontfamily: defaultfont.stylefontfamily});	
				this.fontStyle(defaultfont.styleimported,defaultfont.stylefontfamily);
				this.setState({user_image_preview1: formpayload.first_file});
				this.setState({user_image_preview2: formpayload.map_file});
				this.setState({user_image_preview3: formpayload.date_time_file});
				this.setState({user_image_preview4: formpayload.car_list_file});
				this.setState({user_image_preview5: formpayload.booking_page_file});				
				this.setState({user_image_preview6: formpayload.favorites_page_file});				
				this.setState({user_image_preview7: formpayload.pendingride_page_file});				
				this.setState({user_image_preview8: formpayload.recentride_page_file});				
				this.setState({selectedFont: Props.notification[0].font});
				
				if(formpayload.first_page_content_show === '1'){
    				this.setState({first_page_content_show: true});
    			}else{
    				this.setState({first_page_content_show: false});
    			}
				if(formpayload.map_page_content_show === '1'){
    				this.setState({map_page_content_show: true});
    			}else{
    				this.setState({map_page_content_show: false});
    			}
				if(formpayload.date_time_content_show === '1'){
    				this.setState({date_time_content_show: true});
    			}else{
    				this.setState({date_time_content_show: false});
    			}
				if(formpayload.car_list_content_show === '1'){
    				this.setState({car_list_content_show: true});
    			}else{
    				this.setState({car_list_content_show: false});
    			}
				
				if(formpayload.booking_content_show === '1'){
    				this.setState({booking_content_show: true});
    			}else{
    				this.setState({booking_content_show: false});
    			}
				
				if(formpayload.favorites_content_show === '1'){
    				this.setState({favorites_content_show: true});
    			}else{
    				this.setState({favorites_content_show: false});
    			}
				
				if(formpayload.pendingride_content_show === '1'){
    				this.setState({pendingride_content_show: true});
    			}else{
    				this.setState({pendingride_content_show: false});
    			}
				
				if(formpayload.recentride_content_show === '1'){
    				this.setState({recentride_content_show: true});
    			}else{
    				this.setState({recentride_content_show: false});
    			}
			}
		} 
		if(Props.updatenotification !== this.props.updatenotification){					
		
			if(Object.keys(Props.updatenotification).length > 0){				
				this.setState({ Loading: false });
				const formpayload1 = Props.updatenotification[0].results;
				
				console.log(formpayload1,"TEst NOty")	
				
				const defaultfont = Props.updatenotification[0].defaultfont;
				this.setState({plugin_id:formpayload1.plugin_id});
				this.setState({first_page_label: formpayload1.first_page_label});
				this.setState({first_page_static_block: formpayload1.first_page_static_block});
				this.setState({map_label: formpayload1.map_label});
				this.setState({map_static_block: formpayload1.map_static_block});
				this.setState({date_time_label: formpayload1.date_time_label});
				this.setState({date_time_static_block: formpayload1.date_time_static_block});
				this.setState({car_list_label: formpayload1.car_list_label});
				this.setState({car_list_static_block: formpayload1.car_list_static_block});
				
				this.setState({booking_label: formpayload1.booking_label});
				this.setState({booking_static_block: formpayload1.booking_static_block});
				
				this.setState({favorites_label: formpayload1.favorites_label});
				this.setState({favorites_static_block: formpayload1.favorites_static_block});
				
				this.setState({pendingride_label: formpayload1.pendingride_label});
				this.setState({pendingride_static_block: formpayload1.pendingride_static_block});
				this.setState({recentride_label: formpayload1.recentride_label});
				this.setState({recentride_static_block: formpayload1.recentride_static_block});
				
				
				this.setState({notify_font_label_size: formpayload1.notify_font_label_size});
				this.setState({notify_font_text_size: formpayload1.notify_font_text_size});
				this.setState({notify_height: formpayload1.notify_height});
				this.setState({styleimported: defaultfont.styleimported});
				this.setState({stylefontfamily: defaultfont.stylefontfamily});
				
				this.setState({selectedFont: Props.updatenotification[0].font})
				this.fontStyle(defaultfont.styleimported,defaultfont.stylefontfamily);
				
				if(formpayload1.first_page_content_show === '1'){
    				this.setState({first_page_content_show: true});
    			}else{
    				this.setState({first_page_content_show: false});
    			}
				if(formpayload1.map_page_content_show === '1'){
    				this.setState({map_page_content_show: true});
    			}else{
    				this.setState({map_page_content_show: false});
    			}
				if(formpayload1.date_time_content_show === '1'){
    				this.setState({date_time_content_show: true});
    			}else{
    				this.setState({date_time_content_show: false});
    			}
				if(formpayload1.car_list_content_show === '1'){
    				this.setState({car_list_content_show: true});
    			}else{
    				this.setState({car_list_content_show: false});
    			}
				if(formpayload1.booking_content_show === '1'){
    				this.setState({booking_content_show: true});
    			}else{
    				this.setState({booking_content_show: false});
    			}

				if(formpayload1.favorites_content_show === '1'){
    				this.setState({favorites_content_show: true});
    			}else{
    				this.setState({favorites_content_show: false});
    			}	
				if(formpayload1.pendingride_content_show === '1'){
    				this.setState({pendingride_content_show: true});
    			}else{
    				this.setState({pendingride_content_show: false});
    			}	
				if(formpayload1.recentride_content_show === '1'){
    				this.setState({recentride_content_show: true});
    			}else{
    				this.setState({recentride_content_show: false});
    			}					

				$('.success_message').html('<div class="status_sucess"><h3>'+ Props.updatenotification[0].message+'</h3></div>');
                	scrollToTop();
    				setTimeout(
						function() {
							$('.success_message').html('');
						}
						.bind(this),
						3000
						);
			}
		}
		
		if(Props.fontname !== this.props.fontname){
    		if(Object.keys(Props.fontname).length > 0){
    		    this.setState({ fonttype : Props.fontname[0].fontlist})

    		}
    	}
			

   }
  
  setselectedvalue(selectsers){
	    const fontArray = selectsers.map((fonttype, index) => ({
	     id: index,
	     name: fonttype,
	    }));
    // Dynamically create select list
    let fonts = [];
    fontArray.map(item =>
    fonts.push({ label: item.name.label, value: item.name.value }),
    );
    this.setState({selectedFont : fonts})
  }
  
  handleChangeFont = selectedFont => {
      this.setState
	  ({ selectedFont});
      this.setState({ fontvalue : selectedFont.value });
      if(selectedFont.value === ''){
        $('.errorfont').html('<span class="errorspan">Please select the font</span>');
      }else{
      	 $('.errorfont').html('');
		 var qs = require('qs');
		 var postObject = {
				tf_id: selectedFont.value,
				admin_id:  localStorage.getItem("admin_id")
				};				
		var t_this = this;
		  axios.post(apiUrl+"plugin/imported_font_style",qs.stringify(postObject))
		  .then(function (response) {
			  if(response.data.result_set != ''){	 
				  
				  var styleimported = response.data.result_set.tf_import_url;
				  var stylefontfamily = response.data.result_set.tf_font_family;
				  t_this.fontStyle(styleimported,stylefontfamily);			  
				  
			  }else{
				  console.log('No data');
			  }
			
		  })
		  .catch(function (error) {
			console.log(error);
		  });
      }
   }
   fontStyle = (styleimported,stylefontfamily) =>{
			  
			var css = document.createElement('style'); 
            css.type = 'text/css';        
            if (css.styleSheet) { 
                css.styleSheet.cssText = styleimported; 
			}
            else { 
                css.appendChild(document.createTextNode(styleimported)); 
			}              
            document.getElementsByTagName("head")[0].appendChild(css);
			
			$(".samplefont p").attr('style', 'font-family: '+ stylefontfamily +' !important'); 
	}
	
	removeImage=(val,event)=>{console.log(val)
		if(val === 'first_file'){
			this.setState({user_image_preview1:'',remove_preview1:true});
		}else if(val === 'map_file'){
			this.setState({user_image_preview2:'',remove_preview2:true});
		}else if(val === 'date_time_file'){
			this.setState({user_image_preview3:'',remove_preview3:true});
		}else if(val === 'car_list_file'){
			this.setState({user_image_preview4:'',remove_preview4:true});
		}else if(val === 'booking_page_file'){
			this.setState({user_image_preview5:'',remove_preview5:true});
		}else if(val === 'favorites_page_file'){
			this.setState({user_image_preview6:'',remove_preview6:true});
		}else if(val === 'pendingride_page_file'){
			this.setState({user_image_preview7:'',remove_preview7:true});
		}else if(val === 'recentride_page_file'){
			this.setState({user_image_preview8:'',remove_preview8:true});
		}
		
	}
  
	onChangeHandler=(val,event)=>{ 
		
		if(val === 'first_file'){
			let reader = new FileReader();
			console.log(reader)
			const file1 = event.target.files[0];			
			reader.onloadend = () => {
			  this.setState({
				user_image_name1: file1.name,
				user_image_preview1: reader.result,
				user_image1: file1
			  });
			};
			reader.readAsDataURL(file1);
		}else if(val === 'map_file'){
			let reader = new FileReader();
			const file2 = event.target.files[0];			
			reader.onloadend = () => {
			  this.setState({
				user_image_name2: file2.name,
				user_image_preview2: reader.result,
				user_image2: file2
			  });
			};
			reader.readAsDataURL(file2);
		}else if(val === 'date_time_file'){
			let reader = new FileReader();
			const file3 = event.target.files[0];			
			reader.onloadend = () => {
			  this.setState({
				user_image_name3: file3.name,
				user_image_preview3: reader.result,
				user_image3: file3
			  });
			};
			reader.readAsDataURL(file3);
		}else if(val === 'car_list_file'){
			let reader = new FileReader();
			const file4 = event.target.files[0];			
			reader.onloadend = () => {
			  this.setState({
				user_image_name4: file4.name,
				user_image_preview4: reader.result,
				user_image4: file4
			  });
			};
			reader.readAsDataURL(file4);
		}else if(val === 'booking_page_file'){ console.log('booking_page_file');
			let reader = new FileReader();
			const file5 = event.target.files[0];			
			reader.onloadend = () => {
			  this.setState({
				user_image_name5: file5.name,
				user_image_preview5: reader.result,
				user_image5: file5
			  });
			};
			reader.readAsDataURL(file5);
		}else if(val === 'favorites_page_file'){ console.log('favorites_page_file');
			let reader = new FileReader();
			const file6 = event.target.files[0];			
			reader.onloadend = () => {
			  this.setState({
				user_image_name6: file6.name,
				user_image_preview6: reader.result,
				user_image6: file6
			  });
			};
			reader.readAsDataURL(file6);
		}else if(val === 'pendingride_page_file'){ console.log('pendingride_page_file');
			let reader = new FileReader();
			const file7 = event.target.files[0];			
			reader.onloadend = () => {
			  this.setState({
				user_image_name7: file7.name,
				user_image_preview7: reader.result,
				user_image7: file7
			  });
			};
			reader.readAsDataURL(file7);
		}else if(val === 'recentride_page_file'){ console.log('recentride_page_file');
			let reader = new FileReader();
			const file8 = event.target.files[0];			
			reader.onloadend = () => {
			  this.setState({
				user_image_name8: file8.name,
				user_image_preview8: reader.result,
				user_image8: file8
			  });
			};
			reader.readAsDataURL(file8);
		}
		
		
	/**/
  }

  
  render() {
	  
	  let { user_image_preview1,user_image_preview2,user_image_preview3,user_image_preview4,user_image_preview5,user_image_preview6,user_image_preview7,user_image_preview8} = this.state;
	let carImg1 = '', carImg2 = '', carImg3 ='', carImg4 ='', carImg5 ='', carImg6 ='', carImg7 ='', carImg8 ='';
	let preview1 = '', preview2 = '', preview3 = '', preview4 ='', preview5 ='', preview6 ='', preview7 ='', preview8 ='';
	
	if (user_image_preview1!== null && user_image_preview1!== '') {
	    carImg1 = carImageUrl + "/" + user_image_preview1;
		preview1 = <img className="img_class" src={carImg1} alt="" />;
	}
	if (user_image_preview2!== null && user_image_preview2!== '') {
	    carImg2 = carImageUrl + "/" + user_image_preview2;
		preview2 = <img className="img_class" src={carImg2} alt="" />;
	}
	if (user_image_preview3!== null && user_image_preview3!== '') {
	    carImg3 = carImageUrl + "/" + user_image_preview3;
		preview3 = <img className="img_class" src={carImg3} alt="" />;
	}
	if (user_image_preview4!== null && user_image_preview4!== '') {
	    carImg4 = carImageUrl + "/" + user_image_preview4;
		preview4 = <img className="img_class" src={carImg4} alt="" />;
	}	
	if (user_image_preview5!== null && user_image_preview5!== '') {
	    carImg5 = carImageUrl + "/" + user_image_preview5;
		preview5 = <img className="img_class" src={carImg5} alt="" />;
	}
	if (user_image_preview6!== null && user_image_preview6!== '') {
	    carImg6 = carImageUrl + "/" + user_image_preview6;
		preview6 = <img className="img_class" src={carImg6} alt="" />;
	}
	if (user_image_preview7!== null && user_image_preview7!== '') {
	    carImg7 = carImageUrl + "/" + user_image_preview7;
		preview7 = <img className="img_class" src={carImg7} alt="" />;
	}
	if (user_image_preview8!== null && user_image_preview8!== '') {
	    carImg8 = carImageUrl + "/" + user_image_preview8;
		preview8 = <img className="img_class" src={carImg8} alt="" />;
	}
	
	  const fontArray = this.state.fonttype.map ((fonttype, index) => ({
	id: index,
	name: fonttype,
	}));
	// Dynamically create select list
	let fonts = [];
	fontArray.map(item =>
	fonts.push({ label: item.name.label, value: item.name.value }),
	);

	const {selectedFont} = this.state;
	
	    return (
	<div className="wrapper"> 
		<Header />
		<MenuSidebar />
			

		<div className="content">	
			<div className="content-wrapper">
				<div className="content-body">
					<form className="settings" onSubmit={ e => { this.handleFormSubmit(this); e.preventDefault(); }}>
						<div className="form-wrapper">
							<div className="success_message"></div>
							<div className="title">
								<h4>User Notification</h4>
							</div>
							<div className="form-first-content">
							<div className="form-row">
								
								<div className="form-left">
									<div className="form-group"> 
										<label>First Page Label:</label>
										<input className="form-control" name="first_page_label" onChange={this.handleInputChange} value={this.state.first_page_label} />
									</div>
									<div className="form-group"> 
										<label>First Page:</label>
										<textarea className="form-control" name="first_page_static_block" onChange={this.handleInputChange} value={this.state.first_page_static_block} />
									</div>
									
								</div>	
								<div className="form-right">
								<div className="form-group"> 
										<label>Show Notification:</label>
										
										<label className="switch">
										<input type="checkbox" className="" name="first_page_content_show" onChange={this.handleInputChange} checked={this.state.first_page_content_show} />
										<span className="slider round"></span>
										</label>
									</div>
									
									<div className="form-group image-section">
										<label>First Page Image:</label>
										   <div className="choose-file">
										{this.state.isHidden && <div className="image_success"></div>}
										   <span className="profile_btn"><input type="file" name="first_file" onChange={(e) => this.onChangeHandler('first_file',e)} /></span>
										</div>
										{preview1}
										{user_image_preview1?<a className="profile_btn-close" href="javascript:void(0);" onClick={(e) =>this.removeImage('first_file',e)}>X</a>:''}
										<div className="errorcarimage"></div> 
									</div>
							    </div>
								</div>		
							</div>

							


							<div className="form-first-content">
								<div className="form-row">
									
									<div className="form-left">
									<div className="form-group"> 
										<label>Map Label:</label>
										<input className="form-control" name="map_label" onChange={this.handleInputChange} value={this.state.map_label} />
									</div>
									<div className="form-group"> 
										<label>Map Page:</label>
										<textarea className="form-control" name="map_static_block" onChange={this.handleInputChange} value={this.state.map_static_block} />
									</div>
								

									</div>
									<div className="form-right">
									<div className="form-group"> 
										<label>Show Notification:</label>

										<label className="switch">
										<input type="checkbox" className="" name="map_page_content_show" onChange={this.handleInputChange} checked={this.state.map_page_content_show} />
										<span className="slider round"></span>
										</label>
										
									</div>
									<div className="form-group">
										<label>Map Page Image:</label>
										   <div className="choose-file">
										{this.state.isHidden && <div className="image_success"></div>}
										   <span className="profile_btn"><input type="file" name="map_file" onChange={(e) => this.onChangeHandler('map_file',e)} /></span>
										</div>
										{preview2}
										{user_image_preview2?<a className="profile_btn-close" href="javascript:void(0);" onClick={(e) =>this.removeImage('map_file',e)}>X</a>:''}
										<div className="errorcarimage"></div> 
									</div>
									</div>
								</div>
						    </div>	


							<div className="form-first-content">
								<div className="form-row">
									
									<div className="form-left">
									<div className="form-group"> 
										<label>Date/Time Label:</label>
										<input className="form-control" name="date_time_label" onChange={this.handleInputChange} value={this.state.date_time_label} />
									</div>
									<div className="form-group"> 
										<label>Date/Time Page:</label>
										<textarea className="form-control" name="date_time_static_block" onChange={this.handleInputChange} value={this.state.date_time_static_block} />
									</div>
									

									</div>
									<div className="form-right">
									<div className="form-group"> 
										<label>Show Notification:</label>

										<label className="switch">
										<input type="checkbox" className="" name="date_time_content_show" onChange={this.handleInputChange} checked={this.state.date_time_content_show} />
										<span className="slider round"></span>
										</label>
										
									</div>
									<div className="form-group">
										<label>DateTime Image:</label>
										   <div className="choose-file">
										{this.state.isHidden && <div className="image_success"></div>}
										   <span className="profile_btn"><input type="file" name="date_time_file" onChange={(e) => this.onChangeHandler('date_time_file',e)} /></span>
										</div>
										{preview3}
										{user_image_preview3?<a className="profile_btn-close" href="javascript:void(0);" onClick={(e) =>this.removeImage('date_time_file',e)}>X</a>:''}
										<div className="errorcarimage"></div> 
									</div>

									</div>
								</div>
						    </div>	



								<div className="form-first-content">
								<div className="form-row">
									
									<div className="form-left">
									<div className="form-group"> 
										<label>Car List Label:</label>
										<input className="form-control" name="car_list_label" onChange={this.handleInputChange} value={this.state.car_list_label} />
									</div>
									<div className="form-group"> 
										<label>Car List Page:</label>
										<textarea className="form-control" name="car_list_static_block" onChange={this.handleInputChange} value={this.state.car_list_static_block} />
									</div>
									

									</div>
									<div className="form-right">
									<div className="form-group"> 
										<label>Show Notification:</label>
										<label className="switch">
										<input type="checkbox" className="" name="car_list_content_show" onChange={this.handleInputChange} checked={this.state.car_list_content_show} />
										<span className="slider round"></span>
										</label>



										
									</div>
									<div className="form-group">
										<label>Car List Image:</label>
										   <div className="choose-file">
										{this.state.isHidden && <div className="image_success"></div>}
										   <span className="profile_btn"><input type="file" name="car_list_file" onChange={(e) => this.onChangeHandler('car_list_file',e)} /></span>
										</div>
										{preview4}
										{user_image_preview4?<a className="profile_btn-close" href="javascript:void(0);" onClick={(e) =>this.removeImage('car_list_file',e)}>X</a>:''}
										<div className="errorcarimage"></div> 
									</div>
									</div>
								</div>
						    </div>	


							<div className="form-first-content">
								<div className="form-row">
									
									<div className="form-left">
										<div className="form-group"> 
											<label>Booking Page Label:</label>
											<input className="form-control" name="booking_label" onChange={this.handleInputChange} value={this.state.booking_label} />
										</div>
										<div className="form-group"> 
											<label>Booking Page:</label>
											<textarea className="form-control" name="booking_static_block" onChange={this.handleInputChange} value={this.state.booking_static_block} />
										</div>								
									</div>
									<div className="form-right">
										<div className="form-group"> 
											<label>Show Notification:</label>

											<label className="switch">
											<input type="checkbox" className="" name="booking_content_show" onChange={this.handleInputChange} checked={this.state.booking_content_show} />
											<span className="slider round"></span>
											</label>
											
										</div>
									
										<div className="form-group">
											<label>Booking Page Image:</label>
											   <div className="choose-file">
											{this.state.isHidden && <div className="image_success"></div>}
											   <span className="profile_btn"><input type="file" name="booking_page_file" onChange={(e) => this.onChangeHandler('booking_page_file',e)} /></span>
											</div>
											{preview5}
											{user_image_preview5?<a className="profile_btn-close" href="javascript:void(0);" onClick={(e) =>this.removeImage('booking_page_file',e)}>X</a>:''}
											<div className="errorcarimage"></div> 
										</div>
									</div>
								</div>
						    </div>	


{/*===================== New Notification starts here ============================*/}
							<div className="form-first-content">
								<div className="form-row">
									
									<div className="form-left">
										<div className="form-group"> 
											<label>Favorites Page Label:</label>
											<input className="form-control" name="favorites_label" onChange={this.handleInputChange} value={this.state.favorites_label} />
										</div>
										<div className="form-group"> 
											<label>Favorites Page:</label>
											<textarea className="form-control" name="favorites_static_block" onChange={this.handleInputChange} value={this.state.favorites_static_block} />
										</div>								
									</div>
									<div className="form-right">
										<div className="form-group"> 
											<label>Show Notification:</label>

											<label className="switch">
											<input type="checkbox" className="" name="favorites_content_show" onChange={this.handleInputChange} checked={this.state.favorites_content_show} />
											<span className="slider round"></span>
											</label>
											
										</div>
									
										<div className="form-group">
											<label>Favorites Page Image:</label>
											   <div className="choose-file">
											{this.state.isHidden && <div className="image_success"></div>}
											   <span className="profile_btn"><input type="file" name="favorites_page_file" onChange={(e) => this.onChangeHandler('favorites_page_file',e)} /></span>
											</div>
											{preview6}
											{user_image_preview6?<a className="profile_btn-close" href="javascript:void(0);" onClick={(e) =>this.removeImage('favorites_page_file',e)}>X</a>:''}
											<div className="errorcarimage"></div> 
										</div>
									</div>
								</div>
						    </div>	
							
							
							<div className="form-first-content">
								<div className="form-row">
									
									<div className="form-left">
										<div className="form-group"> 
											<label>Pendingride Page Label:</label>
											<input className="form-control" name="pendingride_label" onChange={this.handleInputChange} value={this.state.pendingride_label} />
										</div>
										<div className="form-group"> 
											<label>Pendingride Page:</label>
											<textarea className="form-control" name="pendingride_static_block" onChange={this.handleInputChange} value={this.state.pendingride_static_block} />
										</div>								
									</div>
									<div className="form-right">
										<div className="form-group"> 
											<label>Show Notification:</label>

											<label className="switch">
											<input type="checkbox" className="" name="pendingride_content_show" onChange={this.handleInputChange} checked={this.state.pendingride_content_show} />
											<span className="slider round"></span>
											</label>
											
										</div>
									
										<div className="form-group">
											<label>Pendingride Page Image:</label>
											   <div className="choose-file">
											{this.state.isHidden && <div className="image_success"></div>}
											   <span className="profile_btn"><input type="file" name="pendingride_page_file" onChange={(e) => this.onChangeHandler('pendingride_page_file',e)} /></span>
											</div>
											{preview7}
											{user_image_preview7?<a className="profile_btn-close" href="javascript:void(0);" onClick={(e) =>this.removeImage('pendingride_page_file',e)}>X</a>:''}
											<div className="errorcarimage"></div> 
										</div>
									</div>
								</div>
						    </div>
							
							
							
							<div className="form-first-content">
								<div className="form-row">
									
									<div className="form-left">
										<div className="form-group"> 
											<label>Recentride Page Label:</label>
											<input className="form-control" name="recentride_label" onChange={this.handleInputChange} value={this.state.recentride_label} />
										</div>
										<div className="form-group"> 
											<label>Recentride Page:</label>
											<textarea className="form-control" name="recentride_static_block" onChange={this.handleInputChange} value={this.state.recentride_static_block} />
										</div>								
									</div>
									<div className="form-right">
										<div className="form-group"> 
											<label>Show Notification:</label>

											<label className="switch">
											<input type="checkbox" className="" name="recentride_content_show" onChange={this.handleInputChange} checked={this.state.recentride_content_show} />
											<span className="slider round"></span>
											</label>
											
										</div>
									
										<div className="form-group">
											<label>Recentride Page Image:</label>
											   <div className="choose-file">
											{this.state.isHidden && <div className="image_success"></div>}
											   <span className="profile_btn"><input type="file" name="recentride_page_file" onChange={(e) => this.onChangeHandler('recentride_page_file',e)} /></span>
											</div>
											{preview8}
											{user_image_preview8?<a className="profile_btn-close" href="javascript:void(0);" onClick={(e) =>this.removeImage('recentride_page_file',e)}>X</a>:''}
											<div className="errorcarimage"></div> 
										</div>
									</div>
								</div>
						    </div>
							
							
							
{/*==================================================================================================*/}
							
							<div className="form-row">	
								<div className="form-left">
									<div className="form-group"> 
										<label>Font Label Size:</label>
										<input className="form-control" name="notify_font_label_size" onChange={this.handleInputChange} value={this.state.notify_font_label_size} />
									</div>
									<div className="form-group"> 
										<label>Font Text Size:</label>
										<input className="form-control" name="notify_font_text_size" onChange={this.handleInputChange} value={this.state.notify_font_text_size} />
									</div>
									<div className="form-group"> 
										<label>Notification Height:</label>
										<input className="form-control" name="notify_height" onChange={this.handleInputChange} value={this.state.notify_height} />
									</div>
									
								<div className="font-section">
								    <div className="form-group">
										<label>Font:</label>
										 <Select 
											 options={fonts}  
											 value={selectedFont?selectedFont:{ value: '0', label: 'Select Font Type' }}
											 onChange={this.handleChangeFont}
											 placeholder="Select Font Type" />
										<div className="errorfont"></div>
										<div className="samplefont">
											<p>Hello World!</p></div>
										</div>
									</div>
									
				
								</div>
								
								
							</div>
						
						
							<div className="form-row">
								
								<div className="btn-group right">				
									<button className="btn btn_orange btn_minwid login_submit animate-btn2" type="submit" disabled={(this.state.Loading ===true)?true:false}> {this.state.Loading ===true &&
													<span className="load-data">Loading</span> 
													} Submit
									</button>	
								</div>
								
							</div>
						</div>
					</form>	
				</div>
			</div>
		</div>
	</div>
	
		)
  }
}

const mapStateTopProps = (state) => {
  return {
	notification: state.notification,
	updatenotification: state.updatenotification,
	fontname : state.fontname
  }
}

const mapDispatchToProps = (dispatch) => {
  return {	   
	   getUserNotificationData: (formPayload) =>{
		dispatch({ type: GET_USERNOTIFICATION, formPayload});
       },
	   getFontName: () => {
        dispatch({ type: GET_FONTNAME });
      },
	   getUpdateNotificationData: (formPayload) => { 
		  dispatch({ type: GET_UPDATENOTIFICATION, formPayload });
		}
   	  }
}
export default connect(mapStateTopProps, mapDispatchToProps)(withRouter(Notification));