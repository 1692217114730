import React, { Component } from 'react';
import { Link, withRouter } from "react-router-dom";
import { connect } from 'react-redux';
import Pagination from "react-js-pagination";
import $ from 'jquery';
import {
  Row,
  Col,
  Modal,
  Table,
  OverlayTrigger,
  ButtonToolbar,
  Tooltip,
  Button
} from "react-bootstrap";
import Header from '../Layouts/Header';
import MenuSidebar from '../Layouts/MenuSidebar';
import { scrollToTopValidate,PageTitle } from "../Helpers/SettingHelper";

import { GET_COMPLETEDRIDE } from '../../actions';

class List extends Component {
  
  constructor(props)
  {
    super(props); 
    this.state = {
      completedride:'',
      editable: false,
      priorityid:'',
      priorityvalue:'',
	   activePage: 1,
	   totalRecord: '',
	   search_all:''
    };
	
	var qs = require('qs');
	var postobject = {
		activePage: 1,
    historytype:'completed',
		search_all: '',
    admin_id: localStorage.getItem('admin_id')
	};
    this.props.getCompletedRide(qs.stringify(postobject));
	
	
	 this.handleInputChange = this.handleInputChange.bind(this);
	 this.handleFormSubmit = this.handleFormSubmit.bind(this);
    }

   
   componentDidMount() {
      document.title = PageTitle('Ongoing List');
      if(localStorage.getItem('admin_id') === null){
      this.props.history.push('/');
      }
   }


   componentWillReceiveProps(Props){
	if(Props.completedride !== this.props.completedride){
		if(Object.keys(Props.completedride).length > 0){
			this.setState({totalRecord : Props.completedride[0].records_count})
		}
	}
		
   }

deletevehicle(id){
        var qs = require('qs');
        var postObject = {
          admin_id : localStorage.getItem("admin_id"),
          car_id  :id
        };
      this.props.getDeleteCar(qs.stringify(postObject));
}
handleChange = (name,carid,event) => {
  console.log(carid)
  this.setState({priorityid: name,priorityvalue: event.target.value})
   var qs = require('qs');
        var postObject = {
          value :event.target.value,
          car_id  :carid,
          admin_id: localStorage.getItem('admin_id')
    };
  this.props.getPriorityUpdate(qs.stringify(postObject));
}



handlePageChange(pageNumber) {
   // console.log(`active page is ${pageNumber}`);
    this.setState({activePage: pageNumber});
    var qs = require('qs');
	var postobject = {
		activePage: pageNumber,
    historytype:'completed',
		search_all: this.state.search_all,
    admin_id: localStorage.getItem('admin_id')
	};
	this.props.getCompletedRide(qs.stringify(postobject))
  }
  
handleInputChange(event) {
  const {name, value} = event.target;      
  this.setState({
	  [name]: value
	});
  
}
 handleFormSubmit = () => {
			
				this.setState({Loading:true});
				var qs = require('qs');
				const formPayload = this.state;
			   
				var postObject = {			       
					activePage   : 1,
          historytype:'completed',
					search_all : formPayload.search_all,
          admin_id: localStorage.getItem('admin_id')					
				};
				this.props.getCompletedRide(qs.stringify(postObject));
			
	}  
  
  
  vehiclelist() {
    var completedride = this.props.completedride[0];
    if (completedride != "undefined" && completedride != null) {
      if (completedride.status == "success") {
        if (Object.keys(completedride).length > 0) {
          const vehiclelistDetails = completedride.records.map(
            (completedride, Index) => {
              let sno = Index+1;
              
              return (
                <tr key={completedride.id}>
                <td>{sno}</td>
                  <td> {completedride.username?completedride.username:'Guest User'}</td>
                  <td>{completedride.booking_id}</td>
                  <td>{completedride.from_location}</td>
                  <td>{completedride.to_location}</td>
                  <td>{completedride.driver_fname != ''?completedride.driver_fname:"-"}</td>
                  <td>{completedride.driver_mobile != ''?completedride.driver_mobile:"-"}</td>
                  <td>Completed</td>
                   <td>{completedride.car_name}</td>
                   <td>${completedride.original_amount}</td>
                 {/* <td className="actiongroup">
                    <Link to={{ pathname: '/car/view/'+bookinglist.id}} className="" title="view">  <i className="fa fa-eye" aria-hidden="true"></i></Link>
                   <Link onClick={this.deletevehicle.bind(this,bookinglist.id)} className="" title="Delete">  <i className="fa fa-trash" aria-hidden="true"></i></Link>
                   
                   
                  </td>*/}
                </tr>
              );
            }
          );
          return vehiclelistDetails;
        }
      } else {
        return (
          <tr>
            <td colspan="10" className="v-align-nr">
              No Result
            </td>
          </tr>
        );
      }
    }
  }
  
  render() {
    return (
      <div className="wrapper"> 
 
    <Header />
    <MenuSidebar currentpage="completedride" />  
      
      <div className="content"> 
        <div className="content-wrapper">
            <div className="car-listing-wrap">

              <div className="listing-header">
                <div className="title">
                  <h3>Completed Rides</h3>
                </div>
				<div className="search-list">
				 <form className="edit" id="edit" onSubmit={ e => { this.handleFormSubmit(this); e.preventDefault(); }} >     
				  <div className="search-inner">
						<input type="text" name="search_all" onChange={this.handleInputChange} className="form-control" value={this.state.search_all} placeholder="Search" />				  
				   <button className="" type="submit">Submit</button>
					<div className="erroremail"></div>
				</div>
				   
				  </form>
                </div>
              </div>            
              {/*<div className="statusmessage"></div>*/}

              <div className="car-listing-row">
                <Table striped bordered hover>
                  <thead>
                    <tr>
                      <th>S.NO</th>
                      <th>Rider</th>
                      <th>Booking ID</th>
                      <th>From Location</th>                      
                      <th>To Location</th>
          					  <th>Driver Info</th>
          					  <th>Driver Mobile</th>
          					  <th>Ride Status</th>
                      <th>Selected Car</th>
                      <th>Ride Amount</th>
                     
                    </tr>
                  </thead>
                  <tbody>{this.vehiclelist()}</tbody>
                </Table>
          	     <div className="text-center">
          				 <Pagination
                    activePage={this.state.activePage}
                    itemsCountPerPage={10}
                    totalItemsCount={this.state.totalRecord}
                    pageRangeDisplayed={5}
                    onChange={this.handlePageChange.bind(this)}
                  />
          		</div>
          </div>
         </div> 
        </div>  
      </div>
      
    </div>
    );
  }
}

const mapStateTopProps = (state) => {
  return {
    completedride: state.completedride,
  }
  
}

const mapDispatchToProps = (dispatch) => {
  return {
    getCompletedRide: (formVal) =>{
       dispatch({ type: GET_COMPLETEDRIDE, formVal });
    },
  }
}
export default connect(mapStateTopProps, mapDispatchToProps)(withRouter(List));