import login from "./login";
import settings from "./settings";
import notification from './notification';
import updatenotification from './updatenotification';
import changepassword from "./changepassword";
import updatesetting from './updatesetting';
import forgetpassword from './forgetpassword';
import profile from './profile';
import updateprofile from './updateprofile';
import vehicleadd from './vehicleadd';
import vehiclelist from './vehiclelist';
import vehicleedit from './vehicleedit';
import vehicletype from './vehicletype';

import companylist from './companylist';
import companyadd from './companyadd';
import companyedit from './companyedit';
import companydetail from './companydetail';
import caradd from './caradd';
import carlist from './carlist';
import caredit from './caredit';
import ongoingrideview from './ongoingrideview';
import deletevehicle from './deletevehicle';
import deletecar from './deletecar';
import priorityupdate from './priorityupdate';
import bookinghistory from './bookinghistory';
import fontlist from './fontlist';
import fontname from './fontname';
import fontadd from './fontadd';
import couponlist from './couponlist';
import coupondetails from './coupondetails';
import couponadd from './couponadd';
import couponedit from './couponedit';
import userlist from './userlist';
import changestatus from './changestatus';
import exportuser from './exportuser';
import exportpayment from './exportpayment';
import fontdelete from './fontdelete';
import driverdelete from './driverdelete';
import useredit from './useredit';
import userimport from './userimport';

import driverimport from './driverimport';
import driverlist from './driverlist';
import driverstatus from './driverstatus';
import driverstripelink from './driverstripelink';
import exportdriver from './exportdriver';
import locateddrivers from './locateddrivers';
import driveredit from './driveredit';

import ongoingride from './ongoingride';
import completedride from './completedride';
import incompleteride from './incompleteride';
import userdetails from './userdetails';

import driverdetail from './driverdetail';

import vehicledetail from './vehicledetail';
import cardetail from './cardetail';
import useradd from './useradd';

import driverdropdownlist from './driverdropdownlist';
import driverassigned from './driverassigned';

import templatelist from './templatelist';
import templatedetail from './templatedetail';
import templateadd from './templateadd';
import templatedelete from './templatedelete';
import templateedit from './templateedit';
import domainlist from './domainlist';
import paymentlist from './paymentlist';
import invitefriend from './invitefriend';
import updateinvitefriend from './updateinvitefriend';
import vechiclebytype from './vechiclebytype';
import gallery from './gallery';
import favourite from './favourite';
import updatefavourite from './updatefavourite';

import { combineReducers } from "redux";


const rootReducer = combineReducers({
  login: login,
  settings      : settings,
  updatesetting : updatesetting,
  changepassword: changepassword,
  forgetpassword: forgetpassword,
  profile       : profile,
  updateprofile : updateprofile,
  vehicleadd    : vehicleadd,
  vehiclelist   : vehiclelist,
  companydetail : companydetail,
  companylist   : companylist,
  companyadd   : companyadd,
  companyedit   : companyedit,
  vehicleedit   : vehicleedit,
  vehicletype   : vehicletype,
  caradd        : caradd,
  carlist       : carlist,
  caredit       : caredit,
  deletevehicle : deletevehicle,
  deletecar     : deletecar,
  priorityupdate     : priorityupdate,
  bookinghistory     : bookinghistory,
  fontlist           : fontlist,
  fontname           : fontname,
  fontadd            : fontadd,
  couponlist           : couponlist,
  coupondetails           : coupondetails,
  couponadd            : couponadd,
  couponedit            : couponedit,
  userlist			 : userlist,
  exportuser     : exportuser,
  exportpayment     : exportpayment,
  fontdelete         : fontdelete,
  driverdelete         : driverdelete,
  useredit: useredit,
  userimport: userimport,
  driverimport: driverimport,
  driverlist: driverlist,
  driverdropdownlist: driverdropdownlist,
  driverassigned: driverassigned,
  driverstatus: driverstatus,
  driverstripelink: driverstripelink,
  exportdriver: exportdriver,
  locateddrivers: locateddrivers,
  driveredit: driveredit,
  ongoingride: ongoingride,
  ongoingrideview: ongoingrideview,
  completedride: completedride,
  incompleteride: incompleteride,
  userdetails: userdetails,
  driverdetail: driverdetail,
  vehicledetail: vehicledetail,
  cardetail    : cardetail,
  useradd      : useradd,
  changestatus: changestatus,
  templatelist  : templatelist,
  templatedetail: templatedetail,
  templateadd   : templateadd,
  templatedelete : templatedelete,
  templateedit: templateedit,
  domainlist: domainlist,
  paymentlist: paymentlist,
  notification: notification,
  updatenotification: updatenotification,
  invitefriend: invitefriend,
  updateinvitefriend: updateinvitefriend,
  vechiclebytype : vechiclebytype,
  gallery: gallery,
  favourite: favourite,
  updatefavourite:  updatefavourite
});

export default rootReducer;
