import { SET_USERINVITEFRIEND } from '../actions';

const invitefriend = (state = [], action) => {
  switch (action.type) { 
	case SET_USERINVITEFRIEND:console.log(action.type)
      return [...action.value];  
    default: return state;
  }
}

export default invitefriend;
