import { all } from "redux-saga/effects";
import { watchGetLoginData } from "./login";
import { watchGetSettingData, watchGetUpdateSetting }from "./setting";
import { watchGetNotification,watchGetUpdateNotification }from "./notification";
import { watchGetUserinvitefriend,watchGetUpdateUserinvitefriend }from "./invitefriend";
import { watchGetChangePassword }from "./changepassword";
import { watchGetForgetPassword } from "./forgetpassword";
import { watchGetProfileDetails, watchGetUpdateProfile } from './profile';
import { watchGetAddVehicle, watchGetVehicleList, watchGetEditVehicle, watchGetDeleteVehicle, watchGetVehicleDetail } from './vehicle';
import {  watchGetCompanyList,watchGetAddCompany, watchGetEditCompany,watchGetCompanyDetail  } from './company';
import { watchGetVehicleType, watchGetAddCar, watchGetCarList, watchGetEditCar, watchGetDeleteCar, watchGetPriorityUpdate, watchGetCarDetail } from './car';
import { watchGetBookingHistory, watchGetOngoingRide, watchGetCompletedRide, watchGetIncompleteRide, watchGetViewOngoingride,watchGetDriverDropList,watchGetAssignDriver } from './booking';
import {watchGetUserList, watchActivateUser, watchExportUser,watchGetEditUser, watchGetUserDetailList,watchGetAddUser,watchGetImportUser} from './user';
import { watchGetFontList, watchGetFontName, watchGetAddFont, watchGetDeleteFont } from './font';
import { watchGetCouponList, watchGetCouponDetailList, watchGetAddCoupon,watchGetEditCoupon } from './coupon';
import { watchGetPaymentList,watchExportPayment } from './payment';
import { watchGetDriverList,watchActivateDriver, watchExportDriver, watchGetEditDriver, watchGetDriverDetail,watchGetDeleteDriver, watchGetLocatedDrivers,watchGetImportDriver, watchStripLinkToDriver,watchGetVehicleByType } from './driver';
import { watchGetTemplateList, watchGetTemplateDetail, watchGetTemplateAdd, watchGetTemplateDelete, watchGetTemplateEdit } from './template';
import { watchGetDomainList } from './common';
import { watchGetGallery } from './gallery';
import { watchGetFavourite, watchGetUpdateFavourite } from './favourites';
export default function*() {
  yield all([
       watchGetLoginData(),
       watchGetSettingData(),
       watchGetNotification(),
       watchGetUpdateNotification(),
	    watchGetUserinvitefriend(),
       watchGetUpdateUserinvitefriend(),
       watchGetUpdateSetting(),
       watchGetChangePassword(),
       watchGetForgetPassword(),
       watchGetProfileDetails(),
       watchGetUpdateProfile(),
       watchGetAddVehicle(),
       watchGetVehicleList(),
	   watchGetCompanyDetail(),
       watchGetCompanyList(),
	     watchGetAddCompany(),
       watchGetEditVehicle(),
       watchGetEditCompany(),
       watchGetDeleteVehicle(),
       watchGetVehicleType(),
       watchGetDriverDropList(),
       watchGetAssignDriver(),
       watchGetAddCar(),
       watchGetCarList(),
       watchGetEditCar(),
       watchGetDeleteCar(),
       watchGetBookingHistory(),
       watchGetPriorityUpdate(),
       watchGetFontList(),
       watchGetPaymentList(),
       watchGetFontName(),
       watchGetAddFont(),
  	   watchGetUserList(),
  	   watchActivateUser(),
  	   watchExportUser(),
  	   watchExportPayment(),
       watchGetDeleteFont(),
       watchGetEditUser(),
       watchGetDriverList(),
       watchActivateDriver(),
       watchStripLinkToDriver(),
       watchExportDriver(),
       watchGetEditDriver(),
       watchGetOngoingRide(),
       watchGetViewOngoingride(),
       watchGetCompletedRide(),
       watchGetIncompleteRide(),
       watchGetUserDetailList(),
       watchGetDriverDetail(),
       watchGetVehicleDetail(),
       watchGetCarDetail(),
       watchGetAddUser(),
	     watchGetImportUser(),
	     watchGetImportDriver(),
	     watchGetDeleteDriver(),
       watchGetLocatedDrivers(),
	     watchGetTemplateList(),
       watchGetTemplateDetail(),
       watchGetTemplateAdd(),
       watchGetTemplateDelete(),
       watchGetTemplateEdit(),
	   watchGetDomainList(),
	   watchGetCouponDetailList(),
       watchGetAddCoupon(),
       watchGetEditCoupon(),
       watchGetCouponList(),
       watchGetVehicleByType(),
       watchGetGallery(),
       watchGetFavourite(),
       watchGetUpdateFavourite()
  ]);
}
