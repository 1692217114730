import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import Header from '../Layouts/Header';
import MenuSidebar from '../Layouts/MenuSidebar';
import Uploadgallery from './Uploadgallery';
import $ from 'jquery';
import Select from 'react-select';
import { lang } from '../Helpers/lang';
import { PageTitle, CheckAuth, scrollToTopValidate, scrollToTop } from "../Helpers/SettingHelper";
import { apiUrl, carImageUrl } from '../Config/Config';
import axios from 'axios';
import { Scrollbars } from 'react-custom-scrollbars';


import {GET_VEHICLETYPE, GET_ADDCAR }  from '../../actions'; 

const initialState = {
			car_name:'',
			car_price:'',
			car_colour:'',
			car_no_plate:'',
			car_model:'',
			ride_information: '',
			selectedvalue: '',
            selectedOption:{ value: 'active', label: 'Active'},
			selectedsizevalue: '',
            selectedsizeOption:{ value: '350x350', label: '350x350'},
			user_image: [],
			vehicletype:[],
			user_image_name: [],
			user_image_preview: [],
			image: [],
			isHidden: false,
			statusmessage:'',
			selectedVehicle:'',
			vehiclevalue:'',
			Loading:false,
			fileName: [],
			thumbDefault: null,
			priority: "",
			additional_charges:'',
			extra_time_limit:'',
			extra_time_limit_charge:'',
			enableweight: false,
			truck_weight:'',
			cancellation_fee:'',
			cancellation_hour:'',
			admin_id: localStorage.getItem('admin_id'),
			enableuploadgalley: false,
			imagelist:'',
			selectedimages:[],
			galleryimg: 'No'
		};
		
class Add extends Component {
	
	fileObj = [];
    fileArray = [];
    imageArray = [];
	
	constructor(props)
	{
		CheckAuth();
		super(props);	
		this.state =  { ...initialState }
		
	   this.handleInputChange = this.handleInputChange.bind(this);
	   this.handleChange = this.handleChange.bind(this);
	   this.handleReset = this.handleReset.bind(this);
	   this.handlesizeChange = this.handlesizeChange.bind(this);
	   this.props.getVehicleType(this.state.admin_id);
	   this.uploadMultipleFiles = this.uploadMultipleFiles.bind(this);

    }


	handleReset() {
         this.setState(initialState);
		 this.fileObj = [];
		 this.fileArray = [];
		 this.imageArray = [];
    }

    handleChange = selectedOption => {
        this.setState({selectedOption})
        this.setState({  selectedvalue : selectedOption.value});
    };
	handlesizeChange = selectedsizeOption => {
        this.setState({selectedsizeOption})
        this.setState({  selectedsizevalue : selectedsizeOption.value});
		
    };
   handleInputChange(event) {

      const {name, value} = event.target;    
      this.setState({
          [name]: value
        });
    }

	componentDidMount() {
		document.title = PageTitle('Car Add');
    }

   handleChangeVehicle = selectedVehicle => {
      this.setState({ selectedVehicle});
      this.setState({ vehiclevalue : selectedVehicle.value });
      if(selectedVehicle.value ==''){
        $('.errorvehicletype').html('<span class="errorspan">Please fill the field</span>');
      }

      let vehicletruck = selectedVehicle.label;
      let lowercasetext = vehicletruck.toLowerCase();
      if(lowercasetext == 'truck'){
      	this.setState({enableweight: true})
      }else{
      	this.setState({enableweight: false, truck_weight:0})
      }
   }


onThumbChanged = (e) => {
    this.setState({
      thumbDefault: e.currentTarget.value
      });
  }
  
uploadMultipleFiles = (e) =>{

		this.fileObj = [];
        this.fileObj.push(e.target.files)
        for (let i = 0; i < this.fileObj[0].length; i++) {
            this.fileArray.push(URL.createObjectURL(this.fileObj[0][i]))
            //this.fileArray.push(this.fileObj[0][i])
            this.imageArray.push(this.fileObj[0][i])

        }
        this.setState({ user_image: this.imageArray });			
	
    }
	
	 handleFormSubmit = () => {
			if(this.validateForm()){
				this.setState({Loading:true});
				 const config = {
			        headers: {
			          'Content-Type': 'multipart/form-data'
			        }
			      };

				const formPayload = this.state;
				var qs = require('qs');
				var status = '';
				if(formPayload.selectedvalue === '' && formPayload.selectedvalue!== 0){
				status =formPayload.selectedOption.value;
				}else{
				status = formPayload.selectedvalue;
				}
				var vehicletype;
				if(formPayload.selectedVehicle !== ''){
				vehicletype =formPayload.selectedVehicle.value;
				}else{
				vehicletype =formPayload.vehiclevalue;
				}
				var imgSize = '';
				if(formPayload.selectedsizevalue === ''){
				imgSize =formPayload.selectedsizeOption.value;
				}else{
				imgSize = formPayload.selectedsizevalue;
				}
                
                var galleryimg = 'No';
				if(Object.keys(formPayload.selectedimages).length >0){
                   galleryimg = 'Yes';
				}

				var postObject = {
					admin_id : localStorage.getItem("admin_id"),
					car_name: formPayload.car_name,
					car_price:formPayload.car_price,
					car_colour:formPayload.car_colour,
					car_no_plate:formPayload.car_no_plate,
					car_model: formPayload.car_model,
					cancellation_fee: formPayload.cancellation_fee,
					ride_information: formPayload.ride_information,
					vehicle_type    : vehicletype,
					car_image        : formPayload.image,
					car_status      :status,
					image_size      :imgSize,
					thumbDefault :  formPayload.thumbDefault,
					priority        : formPayload.priority,
					additional_charges: formPayload.additional_charges,
					extra_time_limit: formPayload.extra_time_limit,
					extra_time_limit_charge: formPayload.extra_time_limit_charge,
					cancellation_hour : formPayload.cancellation_hour,
					uploadfromgallery : galleryimg
					
				};
				let formData = new FormData();
				for(let k in postObject) {
				formData.append(k, postObject[k]);
				}
                
                var filesdata;
                if(galleryimg == 'No'){
                    filesdata = this.state.user_image;	
                }else{
                	 filesdata = this.state.selectedimages;	
                }
				
				
				for (var i in filesdata) {
				 formData.append('car_image[]',filesdata[i])
				}	
				
				
				
				this.props.getAddCar(formData,config);
				//this.props.getAddCar(qs.stringify(postObject));
			}
	}

	validateForm() {
		const {car_name,car_colour,car_no_plate,car_price,car_model,ride_information,selectedVehicle,user_image_preview, additional_charges, extra_time_limit, extra_time_limit_charge, cancellation_fee,cancellation_hour} = this.state;
		let errors = 0;
	
		if (!car_name) {
			errors++;
			$('.errorcar_name').html('<span class="errorspan">Please fill the field</span>');
		}else if(car_name){
			$('.errorcar_name').html('');
		}

	

		if (!car_price) {
			errors++;
			$('.errorcar_price').html('<span class="errorspan">Please fill the field</span>');
		}else if(car_price){
			$('.errorcar_price').html('');
		}
	
		if (!car_model) {
			errors++;
			$('.errorcar_model').html('<span class="errorspan">Please fill the field</span>');
		}else if(car_model){
			$('.errorcar_model').html('');
		}
		if (!ride_information) {
			errors++;
			$('.errorride_information').html('<span class="errorspan">Please fill the field</span>');
		}else if(ride_information){
			$('.errorride_information').html('');
		}

		if (selectedVehicle.value === undefined || selectedVehicle.value === '') {
		$('.errorvehicletype').html('<span class="errorspan">Please fill the field</span>');
		errors++;
		}
		else {
		$('.errorvehicletype').html('');
		}

		if (user_image_preview === undefined || user_image_preview === '') {
		$('.errorcarimage').html('<span class="errorspan">Please fill the field</span>');
		errors++;
		}
		else {
		$('.errorcarimage').html('');
		}

		if(!additional_charges){
			errors++;
			$('.erroradditional_charges').html('<span class="errorspan">Please fill the field</span>');
		}else{
			$('.erroradditional_charges').html('');
		}
		
		if(!extra_time_limit){
			errors++;
			$('.errorextra_time_limit').html('<span class="errorspan">Please fill the field</span>');
		}else{
			$('.errorextra_time_limit').html('');
		}
		
		if(!extra_time_limit_charge){
			errors++;
			$('.errorextra_time_limit_charge').html('<span class="errorspan">Please fill the field</span>');
		}else{
			$('.errorextra_time_limit_charge').html('');
		}		

		if(!cancellation_fee){
			errors++;
			$('.errorcar_cacellation').html('<span class="errorspan">Please fill the field</span>');
		}else{
			$('.errorcar_cacellation').html('');
		}

		if(!cancellation_hour){
			errors++;
			$('.errorcar_cacellationhour').html('<span class="errorspan">Please fill the field</span>');
		}else{
			$('.errorcar_cacellationhour').html('');
		}

		


		if(errors>0) {
		setTimeout(function () {
		scrollToTopValidate();
		}, 100);
		return false;
		}
		else {
		return true;
		}

		/*this.setState({
			errors: errors
		});
		return formIsValid;*/
    }

    componentWillReceiveProps(Props){

    	 	if(Props.caradd !== this.props.caradd){
    		if(Object.keys(Props.caradd).length > 0){
				this.setState({Loading:false});

					if(Props.caradd[0].status === "success"){

			$('.success_message').html('<div class="status_sucess"><h3>'+Props.caradd[0].message+'</h3></div>');
			scrollToTop();
						setTimeout(
						function() {
							$('.success_message').html('');
							this.handleReset();
						}
						.bind(this),
						3000
						);
					}else{
						$('.success_message').html('<div class="status_sucess"><h3>'+Props.caradd[0].message+'</h3></div>');
						scrollToTop();
						setTimeout(
						function() {
							$('.success_message').html('');
						}
						.bind(this),
						3000
						);
					}

    		}
    	}

			if(Props.vehicletype[0].image_size === '350x350'){
				this.setState({selectedsizeOption:{value: '350x350', label: '350x350'}});
			}else if(Props.vehicletype[0].image_size === '250x250'){
				this.setState({selectedsizeOption:{value: '250x250', label: '250x250'}});
			}else{
				this.setState({selectedsizeOption:{value: '520x520', label: '520x520'}});
			}
			
    	 if(Props.vehicletype[0].vehiclelist!== '' && Props.vehicletype[0].vehiclelist!== null){
              this.setState({ vehicletype : Props.vehicletype[0].vehiclelist})
            }   
     }
	  
	removeImagebyindex = (indexvalue) => {
  	 
	  var list = [...this.imageArray];
	  this.imageArray = [];
	  this.fileArray = [];
	  list.splice(indexvalue, 1);
	 
	  for (let i = 0; i < list.length; i++) {
		this.imageArray.push(list[i]);
		this.fileArray.push(URL.createObjectURL(list[i]));
	  }
	
		this.setState({ user_image: this.imageArray })
		
		var thumbDefault = this.state.thumbDefault;
		if(indexvalue <= thumbDefault){
			let thum = thumbDefault-1;
			 if(thum < 0 ){
			   this.setState({thumbDefault : 0})
			 }else{
				this.setState({thumbDefault : thum});
			}    
		}
	}
	
	
	 getImagesFrontUpload(){
		 /*====Thumb image while choosing from front end===*/
		  if(Object.keys(this.fileArray).length > 0) {
			var thumbDefaultArr = this.state.thumbDefault;
		 
			 const imgThumblist = this.fileArray.map((url, index) => {
						var indicheckFlag = false;
												
						//if(Object.keys(thumbDefaultArr).length > 0){
							if(thumbDefaultArr != null && thumbDefaultArr == index){
								indicheckFlag = true;
							}
						//}
						  return (
							<li className="thumb" key={index}  >
								<input type="radio" name="sel_img" id="sel_img"  value={index} onClick={this.onThumbChanged} checked={indicheckFlag} />
								<span><img src={url}  alt="..." /></span>
								<a href="javascript:void(0);" onClick={this.removeImagebyindex.bind(this,index)}>X</a>
							</li>
							 );
			 });	
				return imgThumblist;					
			/*=======*/
		}
	 }

   //list selected image  from gallery
	 getafteruploaded(){
	 	var imgArray = this.state.selectedimages;
	 	if(Object.keys(imgArray).length > 0) {
		 	var thumbDefaultArr = this.state.thumbDefault;
			 const imgThumblist = imgArray.map((images, index) => {
			 	console.log(images,'images')
			 			var indicheckFlag = false;
		 				if(thumbDefaultArr != null && thumbDefaultArr == index){
							indicheckFlag = true;
						} 
						  return (
							<li className="thumb" key={index}  >
								<input type="radio" name="sel_img" id="sel_img"  value={index} onClick={this.onThumbChanged} checked={indicheckFlag} />
								<span><img src={carImageUrl+'/'+images}  alt="..." /></span>
								<a href="javascript:void(0);" onClick={this.removeImagebyindex.bind(this,index)}>X</a>
							</li>
							 );
			 });	
				return imgThumblist;					
			/*=======*/
		}

	 }
  //check gallery images
   Checkgallery = (indexs,value) =>{
      var imageArr = [...this.state.selectedimages];
      const index = imageArr.findIndex(images => images === indexs);
      if(index > -1) {
     	 imageArr = [...imageArr.slice(0, index), ...imageArr.slice(index + 1)]
      } else {
      imageArr.push(value);
      }
      this.setState({selectedimages: imageArr});
  }


 getGalleryimages() {
    var imageArr = this.state.imagelist;
    if(imageArr!== undefined && imageArr!== null){
      if(Object.keys(imageArr).length > 0) {
         const imageDetails = imageArr.map((image, Index) => {
       		var checked = false;
            return (
                        <div className="asp-col" key={Index}>   
                        <input type="checkbox" name="gallery_img" value={Index}  onClick={this.Checkgallery.bind(this,Index,image['name'])} />
                        <label><img src={image['url']} alt="" className="" /></label>
                        </div>
                     );
    
     });
      return imageDetails;
     }
    } else {
      return (<div className="">No images found</div>);
    }
  }

   opengalleryImages = () => {
		axios.get(apiUrl+'car/getimagelist')
		.then(res => {
			if(res.data.status == 'success'){
				this.setState({imagelist: res.data.imagelist})
			}else{
			   
			}
		});
		this.setState(prevState => ({
			enableuploadgalley: !prevState.enableuploadgalley
		}));
   }
   galleryClose = () => {

   		if(Object.keys(this.state.selectedimages).length > 0){
   			 this.setState({galleryimg: 'Yes'});
   		}
   	    this.setState(prevState => ({
			enableuploadgalley: !prevState.enableuploadgalley
		}));
		$('.cargallery-popup').removeClass('active');
   }


  render() {

  	let preview = '';
	let imagesrc = '';
	const vehicleArray = this.state.vehicletype.map ((vehicleType, index) => ({
	id: index,
	name: vehicleType,
	}));
	// Dynamically create select list
	let vehicles = [];
	vehicleArray.map(item =>
	vehicles.push({ label: item.name.vehicle_type, value: item.name.vehicle_id }),
	);

  	const {selectedOption,selectedVehicle, user_image_preview} = this.state;

	if (user_image_preview!== null && user_image_preview!== '') {
	preview = <img className="img_class" src={user_image_preview} alt="" />;
	}

    return (
      <div className="wrapper"> 
 
	  <Header />
    <MenuSidebar currentpage="carlist" />  

	
	<div className="content">	
		<div className="content-wrapper">
			<div className="form-wrapper">

			<div className="success_message"></div>
			<div className="title">
				<h4>Add Vehicle</h4>
			</div>
		<form className="login" id="login" onSubmit={ e => { this.handleFormSubmit(this); e.preventDefault(); }}>
			<div className="form-row">
			<div className="form-left">
		    	<div className="form-group">
					<label>Vehicle Name:</label>
				    <input type="text" name="car_name" onChange={this.handleInputChange} className="form-control" value={this.state.car_name} />
					<div className="errorcar_name"></div>
				</div>
				<div className="form-group vehicles-select">
					<label>Vehicle Type:</label>
				     <Select 
                         options={vehicles}  
                         value={selectedVehicle?selectedVehicle:{ value: '0', label: 'Select Vehicle Type' }}
                         onChange={this.handleChangeVehicle}
                         placeholder="Select Vehicle Type" />
					<div className="errorvehicletype"></div>
				</div>
               <div className="form-group">
					<label>Vehicle Image:</label>

				<div className="choose-car" onClick={this.opengalleryImages.bind(this)}>
						 <span>Upload from gallery</span>
				</div>
				<p className="or"><b>OR</b></p>

				<div className="choose-file">
                {this.state.isHidden && <div className="image_success"></div>}
                    <span className="profile_btn">
				      <input type="file" className="form-control" name="file" onChange={this.uploadMultipleFiles} multiple />
					</span>
                </div>
				<div className="form-group">
				 <ul className="carimagelisting">
						{/*imageslist*/}
						{/*imgThumblist*/}
							{/*this.getImagesFrontUpload()*/}
							{(this.state.galleryimg === 'No')?this.getImagesFrontUpload():this.getafteruploaded()}
						
					</ul>
					
				</div>
				
                
                <div className="errorcarimage"></div> 
                </div>
				
				{this.state.enableuploadgalley ==  true && <div className="cargallery-popup active">
					<span className="close-gallery" onClick={this.galleryClose.bind(this)}>X</span>
					<div className="cargallery-popup-inner">
						<Scrollbars className="custom-scroll-wrap">
						 <div className="car-listing">
								{this.getGalleryimages()}
						</div>
						</Scrollbars>
					</div>						

				<div className="btn-group">	
					  <span className="btn" onClick={this.galleryClose.bind(this)} disabled={(this.state.Loading ===true)?true:false}> 
					  {this.state.Loading ===true &&  <span className="load-data"></span>}OK</span>
				</div>
				
				</div>}



				<div className="form-group">					
					<label>Image Size:</label>
						   <Select 
						   value={this.state.selectedsizeOption?this.state.selectedsizeOption:{ value: '350x350', label: '350x350'}}
                            options={lang.common.image_size_option} 
                            onChange={this.handlesizeChange}
                            />
				</div>

				<div className="form-group">					
					<label>Basic Price:</label>
						<input type="text" name="car_price" onChange={this.handleInputChange} className="form-control" value={this.state.car_price} />
					<div className="errorcar_price"></div>
				</div>
				<div className="form-group">
				  <label>Additional Charges (per miles): </label>
				  <input type="text" name="additional_charges" onChange={this.handleInputChange} className="form-group" value={this.state.additional_charges} autoComplete="off" />
				  <div className="erroradditional_charges"></div>
				</div>
				
				<div className="form-group">
				  <label>Time Limit : </label>
				  <input type="text" name="extra_time_limit" onChange={this.handleInputChange} className="form-group" value={this.state.extra_time_limit} />
				  <div className="errorextra_time_limit"></div>
				</div>
				
				<div className="form-group">
				  <label>Time Limit Charges (per minute): </label>
				  <input type="text" name="extra_time_limit_charge" onChange={this.handleInputChange} className="form-group" value={this.state.extra_time_limit_charge} />
				  <div className="errorextra_time_limit_charge"></div>
				</div>
				
				
				 {this.state.enableweight &&
				<div className="form-group">
				  <label>Weight: </label>
				  <input type="text" name="truck_weight" onChange={this.handleInputChange} className="form-group" value={this.state.truck_weight} autoComplete="off" />
				  <div className="errortruck_weight"></div>
				</div>
			  }
			</div>				
			<div className="form-right">
			  {/*<div className="form-group">
					<label>Car Number:</label>
						<input type="text" name="car_no_plate" onChange={this.handleInputChange} className="form-control" value={this.state.car_no_plate} />
					<div className="errorcar_no_plate"></div>
				</div>*/}
				<div className="form-group">
					<label>Model:</label>
						<input type="text" name="car_model" onChange={this.handleInputChange} className="form-control" value={this.state.car_model} />
					<div className="errorcar_model"></div>
				</div>
				<div className="form-group">
					<label>Ride Information:</label>
						<input type="text" name="ride_information" onChange={this.handleInputChange} className="form-control" value={this.state.ride_information} />
					<div className="errorride_information"></div>
				</div>
				<div className="form-group">
				  <label>Priority: </label>
				  <input type="text" name="priority" onChange={this.handleInputChange} className="form-group" value={this.state.priority} />
				</div>
				{/*<div className="form-group">
					<label>Color:</label>
						<input type="text" name="car_colour" onChange={this.handleInputChange} className="form-control" value={this.state.car_colour} autoComplete="off" />
					<div className="errorcar_colour"></div>
				</div>*/}
				<div className="form-group">
					<label>Cancellation hour:</label>
						<input  id="appt-time" type="time" name="cancellation_hour" onChange={this.handleInputChange} className="form-control" value={this.state.cancellation_hour} autoComplete="off" />
					<div className="errorcar_cacellationhour"></div>
				</div>
				<div className="form-group">
					<label>Cancellation %:</label>
						<input type="text" name="cancellation_fee" onChange={this.handleInputChange} className="form-control" value={this.state.cancellation_fee} autoComplete="off" />
					<div className="errorcar_cacellation"></div>
				</div>
				<div className="form-group">					
					<label>Status:</label>
						   <Select 
						   value={selectedOption?selectedOption:{ value: 'active', label: 'Active'}}
                            options={lang.common.status_option} 
                            onChange={this.handleChange}
                            />
				</div>
			</div>	
			</div>		

			<div className="btn-group export">	
				   <button className="btn btn_orange btn_minwid login_submit animate-btn2" type="submit" disabled={(this.state.Loading ===true)?true:false}> {this.state.Loading ===true &&
                                        <span className="load-data"></span> 
                                        }Submit
                   </button>
			</div>
			</form>
			</div>
		</div>	
	</div>
    </div>
    );
  }
}


const mapStateTopProps = (state) => {
  return {
  	vehicletype: state.vehicletype,
  	caradd     : state.caradd
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
	 getVehicleType: (adminID) =>{
       dispatch({ type: GET_VEHICLETYPE, adminID});
    },
    getAddCar: (formPayload) =>{
       dispatch({ type: GET_ADDCAR, formPayload});
    }
  }
}
export default connect(mapStateTopProps, mapDispatchToProps)(withRouter(Add));