import { SET_PAYMENTLIST } from '../actions';

const paymentlist = (state = [], action) => {
  switch (action.type) {
	case SET_PAYMENTLIST:
      return [...action.value];  
    default: return state;
  }
}

export default paymentlist;
