import React, { Component } from 'react';
import { Link, withRouter } from "react-router-dom";
import { connect } from 'react-redux';

import $ from 'jquery';
import {
  Row,
  Col,
  Modal,
  Table,
  OverlayTrigger,
  ButtonToolbar,
  Tooltip,
  Button
} from "react-bootstrap";
import Header from '../Layouts/Header';
import MenuSidebar from '../Layouts/MenuSidebar';
import Pagination from "react-js-pagination";
import { GET_VEHICLELIST, GET_DELETEVEHICLE } from '../../actions';

class List extends Component {
  
  constructor(props)
  {
    super(props); 
    this.state = {
      vehiclelist:'',
      editable: false,
      priorityid:'',
      priorityvalue:'',
      activePage: 1,
      totalRecord: '',
      search_all:''
    };

      var admin_id = localStorage.getItem("admin_id");
      var qs = require('qs');
      var postobject = {
        activePage: 1,
        search_all: '',
        admin_id: admin_id
      };

     this.props.getVehicleList(qs.stringify(postobject));

     this.handleInputChange = this.handleInputChange.bind(this);
      this.handleFormSubmit = this.handleFormSubmit.bind(this);

    }

   
   componentDidMount() {
   }

    handlePageChange(pageNumber) {
     // console.log(`active page is ${pageNumber}`);
      this.setState({activePage: pageNumber});
      var qs = require('qs');
      var admin_id = localStorage.getItem("admin_id");
      var postobject = {
        activePage: pageNumber,
        search_all: this.state.search_all,
        admin_id: admin_id
      };
    this.props.getVehicleList(qs.stringify(postobject))
    }

    handleInputChange(event) {
    const {name, value} = event.target;      
    this.setState({
      [name]: value
    });
    
  }
   handleFormSubmit = () => {
        
          this.setState({Loading:true});
          var qs = require('qs');
          const formPayload = this.state;
          var admin_id = localStorage.getItem("admin_id");
          var postObject = {             
              activePage : this.state.activePage,
              search_all : formPayload.search_all,
              admin_id  : admin_id         
          };
          //console.log(postObject);
          this.props.getVehicleList(qs.stringify(postObject));
        
    } 

   componentWillReceiveProps(Props){

    if (Object.keys(Props.vehiclelist).length > 0) {
      if (Props.vehiclelist[0]["status"] === "success") {
       /* this.setState({
          vehiclelist: Props.vehiclelist[0]["vehiclelist"],
        });*/

        this.setState({ totalRecord : Props.vehiclelist[0].records_count, vehiclelist: Props.vehiclelist[0]["vehiclelist"] }, function() {
            this.vehiclelist();
          });


      }
    }
     if (Object.keys(Props.deletevehicle).length > 0) {
      if (Props.deletevehicle[0]["status"] === "success") {
        window.location.reload(); 
        this.setState({
          vehiclelist: Props.deletevehicle[0]["vehiclelist"],
        });
      }
    }
   }

deletevehicle(id){
        var qs = require('qs');
        var postObject = {
          admin_id : localStorage.getItem("admin_id"),
          vehicle_id  :id
        };
      this.props.getDeleteVehicle(qs.stringify(postObject));
}

  vehiclelist() {
    var vehiclelist = this.props.vehiclelist[0];
    if (vehiclelist != "undefined" && vehiclelist != null) {
      if (vehiclelist.status == "success") {
        if (Object.keys(vehiclelist).length > 0) {
          const vehiclelistDetails = vehiclelist.vehiclelist.map(
            (vehiclelist, Index) => {
              let sno = Index+1;
              if(vehiclelist.vehicle_status == 'A'){
                var status = 'Active';
              }else{
                  status = 'Inactive';
              }
              return (
                <tr key={vehiclelist.vehicle_id}>
                <td>{sno}</td>
                  <td>
                  {vehiclelist.vehicle_type}
                  </td>
                  <td>{vehiclelist.vehicle_passenger}</td>
                  <td>{vehiclelist.vehicle_lagguage}</td>
                   <td>{status}</td>
                  <td className="actiongroup">

                    <Link to={{ pathname: '/vehicle/edit/'+vehiclelist.vehicle_id}} className="" title="Duplicate">  <i className="fa fa-edit" aria-hidden="true"></i></Link>
                    <Link onClick={this.deletevehicle.bind(this, vehiclelist.vehicle_id)} className="" title="Duplicate">  <i className="fa fa-trash" aria-hidden="true"></i></Link>
                  </td>
                </tr>
              );
            }
          );
          return vehiclelistDetails;
        }
      } else {
        return (
          <tr>
            <td colspan="6" className="v-align-nr">
              No Result
            </td>
          </tr>
        );
      }
    }
  }
  
  render() {
    return (
      <div className="wrapper"> 
 
    <Header />
    <MenuSidebar currentpage="vehiclelist" />  

  
  <div className="content"> 
    <div className="content-wrapper">
    <div className="car-listing-wrap">

        <div className="listing-header"> 
          <div className="title">
            <h3>Vehicle Types</h3>
          </div> 

           <div className="search-list">
                   <form className="edit" id="edit" onSubmit={ e => { this.handleFormSubmit(this); e.preventDefault(); }} >     
                    <div className="search-inner">
                      <input type="text" name="search_all" onChange={this.handleInputChange} className="form-control" value={this.state.search_all} placeholder="Search" /> 
                     <button className="" type="submit">Submit</button>
                    <div className="erroremail"></div>
                  </div>
                 </form>
                </div>
          <div className="add_button">  
              <a className="add" href='/vehicle-add'>Add</a> 
          </div>
        </div>

        <div className="car-listing-row">
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>S.No</th>
                <th>Vehicle Type</th>
                <th>No of Passengers</th>
                <th>No of Luggages</th>
                <th>Status</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>{this.vehiclelist()}</tbody>
          </Table>
          <div className="text-center">
            <Pagination
            activePage={this.state.activePage}
            itemsCountPerPage={10}
            totalItemsCount={this.state.totalRecord}
            pageRangeDisplayed={5}
            onChange={this.handlePageChange.bind(this)}
            />
          </div>
        </div>
                      
      </div>  
    </div>  
  </div>
      
    </div>
    );
  }
}

const mapStateTopProps = (state) => {
  return {
    vehiclelist: state.vehiclelist,
    deletevehicle: state.deletevehicle

  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getVehicleList: (formVal) =>{
       dispatch({ type: GET_VEHICLELIST, formVal });
    },
    getDeleteVehicle: (formPayload) =>{
      dispatch({ type: GET_DELETEVEHICLE, formPayload });
    }
  }
}
export default connect(mapStateTopProps, mapDispatchToProps)(withRouter(List));