export const GET_LOGINDATA         = "getLoginData";
export const SET_LOGINDATA 		   = "setLoginData";
export const GET_SETTINGDATA 	   = "getSettingData";
export const SET_SETTINGDATA 	   = "setSettingData";
export const GET_UPDATESETTING     = "getUpdateSetting";
export const SET_UPDATESETTING     = "setUpdateSetting";
export const GET_CHANGEPASSWORD     = "getChangePassword";
export const SET_CHANGEPASSWORD     = "setChangePassword";
export const GET_FORGETPASSWORD     = "getForgetPassword";
export const SET_FORGETPASSWORD     = "setForgetPassword";
export const GET_PROFILEDETAILS     = "getProfileDetails";
export const SET_PROFILEDETAILS     = "setProfileDetails";
export const GET_UPDATEPROFILE     = "getUpdateProfile";
export const SET_UPDATEPROFILE     = "setUpdateProfile";
/*vehicle module*/
export const GET_ADDVEHICLE         = "getAddVehicle";
export const SET_ADDVEHICLE         = "setAddVehicle";
export const GET_EDITVEHICLE        = "getEditVehicle";
export const SET_EDITVEHICLE        = "setEditVehicle";

export const GET_VEHICLELIST        = "getVehicleList";
export const SET_VEHICLELIST        = "setVehicleList";

export const GET_VEHICLEDETAIL        = "getVehicleDetail";
export const SET_VEHICLEDETAIL        = "setVehicleDetail";


export const GET_DELETEVEHICLE      = "getDeleteVehicle";
export const SET_DELETEVEHICLE      = "setDeleteVehicle";
/*end*/

/*Company module*/

export const GET_COMPANYDETAIL        = "getCompanyDetail";
export const SET_COMPANYDETAIL        = "setCompanyDetail";

export const GET_ADDCOMPANY         = "getAddCompany";
export const SET_ADDCOMPANY         = "setAddCompany";
export const GET_EDITCOMPANY        = "getEditCompany";
export const SET_EDITCOMPANY        = "setEditCompany";
export const GET_COMPANYLIST        = "getCompanyList";
export const SET_COMPANYLIST        = "setCompanyList";
export const GET_DELETECOMPANY      = "getDeleteCompany";
export const SET_DELETECOMPANY      = "setDeleteCompany";
/*end*/

export const GET_EXPORTUSER         = "getExportuser";
export const SET_EXPORTUSER         = "setExportuser";

export const GET_EXPORTPAYMENT         = "getExportpayment";
export const SET_EXPORTPAYMENT        = "setExportpayment";

/*user module*/
export const GET_ADDUSER         = "getAddUser";
export const SET_ADDUSER         = "setAddUser";
export const GET_EDITUSER        = "getEditUser";
export const SET_EDITUSER        = "setEditUser";
export const GET_USERLIST        = "getUserList";
export const SET_USERLIST        = "setUserList";
export const GET_DELETEUSER      = "getDeleteUser";
export const SET_DELETEUSER      = "setDeleteUser";

export const GET_IMPORTUSER       = "getImportUser";
export const SET_IMPORTUSER       = "setImportUser";

export const GET_IMPORTDRIVER       = "getImportDriver";
export const SET_IMPORTDRIVER       = "setImportDriver";

export const GET_ACTIVATEUSER        = "getActivateUser";
export const SET_ACTIVATEUSER        = "setActivateUser";

export const GET_USERDETAILLIST      = "getUserDetailList";
export const SET_USERDETAILLIST      = "setUserDetailList";

/*end*/

/*car*/
export const GET_VEHICLETYPE         = "getVehicleType";
export const SET_VEHICLETYPE         = "setVehicleType"; 
export const GET_ADDCAR              = "getAddCar";
export const SET_ADDCAR              = "setAddCar"; 
export const GET_EDITCAR             = "getEditCar";
export const SET_EDITCAR             = "setEditCar";
export const GET_CARLIST             = "getCarList";
export const SET_CARLIST             = "setCarList";

export const GET_CARDETAIL           = "getCarDetail";
export const SET_CARDETAIL           = "setCarDetail";

export const GET_DELETECAR           = "getDeleteCar";
export const SET_DELETECAR           = "setDeleteCar";
/**/

export const GET_PRIORITYUPDATE      = "getPriorityUpdate";
export const SET_PRIORITYUPDATE      = "setPriorityUpdate";
/**/

/*get booking history*/
export const GET_BOOKINGHISTORY      = "getBookingHistory";
export const SET_BOOKINGHISTORY      = "setBookingHistory";


/*get fonts*/

export const GET_FONTLIST            = "getFontList";
export const SET_FONTLIST            = "setFontList";

/*get payment*/

export const GET_PAYMENTLIST            = "getPaymentList";
export const SET_PAYMENTLIST            = "setPaymentList";

/*get font name for settings*/
export const GET_FONTNAME            = "getFontName";
export const SET_FONTNAME            = "setFontName";

export const GET_ADDFONT             = "getAddFont";
export const SET_ADDFONT             = "seAddFont";

export const GET_DELETEFONT           = "getDeleteFont";
export const SET_DELETEFONT           = "setDeleteFont";

/*get coupon name*/


export const GET_COUPONLIST            = "getCouponList";
export const SET_COUPONLIST            = "setCouponList";

export const GET_COUPONDETAILLIST      = "getCouponDetailList";
export const SET_COUPONDETAILLIST      = "setCouponDetailList";

export const GET_ADDCOUPON             = "getAddCoupon";
export const SET_ADDCOUPON             = "setAddCoupon";

export const GET_EDITCOUPON        = "getEditCoupon";
export const SET_EDITCOUPON        = "setEditCoupon";




/*driver module*/
export const GET_ADDDRIVER         = "getAddDriver";
export const SET_ADDDRIVER         = "setAddDriver";
export const GET_EDITDRIVER        = "getEditDriver";
export const SET_EDITDRIVER        = "setEditDriver";
export const GET_DRIVERLIST        = "getDriverList";
export const SET_DRIVERLIST        = "setDriverList";

export const GET_DRIVERDROPDOWNLIST        = "getDriverDropDownList";
export const SET_DRIVERDROPDOWNLIST        = "setDriverDropDownList";
export const GET_ASSIGNDRIVER        = "getAssignDriver";
export const SET_ASSIGNDRIVER        = "setAssignDriver";

export const GET_DRIVERDETAIL      = "getDriverDetail";
export const SET_DRIVERDETAIL      = "setDriverDetail";

export const GET_DELETEDRIVER      = "getDeleteDriver";
export const SET_DELETEDRIVER      = "setDeleteDriver";

export const GET_ACTIVATEDRIVER      = "getActivateDriver";
export const SET_ACTIVATEDRIVER      = "setActivateDriver";

export const GET_STRIPELINKTODRIVER      = "getstripelinkToDriver";
export const SET_STRIPELINKTODRIVER      = "setstripelinkToDriver";

export const GET_EXPORTDRIVER        = "getExportdriver";
export const SET_EXPORTDRIVER        = "setExportdriver";

export const GET_LOCATEDDRIVERS        = "getLocatedDrivers";
export const SET_LOCATEDDRIVERS        = "setLocatedDrivers";
/*end*/

/*get ongoing ride*/
export const GET_ONGOINGRIDE      = "getOngoingRide";
export const SET_ONGOINGRIDE      = "setOngoingRide";
export const GET_VIEWONGOINGRIDE  = "getViewOngoingride";
export const SET_VIEWONGOINGRIDE  = "setViewOngoingride";


/*get completed ride*/
export const GET_COMPLETEDRIDE      = "getCompletedRide";
export const SET_COMPLETEDRIDE      = "setCompletedRide";


/*get incompleted ride*/
export const GET_INCOMPLETERIDE      = "getIncompleteRide";
export const SET_INCOMPLETERIDE      = "setIncompleteRide";


/*Template Module*/

export const GET_TEMPLATELIST        = "getTemplateList";
export const SET_TEMPLATELIST        = "setTemplateList";
export const GET_TEMPLATEDETAIL        = "getTemplateDetail";
export const SET_TEMPLATEDETAIL        = "setTemplateDetail";
export const GET_TEMPLATEADD           = "getTemplateAdd";
export const SET_TEMPLATEADD           = "setTemplateAdd";
export const GET_TEMPLATEEDIT           = "getTemplateEdit";
export const SET_TEMPLATEEDIT           = "setTemplateEdit";
export const GET_TEMPLATEDELETE        = "getTemplateDelete";
export const SET_TEMPLATEDELETE        = "setTemplateDelete";

/*get domain list for user and driver add*/
export const GET_DOMAINLIST =  "getDomainList";
export const SET_DOMAINLIST =  "setDomainList";

export const GET_USERNOTIFICATION = "getUsernotification";
export const SET_USERNOTIFICATION = "setUsernotification";
export const GET_UPDATENOTIFICATION = "getUpdateUsernotification";
export const SET_UPDATENOTIFICATION = "setUpdateUsernotification";


export const GET_FAVOURITE = "getFavourite";
export const SET_FAVOURITE = "setFavourite";
export const GET_UPDATEFAVOURITE = "getUpdateFavourite";
export const SET_UPDATEFAVOURITE = "setUpdateFavourite";



export const GET_USERINVITEFRIEND = "getUserinvitefriend";
export const SET_USERINVITEFRIEND = "setUserinvitefriend";
export const GET_UPDATEUSERINVITEFRIEND = "getUpdateUserinvitefriend";
export const SET_UPDATEUSERINVITEFRIEND = "setUpdateUserinvitefriend";

export const GET_VEHICLEBYTYPE = "getVehicleByType";
export const SET_VEHICLEBYTYPE = "setVehicleByType";

export const GET_GALLERY = "getGallery";
export const SET_GALLERY = "setGallery";