import React, { Component } from 'react';
import { Link, withRouter } from "react-router-dom";
import { connect } from 'react-redux';
import Pagination from "react-js-pagination";
import $ from 'jquery';
import {
  Row,
  Col,
  Modal,
  Table,
  OverlayTrigger,
  ButtonToolbar,
  Tooltip,
  Button
} from "react-bootstrap";
import Header from '../Layouts/Header';
import MenuSidebar from '../Layouts/MenuSidebar';
import { scrollToTopValidate,PageTitle } from "../Helpers/SettingHelper";

import { GET_CARLIST,GET_DELETECAR,GET_PRIORITYUPDATE } from '../../actions';

class List extends Component {
  
  constructor(props)
  {
    super(props); 
    this.state = {
      carlist:''
    };

    var qs = require('qs');
    var admin_id = localStorage.getItem("admin_id");
    var postobject = {
    activePage: 1,
    search_all: '',
    admin_id: admin_id
    };

    this.props.getCarList(qs.stringify(postobject));

    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleFormSubmit = this.handleFormSubmit.bind(this);

    }

   
   componentDidMount() {
      document.title = PageTitle('Car List');
      if(localStorage.getItem('admin_id') === null){
      this.props.history.push('/');
      }
   }

    handlePageChange(pageNumber) {
     // console.log(`active page is ${pageNumber}`);
      this.setState({activePage: pageNumber});

      var qs = require('qs');

      var admin_id = localStorage.getItem("admin_id");

      var postobject = {
        activePage: pageNumber,
        search_all: this.state.search_all,
        admin_id: admin_id
      };
      this.props.getCarList(qs.stringify(postobject))
    }

    handleInputChange(event) {
    const {name, value} = event.target;      
    this.setState({
      [name]: value
    });
    
  }
   handleFormSubmit = () => {
        
          this.setState({Loading:true});
          var qs = require('qs');
         
           var admin_id = localStorage.getItem("admin_id");
          var postObject = {             
            activePage   : 1,
            search_all : this.state.search_all,
            admin_id: admin_id         
          };
          //console.log(postObject);
          this.props.getCarList(qs.stringify(postObject));
        
    } 


   componentWillReceiveProps(Props){

    if (Object.keys(Props.carlist).length > 0) {
      if (Props.carlist[0]["status"] === "success") {
        /*this.setState({
          carlist: Props.carlist[0]["carlist"],
        });*/
         this.setState({ totalRecord : Props.carlist[0].records_count, carlist: Props.carlist[0]["carlist"] }, function() {
            this.vehiclelist();
         });
      }
    }

  if (Object.keys(Props.deletecar).length > 0) {
      if (Props.deletecar[0]["status"] === "success") {
        this.setState({
          carlist: Props.deletecar[0]["carlist"],
        });
      }

      this.setState({statusmessage: Props.deletecar[0].message});

            setTimeout(
            function() {
              this.setState({statusmessage:''})
            }
            .bind(this),
            3000
          );
      window.location.reload(); 
    }
	
	if(Props.priorityupdate !==  this.props.priorityupdate){
      if (Props.priorityupdate[0]["status"] === "success") {        
		 
		  var qs = require('qs');        
           const formPayload = this.state;
          var postObject = {             
            activePage   : 1,
            search_all : '',
            admin_id: localStorage.getItem("admin_id")         
          };
		  this.props.getCarList(qs.stringify(postObject));
			
      }
    }
	
   }
   
   handleChange = (name,carid,event) => {
	  this.setState({priorityid: name,priorityvalue: event.target.value})
	   var qs = require('qs');
			var postObject = {
			  value :event.target.value,
			  car_id  :carid
		};
	  this.props.getPriorityUpdate(qs.stringify(postObject));

	}

deletevehicle(id){
        var qs = require('qs');
        var postObject = {
          admin_id : localStorage.getItem("admin_id"),
          car_id  :id
        };
      this.props.getDeleteCar(qs.stringify(postObject));
}

  vehiclelist() {
    var carlist = this.props.carlist[0];
    if (carlist != "undefined" && carlist != null) {
      if (carlist.status == "success") {
        if (Object.keys(carlist).length > 0) {
          const vehiclelistDetails = carlist.carlist.map(
            (carlist, Index) => {
              let sno = Index+1;
              if(carlist.car_status == 'A'){
                var status = 'Active';
              }else{
                  status = 'Inactive';
              }
			  
			  var inputpriname  = "priority_"+carlist.car_id;
              var privalue = '';
              if(this.state.priorityid !== inputpriname){
                 privalue = carlist.priority;
              }else{
                 privalue = this.state.priorityvalue;
              }
			  var istyle = { width: 100};
              return (
                <tr key={carlist.car_id}>
                <td>{sno}</td>
                  <td>
                  {carlist.car_name}
                  </td>
                  <td>{carlist.car_model}</td>
				  <td style={istyle}><input type="text" name={inputpriname} value={privalue} onChange={this.handleChange.bind(this,inputpriname,carlist.car_id)}/></td>
                  <td>{carlist.car_price}</td>
                   <td>{status}</td>
                  <td className="actiongroup">

                    <Link to={{ pathname: '/car/edit/'+carlist.car_id}} className="" title="Duplicate">  <i className="fa fa-edit" aria-hidden="true"></i></Link>
                     <Link onClick={this.deletevehicle.bind(this,carlist.car_id)} className="" title="Duplicate">  <i className="fa fa-trash" aria-hidden="true"></i></Link>
                  </td>
                </tr>
              );
            }
          );
          return vehiclelistDetails;
        }
      } else {
        return (
          <tr>
            <td colspan="7" className="v-align-nr">
              No Result
            </td>
          </tr>
        );
      }
    }
  }
  
  render() {
    return (
      <div className="wrapper"> 
 
    <Header />
    <MenuSidebar currentpage="carlist" />  
      
      <div className="content"> 
        <div className="content-wrapper">
            <div className="car-listing-wrap">

              <div className="listing-header">
                <div className="title">
                  <h3>Vehicle</h3>
                </div>

                <div className="search-list">
                   <form className="edit" id="edit" onSubmit={ e => { this.handleFormSubmit(this); e.preventDefault(); }} >     
                    <div className="search-inner">
                      <input type="text" name="search_all" onChange={this.handleInputChange} className="form-control" value={this.state.search_all} placeholder="Search" /> 
                     <button className="" type="submit">Submit</button>
                    <div className="erroremail"></div>
                  </div>
                 </form>
                </div>
                <div className="add_button">  
                    <a className="add" href='/car-add'>Add</a> 
                </div>  
              </div>            
              {/*<div className="statusmessage"></div>*/}

              <div className="car-listing-row">
                <Table striped bordered hover>
                  <thead>
                    <tr>
                      <th>S.NO</th>
                      <th>Car Name</th>
                      <th>Model</th>
					            <th>Priority</th>
                      <th>Price</th>
                      <th>Status</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>{this.vehiclelist()}</tbody>
                </Table>
                <div className="text-center">
                  <Pagination
                  activePage={this.state.activePage}
                  itemsCountPerPage={10}
                  totalItemsCount={this.state.totalRecord}
                  pageRangeDisplayed={5}
                  onChange={this.handlePageChange.bind(this)}
                  />
                </div>
              </div>

            </div> 
        </div>  
      </div>
      
    </div>
    );
  }
}

const mapStateTopProps = (state) => {
  return {
    carlist: state.carlist,
    deletecar: state.deletecar,
	  priorityupdate: state.priorityupdate
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getCarList: (formVal) =>{
       dispatch({ type: GET_CARLIST, formVal});
    },
    getDeleteCar: (formPayload) =>{
      dispatch({ type: GET_DELETECAR, formPayload });
    },	
	getPriorityUpdate: (formPayload) =>{
      dispatch({ type: GET_PRIORITYUPDATE, formPayload });
    }
  }
}
export default connect(mapStateTopProps, mapDispatchToProps)(withRouter(List));