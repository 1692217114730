/* eslint-disable */
import { takeEvery, call, put } from 'redux-saga/effects';
import { GET_DOMAINLIST, SET_DOMAINLIST } from '../actions';
import {apiUrl } from "../components/Config/Config";
import Axios from 'axios';
//import cookie from 'react-cookies';

/*get domain list for dropdown*/ 

export const watchGetDomainList = function* () {
  yield takeEvery(GET_DOMAINLIST, workerGetDomainList);
}

function* workerGetDomainList({ adminid }) {

    var url = apiUrl+'adminpanel/domainlist?admin_id='+adminid;
    try {
    const uri = url;
    const result = yield call(Axios.get, uri);
    var resultArr = [];
    resultArr.push(result.data)
    yield put({ type: SET_DOMAINLIST, value: resultArr });
  } 
  catch {
    console.log('Get Page Failed');
  }
}