/* eslint-disable */
import { takeEvery, call, put } from 'redux-saga/effects';
import { GET_FAVOURITE, SET_FAVOURITE, GET_UPDATEFAVOURITE, SET_UPDATEFAVOURITE } from '../actions';
import {apiUrl } from "../components/Config/Config";
import Axios from 'axios';


export const watchGetFavourite = function* () {
  yield takeEvery(GET_FAVOURITE, workerGetFavourite);
}

function* workerGetFavourite({ formPayload }) {
  try {
      
 const result = yield call(getFavouriteData, formPayload);
  var resultArr = [];
  resultArr.push(result.data);
    yield put({ type: SET_FAVOURITE, value: resultArr });
  } 
  catch {
    console.log('post data failed');
  }
} 


function getFavouriteData(formPayload) {
   return Axios.post(apiUrl+'favourite/userfavourite', formPayload);
}  



/*========Update Favourite==============*/

export const watchGetUpdateFavourite = function* () {
  yield takeEvery(GET_UPDATEFAVOURITE, workerGetUpdateFavourite);
}

function* workerGetUpdateFavourite({ formPayload }) {
  try {
      
 const result = yield call(getUpdateFavourite, formPayload);
  var resultArr = [];
  resultArr.push(result.data);
    yield put({ type: SET_UPDATEFAVOURITE, value: resultArr });
  } 
  catch {
    console.log('post data failed');
  }
} 


function getUpdateFavourite(formPayload) {
   return Axios.post(apiUrl+'favourite/userfavouritesave', formPayload);
} 