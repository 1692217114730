/* eslint-disable */
import { takeEvery, call, put } from 'redux-saga/effects';
import { GET_PAYMENTLIST, SET_PAYMENTLIST,GET_EXPORTPAYMENT, SET_EXPORTPAYMENT} from '../actions';
import {apiUrl } from "../components/Config/Config";
import Axios from 'axios';
//import cookie from 'react-cookies';


/*list*/


export const watchGetPaymentList = function* () {
  yield takeEvery(GET_PAYMENTLIST, workerGetPaymentList);
}

function* workerGetPaymentList({ formVal }) {
  try {
      
 const result = yield call(getPaymentList, formVal);
  var resultArr = [];
  resultArr.push(result.data);
    yield put({ type: SET_PAYMENTLIST, value: resultArr });
  } 
  catch {
    console.log('post data failed');
  }
} 


function getPaymentList(formVal) {
   return Axios.post(apiUrl+'payments/list', formVal);
} 

/*Export the Payment*/

export const watchExportPayment = function* () {
  yield takeEvery(GET_EXPORTPAYMENT, workerExportPayment);
}

function* workerExportPayment({ formPayload }) {
	console.log('tester')
  try {
      
 const result = yield call(getExportPayment, formPayload);
  var resultArr = [];
  resultArr.push(result.data);
    yield put({ type: SET_EXPORTPAYMENT, value: resultArr });
  } 
  catch {
    console.log('post data failed');
  }
} 

function getExportPayment(formPayload) {
   return Axios.post(apiUrl+'payments/export', formPayload);
}

