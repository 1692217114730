import React, { Component } from 'react';
import { Link, withRouter } from "react-router-dom";
import { connect } from 'react-redux';
import Pagination from "react-js-pagination";
import $ from 'jquery';
import axios from 'axios';
import Header from '../Layouts/Header';
import MenuSidebar from '../Layouts/MenuSidebar';
import { scrollToTopValidate,PageTitle } from "../Helpers/SettingHelper";
import mapboxgl from "mapbox-gl";
import { GET_VIEWONGOINGRIDE } from '../../actions';
import { apiUrl, dummycarUrl} from'../Config/Config';


class View extends Component {
  
  constructor(props)
  {
    super(props); 
    const rideId = (typeof this.props.match.params.ID === 'undefined') ? '' : this.props.match.params.ID;
console.log(this.props.match.params)
    this.state = {      
      rideId: rideId,
      ongoingrideview:'',
      ongoingrideview_fromLat:'',
      centerlat:40.730610,
      centerlong:-73.935242,
      from_long:'',
      from_lat:'',
	  ongoingridepath:''
    };

    mapboxgl.accessToken = 'pk.eyJ1Ijoib2ZlbCIsImEiOiJjazI4Y3FvdTgwejgzM25xaGMxNHkyM3lyIn0.iPMeYOlpDFigB_K-EigwrQ';

    var qs = require('qs');
    var postObject = {             
    id   :rideId
    };
    this.props.getViewOngoingride(qs.stringify(postObject));  
	this.withInterval = this.withInterval.bind(this);
  }

   
   componentDidMount() {
     document.title = PageTitle('Ongoing View');
	 
	 /*===  Get axios data from db ===*/
	 //var driverId = 9;
	// var ride_Id = this.state.rideId;
	 
	 /*================To run immediataly and then set inteval for the same ============*/
	 
		 /* axios.get(apiUrl+'driver/fetchTestlog?ride_Id='+ride_Id)
				.then(res => {
					//console.log(res+",testresponse");
					if(res.data.status == 'success'){
						 this.setState({ongoingridepath: res.data.records}) 
						// console.log( res.data.records,"my test")
					}else{
						console.log("error")
					}
				});*/
		/*setTimeout(function(){
			//axios.get(apiUrl+'driver/fetchTestlog?driver_id='+driverId+'&booking_id='+bookingId)
			axios.get(apiUrl+'driver/fetchTestlog?ride_Id='+ride_Id)
			.then(res => {
				//console.log(res+",testresponse");
				if(res.data.status == 'success'){
					 this.setState({ongoingridepath: res.data.records}) 
					// console.log( res.data.records,"my test")
				}else{
					console.log("error")
				}
			});//console.log(this.state.ongoingrideview)
		}.bind(this),10000);
		*/
		
		
	
		
   }

   componentDidUpdate(prevProps,prevState){
    if(this.state.ongoingrideview.from_long!=='' && this.state.ongoingrideview.from_lat!==''){
 var ride_Id = this.state.rideId;
      var from_long = this.state.ongoingrideview.from_long;
      var from_lat = this.state.ongoingrideview.from_lat;
	  
	  var to_long = this.state.ongoingrideview.to_long;
      var to_lat = this.state.ongoingrideview.to_lat;
	  
	  var origin      = [from_long,from_lat];
	  var destination = [to_long,to_lat];
	  
	  var from_location = this.state.ongoingrideview.from_location;
      var to_location = this.state.ongoingrideview.to_location;
			
      var ongoingridepath = this.state.ongoingridepath;

      var map = new mapboxgl.Map({
      container: 'map',
      style: 'mapbox://styles/ofel/ck28cs1ua2wbe1ckvmyycnow3',
      center: origin,//[-122.48348236083984, 37.83317489144141],
      zoom: 8
      });
map.addControl(new mapboxgl.NavigationControl());

      map.on('load', () => {
		 
		 if(this.state.ongoingrideview.ride_status == 'running'){
			  setInterval(function(){
				  var ongoingridepath1_return = this.withInterval(map,ride_Id);
			  }.bind(this),10000);
			
		 }else{
			 var ongoingridepath1_return = this.withInterval(map,ride_Id);
		 }
		
if(ongoingridepath1_return != ''){	
		var geojson = {
				'type': 'FeatureCollection',
				'features': [
				{
				'type': 'Feature',
				'properties': {
				'message': 'Orgin',
				'iconSize': [25, 25]
				},
				'geometry': {
				'type': 'Point',
				'coordinates':origin,//[-122.48348236083984, 37.83317489144141]
				}
				},
				]
		    };

				 				
				geojson.features.forEach(function (marker) {					
					// create a DOM element for the marker
					var el = document.createElement('div');
					el.className = 'marker';
					let sImg = dummycarUrl+'/sq.png';
					el.style.backgroundImage =
					'url('+sImg+')';
					el.style.width = marker.properties.iconSize[0] + 'px';
					el.style.height = marker.properties.iconSize[1] + 'px';
					 
					// add marker to map
					new mapboxgl.Marker(el)
					.setLngLat(marker.geometry.coordinates)
					.addTo(map);
					
					/*worked code*/
					 /*new mapboxgl.Marker(el)
					.setLngLat(marker.geometry.coordinates)
					.setPopup(new mapboxgl.Popup({ offset: 25 })
					.setHTML('<p><strong>'+from_location+'</p>'))
					.addTo(map);*/
				});
				
				
				var geojson1 = {
					'type': 'FeatureCollection',
					'features': [
					{
					'type': 'Feature',
					'properties': {
					'message': 'Destination',
					'iconSize': [25, 25]
					},
					'geometry': {
					'type': 'Point',
					'coordinates':destination,//[-122.49378204345702, 37.83368330777276]
					}
					},
					

					]
				};
				
				geojson1.features.forEach(function (marker1) {
					
					// create a DOM element for the marker
					var el = document.createElement('div');
					el.className = 'marker';
					let sImg = dummycarUrl+'/Ovel.png';
					el.style.backgroundImage =
					'url('+sImg+')';
					el.style.width = marker1.properties.iconSize[0] + 'px';
					el.style.height = marker1.properties.iconSize[1] + 'px';
					 
					// add marker to map
					new mapboxgl.Marker(el)
					.setLngLat(marker1.geometry.coordinates)
					.addTo(map);
					
					/*worked code*/
					/* new mapboxgl.Marker(el)
					.setLngLat(marker1.geometry.coordinates)
					.setPopup(new mapboxgl.Popup({ offset: 25 })
					.setHTML('<p><strong>'+to_location+'</p>'))
					.addTo(map);*/
				 });
}


      });
	  

    }
   

   }
   
   
   withInterval=(map,ride_Id)=>{
		var ongoingridepath1;
		  
				//axios.get(apiUrl+'driver/fetchTestlog?driver_id='+driverId+'&booking_id='+bookingId)
				axios.get(apiUrl+'driver/fetchTestlog?ride_Id='+ride_Id)
				.then(res => {
					//console.log(res+",testresponse");
					if(res.data.status == 'success'){
						 //this.setState({ongoingridepath: res.data.records})
						ongoingridepath1 = res.data.records;					 
						// console.log( res.data.records,"my test")
		
					/*===Reset layer and source to set value each time===*/
					if (map.getLayer('route')){
						map.removeLayer('route');
					}

					if (map.getSource('route')){
						map.removeSource('route');
					}	
					/*==================================================*/
			
					map.addSource('route', {
						'type': 'geojson',
						'data': {
							'type': 'Feature',
							'properties': {},
							'geometry': {
							'type': 'LineString',
							'coordinates': ongoingridepath1
							}
						}
					});

					map.addLayer({
						'id': 'route',
						'type': 'line',
						'source': 'route',
						'layout': {
						'line-join': 'round',
						'line-cap': 'round'
						},
						'paint': {
						'line-color': '#000000',
						'line-width': 4
						}
					});
				}else{
						console.log("error")
					}
				});//console.log(this.state.ongoingrideview)
			
			return ongoingridepath1;
   }
   
   
   componentWillReceiveProps(Props){
      if(Props.ongoingrideview!==this.state.ongoingrideview){
        if(Props.ongoingrideview[0].status == "success"){
          this.setState({ongoingrideview: Props.ongoingrideview[0].driver_data[0]}) 
        }
		
		
      }
   }


  
  render() {
    return (
      <div className="wrapper"> 
 
    <Header />
    <MenuSidebar currentpage="ongoingride" />  
      
      <div className="content"> 
	  
        <div className="content-wrapper">
            <div className="content-body">
               <div className="form-wrapper">
                <div className="title">
                  <h3>Ongoing View</h3>
                </div>
                <div className="form-row ongoing-wrapper">
                    <div className="form-left">
                      <p><b>Driver name: </b>{this.state.ongoingrideview.driver_fname}</p>
                      <p><b>Driver number: </b>{this.state.ongoingrideview.driver_mobile}</p>          
                      <p><b>Driver Email: </b>{this.state.ongoingrideview.driver_email}</p>
                    </div>      
                    <div className="form-right">
                      <p><b>User name: </b>{this.state.ongoingrideview.firstname}</p>
                      <p><b>User number: </b>{this.state.ongoingrideview.mobileno}</p>
                      <p><b>From location: </b>{this.state.ongoingrideview.from_location}</p>
                      <p><b>To location: </b>{this.state.ongoingrideview.to_location}</p>
                    </div>  
                    </div>  
                    <div className="map-wrapper">                      
                     <div id="map"></div>
                     <div className="mapbox-gl-marker"></div>
                    </div>
              </div>            
            
          </div>
         </div> 
        </div>        
    </div>
    );
  }
}

const mapStateTopProps = (state) => {
  return {
    ongoingrideview: state.ongoingrideview,
    ongoingrideview_fromLat: state.ongoingrideview.from_lat
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getViewOngoingride: (rideId) =>{
       dispatch({ type: GET_VIEWONGOINGRIDE,rideId });
    },
  }
}
export default connect(mapStateTopProps, mapDispatchToProps)(withRouter(View));