/* eslint-disable */
import { takeEvery, call, put } from 'redux-saga/effects';
import { GET_GALLERY, SET_GALLERY } from '../actions';
import {apiUrl } from "../components/Config/Config";
import Axios from 'axios';



/*========Insert Gallery==============*/

export const watchGetGallery = function* () {
  yield takeEvery(GET_GALLERY, workerGetGallery);
}

function* workerGetGallery({ formPayload }) {
  try {
      
 const result = yield call(getGallery, formPayload);
  var resultArr = [];
  resultArr.push(result.data);
    yield put({ type: SET_GALLERY, value: resultArr });
  } 
  catch {
    console.log('post data failed');
  }
} 


function getGallery(formPayload) {
   return Axios.post(apiUrl+'gallery/uploadgallery', formPayload);
} 