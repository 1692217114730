import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import Header from '../Layouts/Header';
import MenuSidebar from '../Layouts/MenuSidebar';
import $ from 'jquery';
import Select from 'react-select';
import { lang } from '../Helpers/lang';
import { PageTitle, CheckAuth, scrollToTopValidate, scrollToTop } from "../Helpers/SettingHelper";



import { }  from '../../actions'; 

class Uploadgallery extends Component {
  constructor(props) {
      super(props); 
      this.state = {
        servicedata:[],
        user_services:[],
        checked: false
      }
      console.log('test')
  }

  handleFormSubmit = () => {
       
  }
  componentWillReceiveProps(Props){
    
  }
  CheckService = (event) =>{
          var serviceArr = [...this.state.user_services];
          const value = event.target.value
          const index = serviceArr.findIndex(user_services => user_services === value);
          if(index > -1) {
          serviceArr = [...serviceArr.slice(0, index), ...serviceArr.slice(index + 1)]
          } else {
          serviceArr.push(value);
          }
          this.setState({user_services: serviceArr});
  }

   servicelist() {


            return (
                        <div className="asp-col">
                        <div className="asp-inner-box">    
                        <label className="asp-checkbox-label">
                      
                        </label>
                        </div>
                        </div>
                     );
       
  }
  render() {
    return (
        <>     
          <div className="add-service-popup-wrapper">
          <div className="add-service-popup-inner-wrapper">
          {this.servicelist()}
          </div>
          </div>        
          <button className="btn btn_orange btn_minwid btn-width" type="button" onClick={this.handleFormSubmit.bind(this)}>Update Now
          </button>
        </>
    );
  }
}

const mapStateTopProps = (state) => {
  return {
     
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    

  }
}
export default connect(mapStateTopProps, mapDispatchToProps)(withRouter(Uploadgallery));
