/* eslint-disable */
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import $ from 'jquery';

import Header from '../Layouts/Header';
import MenuSidebar from '../Layouts/MenuSidebar';
import { SketchPicker } from 'react-color'
import reactCSS from 'reactcss'
import { scrollToTopValidate, PageTitle,scrollToTop } from "../Helpers/SettingHelper";
import { GET_FAVOURITE,GET_UPDATEFAVOURITE } from '../../actions';
import Select from 'react-select';
import { favouritesUrl,apiUrl } from '../Config/Config';
import axios from 'axios';


class Favourites extends Component {
	
  constructor(props) {
    super(props);
	this.state = {
		favourite1_label:'',
		favourite2_label:'',
		favourite3_label:'',
		favourite4_label:'',
		plugin_id:'',
		admin_id:localStorage.getItem("admin_id"),
		Loading: false,
		notify_font_label_size:'',
		notify_font_text_size:'',
		notify_height:'',
		user_image1: [],
		user_image_name1: "",
		user_image_preview1: "",
		user_image2: [],
		user_image_name2: "",
		user_image_preview2: "",
		user_image3: [],
		user_image_name3: "",
		user_image_preview3: "",
		user_image4: [],
		user_image_name4: "",
		user_image_preview4: "",
		remove_preview1: false,
		remove_preview2: false,
		remove_preview3: false,
		remove_preview4: false,
		remove_preview5: false,
	}
	var qs = require('qs');
		
	var postobject = {
		admin_id:  localStorage.getItem("admin_id")
	};
	this.props.getFavouriteData(qs.stringify(postobject));
		
	this.handleInputChange  = this.handleInputChange.bind(this);
	this.onChangeHandler = this.onChangeHandler.bind(this);	
	this.removeImage = this.removeImage.bind(this);
  }
  
  componentDidMount() {
      document.title = PageTitle('Favourites');
      if(localStorage.getItem('admin_id') === null){
      this.props.history.push('/');
      }
	   
   }
	 
  handleInputChange(event) {

      const {name, value} = event.target;      
      this.setState({
          [name]: value
        });
  }

  handleFormSubmit = () => {
	  
		const config = {
			headers: {
			  'Content-Type': 'multipart/form-data'
			}
		};
				  
	  const formPayload = this.state;
	  var qs = require('qs');

		var postObject = {
			admin_id : localStorage.getItem("admin_id"),
			plugin_id :  formPayload.plugin_id,
			favourite1_label       : formPayload.favourite1_label,
			favourite2_label    : formPayload.favourite2_label,
			favourite3_label     : formPayload.favourite3_label,
			favourite4_label    : formPayload.favourite4_label,
			favourite1_file: formPayload.user_image1,         
			favourite2_file: formPayload.user_image2,          
			favourite3_file: formPayload.user_image3,          
			favourite4_file: formPayload.user_image4,    
			remove_preview1: formPayload.remove_preview1,          
			remove_preview2: formPayload.remove_preview2,          
			remove_preview3: formPayload.remove_preview3,          
			remove_preview4: formPayload.remove_preview4,          
			remove_preview5: formPayload.remove_preview5,          
		};
		
		let formData = new FormData();
		for(let k in postObject) {
		  formData.append(k, postObject[k]);
		}

		this.props.getUpdateFavourite(formData,config);
  }
  
  componentWillReceiveProps(Props){

		if(Props.favourite !== this.props.favourite){

			if(Object.keys(Props.favourite).length > 0){

				const formpayload = Props.favourite[0].results;
				this.setState({plugin_id:formpayload.plugin_id});
				this.setState({favourite1_label: formpayload.favourite1_label});
				this.setState({favourite2_label: formpayload.favourite2_label});
				this.setState({favourite3_label: formpayload.favourite3_label});
				this.setState({favourite4_label: formpayload.favourite4_label});
				this.setState({user_image_preview1: formpayload.favourite1_file});
				this.setState({user_image_preview2: formpayload.favourite2_file});
				this.setState({user_image_preview3: formpayload.favourite3_file});
				this.setState({user_image_preview4: formpayload.favourite4_file});
				
			}
		} 
		if(Props.updatefavourite !== this.props.updatefavourite){					
		
			if(Object.keys(Props.updatefavourite).length > 0){				
				this.setState({ Loading: false });
				const formpayload1 = Props.updatefavourite[0].results;
				
				
				this.setState({plugin_id:formpayload1.plugin_id});
				this.setState({favourite1_label: formpayload1.favourite1_label});
				this.setState({favourite2_label: formpayload1.favourite2_label});
				this.setState({favourite3_label: formpayload1.favourite3_label});
				this.setState({favourite4_label: formpayload1.favourite4_label});

				$('.success_message').html('<div class="status_sucess"><h3>'+ Props.updatefavourite[0].message+'</h3></div>');
                	scrollToTop();
    				setTimeout(
						function() {
							$('.success_message').html('');
							this.props.history.push('/favourites');
						}
						.bind(this),
						3000
						);
			}
		}
		

   }

 
	
	removeImage=(val,event)=>{console.log(val)
		if(val === 'favourite1_file'){
			this.setState({user_image_preview1:'',remove_preview1:true});
		}else if(val === 'favourite2_file'){
			this.setState({user_image_preview2:'',remove_preview2:true});
		}else if(val === 'favourite3_file'){
			this.setState({user_image_preview3:'',remove_preview3:true});
		}else if(val === 'favourite4_file'){
			this.setState({user_image_preview4:'',remove_preview4:true});
		}
	}
  
	onChangeHandler=(val,event)=>{ 
		console.log(val)
		if(val === 'favourite1_file'){
			let reader = new FileReader();
			const file1 = event.target.files[0];			
			reader.onloadend = () => {
			  this.setState({
				user_image_name1: file1.name,
				user_image_preview1: reader.result,
				user_image1: file1
			  });
			};
			reader.readAsDataURL(file1);
		}else if(val === 'favourite2_file'){
			let reader = new FileReader();
			const file2 = event.target.files[0];			
			reader.onloadend = () => {
			  this.setState({
				user_image_name2: file2.name,
				user_image_preview2: reader.result,
				user_image2: file2
			  });
			};
			reader.readAsDataURL(file2);
		}else if(val === 'favourite3_file'){
			let reader = new FileReader();
			const file3 = event.target.files[0];			
			reader.onloadend = () => {
			  this.setState({
				user_image_name3: file3.name,
				user_image_preview3: reader.result,
				user_image3: file3
			  });
			};
			reader.readAsDataURL(file3);
		}else if(val === 'favourite4_file'){
			let reader = new FileReader();
			const file4 = event.target.files[0];			
			reader.onloadend = () => {
			  this.setState({
				user_image_name4: file4.name,
				user_image_preview4: reader.result,
				user_image4: file4
			  });
			};
			reader.readAsDataURL(file4);
		}
		
	/**/
  }

  
  render() {
	  
	  let { user_image_preview1,user_image_preview2,user_image_preview3,user_image_preview4} = this.state;
	let carImg1 = '', carImg2 = '', carImg3 ='', carImg4 ='';
	let preview1 = '', preview2 = '', preview3 = '', preview4 ='';
	
	if (user_image_preview1!== null && user_image_preview1!== '') {
	    carImg1 = favouritesUrl + "/" + user_image_preview1;
		preview1 = <img className="img_class" src={carImg1} alt="" />;
	}
	if (user_image_preview2!== null && user_image_preview2!== '') {
	    carImg2 = favouritesUrl + "/" + user_image_preview2;
		preview2 = <img className="img_class" src={carImg2} alt="" />;
	}
	if (user_image_preview3!== null && user_image_preview3!== '') {
	    carImg3 = favouritesUrl + "/" + user_image_preview3;
		preview3 = <img className="img_class" src={carImg3} alt="" />;
	}
	if (user_image_preview4!== null && user_image_preview4!== '') {
	    carImg4 = favouritesUrl + "/" + user_image_preview4;
		preview4 = <img className="img_class" src={carImg4} alt="" />;
	}
	
	
	    return (
	<div className="wrapper"> 
		<Header />
		<MenuSidebar />
			

		<div className="content">	
			<div className="content-wrapper">
				<div className="content-body">
					<form className="settings" onSubmit={ e => { this.handleFormSubmit(this); e.preventDefault(); }}>
						<div className="form-wrapper">
							<div className="success_message"></div>
							<div className="title">
								<h4>Favourites</h4>
							</div>
							<div className="form-first-content">
							<div className="form-row">
								
								<div className="form-left">
									<div className="form-group"> 
										<label>Favourite1 Label:</label>
										<input className="form-control" name="favourite1_label" onChange={this.handleInputChange} value={this.state.favourite1_label} />
									</div>
									<div className="form-group image-section">
										<label>Favourite1 Image:</label>
										   <div className="choose-file">
										{this.state.isHidden && <div className="image_success"></div>}
										   <span className="profile_btn"><input type="file" name="favourite1_file" onChange={(e) => this.onChangeHandler('favourite1_file',e)} /></span>
										</div>
										{preview1}
										{user_image_preview1?<a className="profile_btn-close" href="javascript:void(0);" onClick={(e) =>this.removeImage('favourite1_file',e)}>X</a>:''}
										<div className="errorcarimage"></div> 
									</div>
								</div>	
								</div>		
							</div>

							<div className="form-first-content">
								<div className="form-row">
									
									<div className="form-left">
									<div className="form-group"> 
										<label>Favourite2 Label:</label>
										<input className="form-control" name="favourite2_label" onChange={this.handleInputChange} value={this.state.favourite2_label} />
									</div>
									<div className="form-group image-section">
										<label>Favourite two Image:</label>
										   <div className="choose-file">
										{this.state.isHidden && <div className="image_success"></div>}
										   <span className="profile_btn"><input type="file" name="favourite2_file" onChange={(e) => this.onChangeHandler('favourite2_file',e)} /></span>
										</div>
										{preview2}
										{user_image_preview2?<a className="profile_btn-close" href="javascript:void(0);" onClick={(e) =>this.removeImage('favourite2_file',e)}>X</a>:''}
										<div className="errorcarimage"></div> 
									</div>
								

									</div>
								</div>
						    </div>	


							<div className="form-first-content">
								<div className="form-row">
									
									<div className="form-left">
									<div className="form-group"> 
										<label>Favourite3 Label:</label>
										<input className="form-control" name="favourite3_label" onChange={this.handleInputChange} value={this.state.favourite3_label} />
									</div>
									<div className="form-group image-section">
										<label>Favourite3 Image:</label>
										<div className="choose-file">
										{this.state.isHidden && <div className="image_success"></div>}
										<span className="profile_btn"><input type="file" name="favourite3_file" onChange={(e) => this.onChangeHandler('favourite3_file',e)} /></span>
										</div>
									{preview3}
									{user_image_preview3?<a className="profile_btn-close" href="javascript:void(0);" onClick={(e) =>this.removeImage('favourite3_file',e)}>X</a>:''}
									<div className="errorcarimage"></div> 
									</div>
									</div>
								</div>
						    </div>	

						<div className="form-first-content">
						<div className="form-row">
							
							<div className="form-left">
							<div className="form-group"> 
								<label>Favourite4 Label:</label>
								<input className="form-control" name="favourite4_label" onChange={this.handleInputChange} value={this.state.favourite4_label} />
							</div>
							<div className="form-group image-section">
										<label>Favourite4 Image:</label>
										<div className="choose-file">
										{this.state.isHidden && <div className="image_success"></div>}
										<span className="profile_btn"><input type="file" name="favourite4_file" onChange={(e) => this.onChangeHandler('favourite4_file',e)} /></span>
										</div>
									{preview4}
									{user_image_preview3?<a className="profile_btn-close" href="javascript:void(0);" onClick={(e) =>this.removeImage('	favourite3_file',e)}>X</a>:''}
									<div className="errorcarimage"></div> 
									</div>
						  </div>
						</div>
						</div>	


							<div className="form-row">
								
								<div className="btn-group right">				
									<button className="btn btn_orange btn_minwid login_submit animate-btn2" type="submit" disabled={(this.state.Loading ===true)?true:false}> {this.state.Loading ===true &&
													<span className="load-data">Loading</span> 
													} Submit
									</button>	
								</div>
								
							</div>
						</div>
					</form>	
				</div>
			</div>
		</div>
	</div>
	
		)
  }
}

const mapStateTopProps = (state) => {
  return {
	favourite: state.favourite,
	updatefavourite: state.updatefavourite,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {	   
	   getFavouriteData: (formPayload) =>{
		dispatch({ type: GET_FAVOURITE, formPayload});
       },
       getUpdateFavourite: (formPayload) => { 
		  dispatch({ type: GET_UPDATEFAVOURITE, formPayload });
		}
   	  }
}
export default connect(mapStateTopProps, mapDispatchToProps)(withRouter(Favourites));