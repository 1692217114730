import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import Header from '../Layouts/Header';
import MenuSidebar from '../Layouts/MenuSidebar';
import $ from 'jquery';
import Select from 'react-select';
import { lang } from '../Helpers/lang';
import { PageTitle, CheckAuth } from "../Helpers/SettingHelper";


import {GET_EDITVEHICLE,GET_VEHICLEDETAIL }  from '../../actions'; 

class Edit extends Component {
	
	constructor(props)
	{
		CheckAuth();
		super(props);	
	    const VehicleId = (typeof this.props.match.params.ID === 'undefined') ? '' : this.props.match.params.ID;
		this.state = {
			vehicle_type:'',
			vehicle_passenger:'',
			vehicle_lagguage:'',
			selectedvalue: '',
            selectedOption:{ value: 'active', label: 'Active'},
			statusmessage:'',
			vehicle_id: VehicleId,
			Loading:false
		};
		this.props.getVehicleDetail(VehicleId);
		this.handleInputChange = this.handleInputChange.bind(this);
	    this.handleChange = this.handleChange.bind(this);

    }
	

    handleChange = selectedOption => {
        this.setState({selectedOption})
        this.setState({  selectedvalue : selectedOption.value});
    };
   handleInputChange(event) {

      const {name, value} = event.target;      
      this.setState({
          [name]: value
        });
    }

	componentDidMount() {
		document.title = PageTitle('Vehicle Add');
    }


        handleFormSubmit = () => {
			if(this.validateForm()){
				this.setState({Loading:true});
				const formPayload = this.state;
				var qs = require('qs');
				var status = '';
				if(formPayload.selectedvalue === '' && formPayload.selectedvalue!== 0){
				status =formPayload.selectedOption.value;
				}else{
				status = formPayload.selectedvalue;
				}
				var postObject = {
					admin_id : localStorage.getItem("admin_id"),
					vehicle_id : this.state.vehicle_id,
					vehicle_type: formPayload.vehicle_type,
					vehicle_passenger:formPayload.vehicle_passenger,
					vehicle_lagguage:formPayload.vehicle_lagguage,
					vehicle_status:status,
				};
				this.props.getEditVehicle(qs.stringify(postObject));
			}
	}

	validateForm() {
		const {vehicle_type,vehicle_lagguage,vehicle_passenger} = this.state;
		let errors = {};
      	let formIsValid = true;
	
		if (!vehicle_type) {
			formIsValid = false;
			$('.errorvehicle_type').html('<span class="errorspan">Please fill the field</span>');
		}else if(vehicle_type){
			$('.errorvehicle_type').html('');
		}

		if (!vehicle_lagguage) {
			formIsValid = false;
			$('.errorvehicle_lagguage').html('<span class="errorspan">Please fill the field</span>');
		}else if(vehicle_lagguage){
			$('.errorvehicle_lagguage').html('');
		}

		if (!vehicle_passenger) {
			formIsValid = false;
			$('.errorvehicle_passenger').html('<span class="errorspan">Please fill the field</span>');
		}else if(vehicle_passenger){
			$('.errorvehicle_passenger').html('');
		}




		this.setState({
			errors: errors
		});
		return formIsValid;
    }

    componentWillReceiveProps(Props){

   	if(Props.vehicledetail !== this.props.vehicledetail){
    		if(Object.keys(Props.vehicledetail).length > 0){
				this.setState({Loading:false});

					if(Props.vehicledetail[0].status === "success"){

						const formdata = Props.vehicledetail[0].vehiclelist;
						if(formdata.vehicle_status === 'A'){
							this.setState({selectedOption:{value: 'active', label: 'Active'}});
						}else{
							this.setState({selectedOption:{value: 'inactive', label: 'Inactive'}});

						}
						this.setState({vehicle_type:formdata.vehicle_type});
						this.setState({vehicle_passenger:formdata.vehicle_passenger});
						this.setState({vehicle_lagguage:formdata.vehicle_lagguage});

						//this.setState({selectedvalue: formdata.vehicle_status})

					}
    		}
    	}
    	if(Props.vehicleedit !== this.props.vehicleedit){
    		if(Object.keys(Props.vehicleedit).length > 0){
    					this.setState({ Loading: false });

    			const formpayload = Props.vehicleedit[0].vehiclelist;
				if(formpayload.vehicle_status === 'A'){
				this.setState({selectedOption:{value: 'active', label: 'Active'}});
				}else{
				this.setState({selectedOption:{value: 'inactive', label: 'Inactive'}});

				}
				this.setState({vehicle_type:formpayload.vehicle_type});
				this.setState({vehicle_passenger:formpayload.vehicle_passenger});
				this.setState({vehicle_lagguage:formpayload.vehicle_lagguage});
               	$('.success_message').html('<div class="status_sucess"><h3>'+ Props.vehicleedit[0].message+'</h3></div>');


    					setTimeout(
						function() {
							$('.success_message').html('');
						}
						.bind(this),
						3000
						);
    		}
    	}
    }
	
  render() {
  	  const {selectedOption} = this.state;
    return (
      <div className="wrapper"> 
 
	  <Header />
    <MenuSidebar currentpage="vehiclelist" />  

	
	<div className="content">	
		<div className="content-wrapper">
			<div className="form-wrapper">
			<div className="success_message"></div>
			<div className="title">
				<h4>Edit Details</h4>
			</div>
			 <form className="edit" id="edit" onSubmit={ e => { this.handleFormSubmit(this); e.preventDefault(); }}>
			<div className="form-row">
			<div className="form-left">
				<div className="form-group">
					<label>Vehicle Type:</label>
				    <input type="text" name="vehicle_type" onChange={this.handleInputChange} className="form-control" value={this.state.vehicle_type} />
					<div className="errorvehicle_type"></div>
				</div>
				<div className="form-group">					
					<label>No of Passengers:</label>
						<input type="number" name="vehicle_passenger" onChange={this.handleInputChange} className="form-control" value={this.state.vehicle_passenger} />
					<div className="errorvehicle_passenger"></div>
				</div>
			</div>	
			<div className="form-right">
				<div className="form-group">
					<label>No of Lagguage:</label>
						<input type="number" name="vehicle_lagguage" onChange={this.handleInputChange} className="form-control" value={this.state.vehicle_lagguage} />
					<div className="errorvehicle_lagguage"></div>
				</div>
				<div className="form-group">					
					<label>Status:</label>
						   <Select 
						   value={selectedOption?selectedOption:{ value: 'active', label: 'Active'}}
                            options={lang.common.status_option} 
                            onChange={this.handleChange}
                            />
					<div className="erroradmin_email_address"></div>
				</div>
			</div>
			</div>		

			<div className="btn-group export">	
				  <button className="btn btn_orange btn_minwid login_submit animate-btn2" type="submit" disabled={(this.state.Loading ===true)?true:false}> {this.state.Loading ===true &&
                                        <span className="load-data"></span> 
                                        }Submit
                   </button>
			</div>
			</form>
			</div>				
		</div>	
	</div>
		 	
    </div>
    );
  }
}


const mapStateTopProps = (state) => {
  return {
    vehicledetail: state.vehicledetail,
    vehicleedit: state.vehicleedit
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getVehicleDetail: (VehicleId) =>{
       dispatch({ type: GET_VEHICLEDETAIL,VehicleId });
    },
     getEditVehicle: (formPayload) =>{
       dispatch({ type: GET_EDITVEHICLE, formPayload});
    }
  }
}
export default connect(mapStateTopProps, mapDispatchToProps)(withRouter(Edit));