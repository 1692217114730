/* eslint-disable */
import { takeEvery, call, put } from 'redux-saga/effects';
import { GET_USERINVITEFRIEND, SET_USERINVITEFRIEND, GET_UPDATEUSERINVITEFRIEND, SET_UPDATEUSERINVITEFRIEND } from '../actions';
import {apiUrl } from "../components/Config/Config";
import Axios from 'axios';


export const watchGetUserinvitefriend = function* () {
  yield takeEvery(GET_USERINVITEFRIEND, workerGetUserinvitefriend);
}

function* workerGetUserinvitefriend({ formPayload }) {
  try {
      
 const result = yield call(getInvitefriendData, formPayload);
  var resultArr = [];
  resultArr.push(result.data);
    yield put({ type: SET_USERINVITEFRIEND, value: resultArr });
  } 
  catch {
    console.log('post data failed');
  }
} 


function getInvitefriendData(formPayload) {
   return Axios.post(apiUrl+'invitefriend/userinvitefriend', formPayload);
  // return Axios.post(apiUrl+'notification/usernotification', formPayload);
}  



/*========Update Notification==============*/

export const watchGetUpdateUserinvitefriend = function* () {
  yield takeEvery(GET_UPDATEUSERINVITEFRIEND, workerGetUpdateUserinvitefriend);
}

function* workerGetUpdateUserinvitefriend({ formPayload }) {
  try {
      
 const result = yield call(getUpdateInvitefriend, formPayload);
  var resultArr = [];
  resultArr.push(result.data);
    yield put({ type: SET_UPDATEUSERINVITEFRIEND, value: resultArr });
  } 
  catch {
    console.log('post data failed');
  }
} 


function getUpdateInvitefriend(formPayload) {
   return Axios.post(apiUrl+'invitefriend/userinvitefriendsave', formPayload);
  // return Axios.post(apiUrl+'notification/usernotificationsave', formPayload);
} 