/* eslint-disable */
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import $ from 'jquery';

import Header from '../Layouts/Header';
import MenuSidebar from '../Layouts/MenuSidebar';
import { SketchPicker } from 'react-color'
import reactCSS from 'reactcss'
import { scrollToTopValidate, PageTitle,scrollToTop } from "../Helpers/SettingHelper";
import { GET_USERINVITEFRIEND,GET_UPDATEUSERINVITEFRIEND,GET_FONTNAME } from '../../actions';
import Select from 'react-select';
import { carImageUrl,apiUrl } from '../Config/Config';
import axios from 'axios';


class Invitefriend extends Component {
	
  constructor(props) {
    super(props);
	this.state = {
		invite_content_label:'',
		invite_content:'',		
		plugin_id:'',
		admin_id:localStorage.getItem("admin_id"),
		Loading: false,		
		user_image1: [],
		user_image_name1: "",
		user_image_preview1: "",		
		remove_preview1: false
	}
	var qs = require('qs');
		
	var postobject = {
		admin_id:  localStorage.getItem("admin_id")
	};
	this.props.getUserInvitefriendData(qs.stringify(postobject));
	
		
	this.handleInputChange  = this.handleInputChange.bind(this);
	
	this.onChangeHandler = this.onChangeHandler.bind(this);	
	this.removeImage = this.removeImage.bind(this);
  }
  
  componentDidMount() {
      document.title = PageTitle('Settings');
      if(localStorage.getItem('admin_id') === null){
      this.props.history.push('/');
      }
	   
   }
	 
  handleInputChange(event) {

      const {name, value} = event.target;      
      this.setState({
          [name]: value
        });
  }
  handleFormSubmit = () => {
	  
		const config = {
			headers: {
			  'Content-Type': 'multipart/form-data'
			}
		};
				  
	  const formPayload = this.state;
	  var qs = require('qs');

		var postObject = {
			admin_id : localStorage.getItem("admin_id"),
			plugin_id :  formPayload.plugin_id,
			invite_content_label       : formPayload.invite_content_label,
			invite_content       : formPayload.invite_content,
			invite_file: formPayload.user_image1,         
			remove_preview1: formPayload.remove_preview1,          
			     
		};
		
		let formData = new FormData();
		for(let k in postObject) {
		  formData.append(k, postObject[k]);
		}

		this.props.getUpdateInvitefriendData(formData,config);
  }
  
  componentWillReceiveProps(Props){

		if(Props.invitefriend !== this.props.invitefriend){
			if(Object.keys(Props.invitefriend).length > 0){
				const formpayload = Props.invitefriend[0].results;
				
				this.setState({plugin_id:formpayload.plugin_id});
				this.setState({invite_content_label: formpayload.invite_content_label});
				this.setState({invite_content: formpayload.invite_content});
				this.setState({user_image_preview1: formpayload.invite_file});
								
				
			}
		} 
		console.log(Props,"Propsis");
		if(Props.updateinvitefriend !== this.props.updateinvitefriend){					
		
			if(Object.keys(Props.updateinvitefriend).length > 0){				
				this.setState({ Loading: false });
				const formpayload1 = Props.updateinvitefriend[0].results;
				
				console.log(formpayload1,"TEst NOty")	
				
				
				this.setState({plugin_id:formpayload1.plugin_id});
				this.setState({invite_content_label: formpayload1.invite_content_label});
				this.setState({invite_content: formpayload1.invite_content});
			

				$('.success_message').html('<div class="status_sucess"><h3>'+ Props.updateinvitefriend[0].message+'</h3></div>');
                	scrollToTop();
    				setTimeout(
						function() {
							$('.success_message').html('');
						}
						.bind(this),
						3000
						);
			}
		}
	}
 
	
	removeImage=(val,event)=>{console.log(val)
		if(val === 'invite_file'){
			this.setState({user_image_preview1:'',remove_preview1:true});
		}
	}
  
	onChangeHandler=(val,event)=>{ 
		
		if(val === 'invite_file'){
			let reader = new FileReader();
			console.log(reader)
			const file1 = event.target.files[0];			
			reader.onloadend = () => {
			  this.setState({
				user_image_name1: file1.name,
				user_image_preview1: reader.result,
				user_image1: file1
			  });
			};
			reader.readAsDataURL(file1);
		}
		
	/**/
  }

  
  render() {
	  
	  let { user_image_preview1} = this.state;
	let carImg1 = '';
	let preview1 = '';
	
	if (user_image_preview1!== null && user_image_preview1!== '') {
	    carImg1 = carImageUrl + "/" + user_image_preview1;
		preview1 = <img className="img_class" src={carImg1} alt="" />;
	}
	
	    return (
	<div className="wrapper"> 
		<Header />
		<MenuSidebar />
			

		<div className="content">	
			<div className="content-wrapper">
				<div className="content-body">
					<form className="settings" onSubmit={ e => { this.handleFormSubmit(this); e.preventDefault(); }}>
						<div className="form-wrapper">
							<div className="success_message"></div>
							<div className="title">
								<h4>Invite Friend</h4>
							</div>
							<div className="form-row">
								<div className="form-left">
									<div className="form-group"> 
										<label>Invite Label:</label>
										<input className="form-control" name="invite_content_label" onChange={this.handleInputChange} value={this.state.invite_content_label} />
									</div>
									<div className="form-group"> 
										<label>Invite Content:</label>
										<textarea className="form-control" name="invite_content" onChange={this.handleInputChange} value={this.state.invite_content} />
									</div>
									<div className="form-group">
										<label>Image:</label>
										   <div className="choose-file">
										{this.state.isHidden && <div className="image_success"></div>}
										   <span className="profile_btn"><input type="file" name="invite_file" onChange={(e) => this.onChangeHandler('invite_file',e)} /></span>
										</div>
										{preview1}
										{user_image_preview1?<a href="javascript:void(0);" onClick={(e) =>this.removeImage('invite_file',e)}>X</a>:''}
										<div className="errorcarimage"></div> 
									</div>
								</div>
								
								
							</div>
							
						
						
							<div className="form-row">
								
								<div className="btn-group right">				
									<button className="btn btn_orange btn_minwid login_submit animate-btn2" type="submit" disabled={(this.state.Loading ===true)?true:false}> {this.state.Loading ===true &&
													<span className="load-data">Loading</span> 
													} Submit
									</button>	
								</div>
								
							</div>
						</div>
					</form>	
				</div>
			</div>
		</div>
	</div>
	
		)
  }
}

const mapStateTopProps = (state) => {
  return {
	invitefriend: state.invitefriend,
	updateinvitefriend: state.updateinvitefriend,
	
  }
}

const mapDispatchToProps = (dispatch) => {
  return {	   
	   getUserInvitefriendData: (formPayload) =>{
		dispatch({ type: GET_USERINVITEFRIEND, formPayload});
       },

	   getUpdateInvitefriendData: (formPayload) => { 
		  dispatch({ type: GET_UPDATEUSERINVITEFRIEND, formPayload });
		}
   	  }
}
export default connect(mapStateTopProps, mapDispatchToProps)(withRouter(Invitefriend));