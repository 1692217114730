import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import Header from '../Layouts/Header';
import MenuSidebar from '../Layouts/MenuSidebar';
import $ from 'jquery';
import Select from 'react-select';
import { lang } from '../Helpers/lang';
import { PageTitle, scrollToTopValidate, scrollToTop } from "../Helpers/SettingHelper";
import { licenseURL, insuranceURL, profileURL, drivercarURL } from '../Config/Config';
import dummylicense from '../../common/images/driver-license.jpg';
import pdficon from '../../common/images/pdf.svg';
import {GET_EDITDRIVER,GET_DRIVERDETAIL, GET_VEHICLETYPE, GET_VEHICLEBYTYPE }  from '../../actions'; 

class Edit extends Component {
	
	fileObj = [];
    fileArray = [];
    imageArray = [];
	
	constructor(props)
	{
		super(props);	
		const driverid = (typeof this.props.match.params.ID === 'undefined') ? '' : this.props.match.params.ID;

		this.state = {
			driverid: driverid,
			username:'',
			firstname:'',
			lastname:'',
			email:'',
			phoneno:'',
			password:'',
			status:'',
			changepassword:'',
            selectedOption:{ value: 'active', label: 'Active'},
			selectedvalue:'',
			disablepasssword:true,
			selectedVehicle:'',
			vehiclevalue:'',	
			driving_license:'',
			profile_image:'',
			license:'',
			insurance	:'',
			car_number:'',
			car_color:'',
			car_image:'',
			profile_checked:false,
			license_checked:false,
			insurance_checked:false,
			car_image_checked: false,
			bankaccdetail_checked: false,
			admin_id: localStorage.getItem('admin_id'),
			vehiclesbytype:[],
			selectedVehiclebytype:'',
			vehiclevaluebytype:''
		};
		
	   this.handleInputChange = this.handleInputChange.bind(this);
	   this.handleVerified = this.handleVerified.bind(this);
	   this.handleChange = this.handleChange.bind(this);
	   this.handleChangeVehicle = this.handleChangeVehicle.bind(this);
	   this.handleChangeVehiclebytype = this.handleChangeVehiclebytype.bind(this);
	   this.props.getVehicleType(this.state.admin_id);
	   this.props.getDriverDetail(driverid);
    }

   handleInputChange(event) {

      const {name, value} = event.target;      
      this.setState({
          [name]: value
        });
      if(name == 'password'){
      	this.setState({disablepasssword: false})
      }
    }
   handleChange = selectedOption => {
        this.setState({selectedOption})
        this.setState({  selectedvalue : selectedOption.value});
    };
    handleVerified(event){

    	const {name} = event.target;
		if(name === 'enable_license'){
		 this.setState({license_checked: !this.state.license_checked});
		}	
		if(name === 'enable_profile'){
		 this.setState({profile_checked: !this.state.profile_checked});
		}	
		if(name === 'enable_insurance'){
		 this.setState({insurance_checked: !this.state.insurance_checked});
		}	
		if(name === 'enable_carimage'){
		 this.setState({car_image_checked: !this.state.car_image_checked});
		}
		if(name === 'enable_bankaccdetail'){
		 this.setState({bankaccdetail_checked: !this.state.bankaccdetail_checked});
		}		
		
		
    }
	componentDidMount() {
		document.title = PageTitle('user Edit');
		if(localStorage.getItem('admin_id') === null){
		this.props.history.push('/');
		}
    }

   handleChangeVehicle = selectedVehicle => {
      this.setState({ selectedVehicle});
      this.setState({ vehiclevalue : selectedVehicle.value,selectedVehiclebytype:'' });
      this.props.getVehicleByType(selectedVehicle.value);
      if(selectedVehicle.value === ''){
        $('.errorvehicletype').html('<span class="errorspan">Please fill the field</span>');
      }
   }

  handleChangeVehiclebytype = selectedVehiclebytype => {
   	 this.setState({ selectedVehiclebytype});
      this.setState({ vehiclevaluebytype : selectedVehiclebytype.value });
      if(selectedVehiclebytype.value === ''){
        $('.errorvehiclebytype').html('<span class="errorspan">Please select anyone</span>');
      }
   }

 handleFormSubmit = () => {
			if(this.validateForm()){
				this.setState({Loading:true});
				var qs = require('qs');
				const formPayload = this.state;
			    var status = '';
				if(formPayload.selectedvalue === '' && formPayload.selectedvalue!== 0){
				status =formPayload.selectedOption.value;
				}else{
				status = formPayload.selectedvalue;
				}

				var vehicletype;
				if(formPayload.selectedVehicle !== '' && formPayload.selectedVehicle !== 'undefined'){
				vehicletype =formPayload.selectedVehicle.value;
				}else{
				vehicletype =formPayload.vehiclevalue;
				}

				var vehicle;
				
				if(formPayload.selectedVehiclebytype !== '' && formPayload.selectedVehiclebytype!=='undefined'){
				vehicle =formPayload.selectedVehiclebytype.value;
				}else{
				vehicle =formPayload.vehiclevaluebytype;
				}

				let insurance_verified;
				if(formPayload.insurance_checked === true){
					insurance_verified = 'yes';
				}else{
					insurance_verified = 'no';
				}
				let license_verified;
				if(formPayload.license_checked === true){
					license_verified = 'yes';
				}else{
					license_verified = 'no';
				}
				let profile_verified;
				if(formPayload.profile_checked === true){
					profile_verified = 'yes';
				}else{
					profile_verified = 'no';
				}

				let carimage_verified;
				if(formPayload.car_image_checked === true){
					carimage_verified = 'yes';
				}else{
					carimage_verified = 'no';
				}
				
				let bankaccdetail_verified;
				if(formPayload.bankaccdetail_checked === true){
					bankaccdetail_verified = 'yes';
				}else{
					bankaccdetail_verified = 'no';
				}

				var postObject = {
			        admin_id            : localStorage.getItem("admin_id"),
					userid              : formPayload.userid,
					username            : formPayload.username,
					firstname           : formPayload.firstname,
					lastname            : formPayload.lastname,
					email               : formPayload.email,
					phoneno             : formPayload.phoneno,
					car_number			: formPayload.car_number,
					car_color			: formPayload.car_color,
					currentpswd         : formPayload.password,
					changepassword      : formPayload.changepassword, 
					vehicle_type        : vehicletype,
					vehicle             : vehicle,
					profile_verified    : profile_verified,
					license_verified    : license_verified,
					insurance_verified  : insurance_verified,
					car_image_verified  : carimage_verified,
					bankaccdetail_verified  : bankaccdetail_verified,
					status              : status,
				};
				this.props.getEditDriver(qs.stringify(postObject));
			}
	}

	validateForm() {
		const {username,firstname, lastname, email, phoneno, password, changepassword,selectedVehicle,driving_license, car_number} = this.state;
      	//let formIsValid = true;
		let errors = 0;
		if (!username) {
			errors++;
			$('.errorusername').html('<span class="errorspan">Please fill the field</span>');
		}else if(username){
			$('.errorusername').html('');
		}

		if (!firstname) {
			errors++;
			$('.errorfirstname').html('<span class="errorspan">Please fill the field</span>');
		}else if(firstname){
			$('.errorfirstname').html('');
		}

		if (!lastname) {
			errors++;
			$('.errorlastname').html('<span class="errorspan">Please fill the field</span>');
		}else if(lastname){
			$('.errorlastname').html('');
		}
		if (!email) {
			errors++;
			$('.erroremail').html('<span class="errorspan">Please fill the field</span>');
		}else if(email){
		    var reg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
		    if (reg.test(email) == false) 
	        {
	        	errors++;					
	            $('.erroremail').html('<span class="errorspan">Please enter valid email address</span>');
	        }else{
	        	$('.erroremail').html('');
	        }
		}
		
		if (!phoneno) {
			errors++;
			$('.errorphoneno').html('<span class="errorspan">Please fill the field</span>');
		}else if(phoneno){	
			// var pattern = /^[1-9][0-9]{9}$/;
			 var pattern = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;
			//var pattern = new RegExp(/^[0-9\b]+$/);
			 if (pattern.test(phoneno)) {
           		$('.errorphoneno').html('');
             }else{
             	errors++;			     
             	$('.errorphoneno').html('<span class="errorspan">Please enter valid mobile number</span>');
             }	
			
		}

		if(password){
			if(!changepassword){
				errors++;
			   $('.errorchangepaswd').html('<span class="errorspan">Please fill the field</span>');
			}else{
				$('.errorchangepaswd').html('');
			}
		}else{
			$('.errorchangepaswd').html('');
		}

		if (selectedVehicle.value === undefined || selectedVehicle.value === '') {
		$('.errorvehicletype').html('<span class="errorspan">Please fill the field</span>');
		errors++;
		}
		else {
		$('.errorvehicletype').html('');
		}

		if (!driving_license) {
		$('.errordrivinglicense').html('<span class="errorspan">Please enter the license number</span>');
		errors++;
		}
		else {
		$('.errordrivinglicense').html('');
		}

		if (!car_number) {
		$('.errorcar_number').html('<span class="errorspan">Please enter the car number</span>');
		errors++;
		}
		else {
		$('.errorcar_number').html('');
		}

		if(errors>0) {
		setTimeout(function () {
		scrollToTopValidate();
		}, 100);
		return false;
		}
		else {
		return true;
		}

		/*this.setState({
			errors: errors
		});
		return formIsValid;*/
    }

    componentWillReceiveProps(Props){
    	   if(Props.driverdetail !== this.props.driverdetail){

    		if(Object.keys(Props.driverdetail).length > 0){

				this.setState({Loading:false});
					//console.log(Props.carlist);
					if(Props.driverdetail[0].status === "success"){

						const formdata = Props.driverdetail[0].driverlist;
				
						if(formdata.status === 'A'){
							this.setState({selectedOption:{value: 'active', label: 'Active'}});
						}else{
							this.setState({selectedOption:{value: 'inactive', label: 'Inactive'}});

						}
						this.setState({username:formdata.username});
						this.setState({firstname:formdata.firstname});
						this.setState({lastname:formdata.lastname});
						this.setState({email:formdata.email});
						this.setState({phoneno:formdata.mobileno});
						this.setState({driving_license:formdata.driving_license});
						this.setState({userid: formdata.id});
						this.setState({profile_image: formdata.profile_image})
						this.setState({insurance: formdata.insurance})
						this.setState({license: formdata.file_license})
						this.setState({car_number: formdata.car_number})
						this.setState({car_color: formdata.car_color})
						this.setState({car_image: formdata.car_image})
						if(Props.driverdetail[0].vehicletype!== '' && Props.driverdetail[0].vehicletype!== null){
				           this.setselectedvalue(Props.driverdetail[0].vehicletype);
				           this.props.getVehicleByType(Props.driverdetail[0].vehicletype[0].value);
				        }

				        if(Props.driverdetail[0].vehicletype!== '' && Props.driverdetail[0].vehicletype!== null){
				           this.setState({selectedVehiclebytype: Props.driverdetail[0].vehicletype[0]})
				        }

				        if(formdata.insurance_verified == 'yes'){
				        	this.setState({insurance_checked: true})
				        }
				        if(formdata.license_verified == 'yes'){
				        	this.setState({license_checked: true})
				        }
				        if(formdata.profile_verified == 'yes'){
				        	this.setState({profile_checked: true})
				        }
					    if(formdata.carimage_verified == 'yes'){
					    	this.setState({car_image_checked: true})
					    }
						if(formdata.bankaccdetail_verified == 'yes'){
					    	this.setState({bankaccdetail_checked: true})
					    }
					}
    		}
    	}

	
         if(Props.driveredit !== this.props.driveredit){
    		if(Object.keys(Props.driveredit).length > 0){
    					this.setState({ Loading: false });

				if(Props.driveredit[0].status === "success"){

    			const formdata = Props.driveredit[0].driverlist;
				if(formdata.status === 'A'){
				this.setState({selectedOption:{value: 'active', label: 'Active'}});
				}else{
				this.setState({selectedOption:{value: 'inactive', label: 'Inactive'}});

				}
			    this.setState({username:formdata.username});
				this.setState({firstname:formdata.firstname});
				this.setState({lastname:formdata.lastname});
				this.setState({email:formdata.email});
				this.setState({phoneno:formdata.mobileno});
				this.setState({userid: formdata.id});
                $('.success_message').html('<div class="status_sucess"><h3>'+ Props.driveredit[0].message+'</h3></div>');
                	scrollToTop();
    				setTimeout(
						function() {
							$('.success_message').html('');
						}
						.bind(this),
						3000
						);
    			}else{
    				 $('.success_message').html('<div class="status_sucess"><h3>'+ Props.driveredit[0].message+'</h3></div>');
                	scrollToTop();
    				setTimeout(
						function() {
							$('.success_message').html('');
						}
						.bind(this),
						3000
						);
    			}
    		}
    	}   
    	 if(Props.vehicletype.length > 0){
    		 if(Props.vehicletype[0].vehiclelist!== undefined && Props.vehicletype[0].vehiclelist!== null){
               this.setState({ vehicletype : Props.vehicletype[0].vehiclelist})
        	 }
         }

		/*get all vehicle by type*/
		if(Props.vechiclebytype.length > 0){
		if(Props.vechiclebytype && Props.vechiclebytype!== undefined && Props.vechiclebytype!== '' && Props.vechiclebytype !== null){
			if(Props.vechiclebytype[0].status === "success"){
				this.setState({ vehiclesbytype : Props.vechiclebytype[0].result_set})
			}
		}
	  }
   }

  setselectedvalue(selectsers){
    const vehicleArray = selectsers.map ((vehicleType, index) => ({
     id: index,
     name: vehicleType,
    }));
    // Dynamically create select list
    let vehicles = [];
    vehicleArray.map(item =>
    vehicles.push({ label: item.name.label, value: item.name.value }),
    );
    this.setState({selectedVehicle : vehicles[0]})
  }

	
  render() {

  	const {selectedOption,selectedVehicle, vehicletype,profile_image, license, insurance, car_image,vehiclesbytype,selectedVehiclebytype} = this.state;

  	
	  	let vehicles = [];
	    let file ='';
	  	if(profile_image && profile_image!=='' && profile_image!== null){
		    file = profileURL + "/" + profile_image;
	    }
	    let driverlic ='';
	  	if(license && license!=='' && license!== null){
		    driverlic = licenseURL + "/" + license;
	    }
	     let driverins ='';
	  	if(insurance && insurance!=='' && insurance!== null){
		    driverins = insuranceURL + "/" + insurance;
	    }

	     let carimage ='';
	  	if(car_image && car_image!=='' && car_image!== null){
		    carimage = drivercarURL + "/" + car_image;
	    }


  	if(vehicletype !== undefined){
	  	const vehicleArray = vehicletype.map ((vehicleType, index) => ({
		id: index,
		name: vehicleType,
		}));
		// Dynamically create select list
		vehicleArray.map(item =>
		vehicles.push({ label: item.name.vehicle_type, value: item.name.vehicle_id }),
		);
   }

    return (
      <div className="wrapper"> 
 
	  <Header />
    <MenuSidebar currentpage="drivers" />  	
	<div className="content">	
		<div className="content-wrapper">
			<div className="form-wrapper">
			<div className="success_message"></div>
			<div className="title">
				<h4>Edit Details</h4>
				
			</div>
			 <form className="edit" id="edit" onSubmit={ e => { this.handleFormSubmit(this); e.preventDefault(); }} encType="multipart/form-data" >
			<div className="form-row">
			<div className="form-left">
		    	<div className="form-group">
					<label>User Name:</label>
				    <input type="text" name="username" onChange={this.handleInputChange} className="form-control" value={this.state.username} disabled="true"/>
					<div className="errorusername"></div>
				</div>
				<div className="form-group">
					<label>Last Name:</label>
				    <input type="text" name="lastname" onChange={this.handleInputChange} className="form-control" value={this.state.lastname} />
					<div className="errorlastname"></div>
				</div>
				<div className="form-group">
					<label>Phone number:</label>
				    <input type="text" name="phoneno" onChange={this.handleInputChange} className="form-control" value={this.state.phoneno} />
					<div className="errorphoneno"></div>
				</div>
				<div className="form-group">
					<label>Driving License</label>
					<input type="text" name="driving_license" onChange={this.handleInputChange} className="form-control" value={this.state.driving_license} />
					<div className="errordrivinglicense"></div>
				</div>
				<div className="form-group">
					<label>Car Color:</label>
				    <input type="text" name="car_color" onChange={this.handleInputChange} className="form-control" value={this.state.car_color} />
				</div>
				<div className="form-group">
					<label>Current password:</label>
				    <input type="password" name="password" onChange={this.handleInputChange} className="form-control" value={this.state.password} />
					<div className="errorpassword"></div>
				</div>
				<div className="form-group">
					<label>Reset password:</label>
				    <input type="text" name="changepassword" onChange={this.handleInputChange} className="form-control" value={this.state.changepassword} disabled={this.state.disablepasssword}/>
					<div className="errorchangepaswd"></div>
				</div>
				</div>
			 <div className="form-right">
				<div className="form-group">
					<label>First Name:</label>
				    <input type="text" name="firstname" onChange={this.handleInputChange} className="form-control" value={this.state.firstname} />
					<div className="errorfirstname"></div>
				</div>
				<div className="form-group">
					<label>Email:</label>
						<input type="text" name="email" onChange={this.handleInputChange} className="form-control" value={this.state.email} />
					<div className="erroremail"></div>
				</div>
				<div className="form-group vehicles-select">
					<label>Vehicle Type:</label>
				     <Select 
	                     options={vehicles}  
	                     value={selectedVehicle?selectedVehicle:{ value: '0', label: 'Select Vehicle Type' }}
	                     onChange={this.handleChangeVehicle}
	                     placeholder="Select Vehicle Type" />
					<div className="errorvehicletype"></div>
				</div>
				<div className="form-group vehicles-select">
					<label>Vehicle:</label>
				     <Select 
						options={vehiclesbytype}  
						value={selectedVehiclebytype?selectedVehiclebytype:{ value: '0', label: 'Select Vehicle' }}
						onChange={this.handleChangeVehiclebytype}
	                     placeholder="Select Vehicle Type" />
					<div className="errorvehicletype"></div>
				</div>
				<div className="form-group">
					<label>Car Number:</label>
				    <input type="text" name="car_number" onChange={this.handleInputChange} className="form-control" value={this.state.car_number} />
					<div className="errorcar_number"></div>
				</div>
				<div className="form-group">					
					<label>Status:</label>
						   <Select 
						   value={selectedOption?selectedOption:{ value: 'active', label: 'Active'}}
                            options={lang.common.status_option} 
                            onChange={this.handleChange}
                            />
				</div>
				</div>
			</div>		
			<div className="drivers-deatils">
			<div className="title">
				<h4>Details given by drivers</h4>
			</div>
			<div className="form-row">
				<div className="form-group">
				    <span>Profile verification</span>
					<input onChange={this.handleVerified} type="checkbox"  name="enable_profile" checked={this.state.profile_checked}/>
					<div className="profile-photo">
					<img
					src={file}
					type='application/pdf'
					title='title'
					/>
					</div>
				</div>
				<div className="form-group">
				    <span>Car Image verification</span>
					<input onChange={this.handleVerified} type="checkbox"  name="enable_carimage" checked={this.state.car_image_checked}/>
					<div className="profile-photo">
					<img
					src={carimage}
					type='application/pdf'
					title='title'
					/>
					</div>
				</div>
				<div className="form-group">
				    <span>License verification</span>
					<input onChange={this.handleVerified} type="checkbox"  name="enable_license" checked={this.state.license_checked}/>					
					<a target="_blank" className="driver-icon" href={driverlic}>
					<img
					src={pdficon}
					type='application/pdf'
					title='title'
					/></a>
				</div>
			<div className="form-group">
			  <span>Insurance verification</span>
					<input onChange={this.handleVerified} type="checkbox"  name="enable_insurance" checked={this.state.insurance_checked}/>
					<a target="_blank" className="driver-icon" href={driverins}>
					<img
					src={pdficon}
					type='application/pdf'
					title='title'
					/></a>
			</div>
			
			<div className="form-group">
				    <span>Bank Account/Card Verification</span>
					<input onChange={this.handleVerified} type="checkbox" name="enable_bankaccdetail" checked={this.state.bankaccdetail_checked}/>
					
				</div>
				
			</div>
		</div>

			<div className="btn-group export">	
				  <button className="btn btn_orange btn_minwid login_submit animate-btn2" type="submit" disabled={(this.state.Loading ===true)?true:false}> {this.state.Loading ===true &&
                                        <span className="load-data"></span> 
                                        }Submit
                   </button>
			</div>
			</form>
			</div>
				
		</div>	
	</div>
		 	
    </div>
    );
  }
}


const mapStateTopProps = (state) => {
  return {
  	vehicletype    : state.vehicletype,
  	driverdetail   : state.driverdetail,
  	driveredit     : state.driveredit,
  	vechiclebytype : state.vechiclebytype
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
  	 getVehicleType: (adminID) =>{
       dispatch({ type: GET_VEHICLETYPE, adminID});
    },
  	getDriverDetail: (driverid) =>{
       dispatch({ type: GET_DRIVERDETAIL,driverid });
    },
    getEditDriver: (formPayload) =>{
       dispatch({ type: GET_EDITDRIVER, formPayload});
    },
    getVehicleByType: (vehicleID) =>{
       dispatch({ type: GET_VEHICLEBYTYPE, vehicleID});
    }
  }
}
export default connect(mapStateTopProps, mapDispatchToProps)(withRouter(Edit));