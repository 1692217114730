 /*local */
/*
export const apiUrl      = "http://localhost/carbooking/api/";
export const baseUrl      = "http://localhost/carbooking";
export const carImageUrl      = "http://localhost/carbooking/media/cars";
export const sampleFileUrl      = "http://localhost/carbooking/media/sample_file/";
export const licenseURL       = "http://localhost/carbooking/media/driverlicense";
export const insuranceURL       = "http://localhost/carbooking/media/driverinsurance";
export const profileURL       = "http://localhost/carbooking/media/driverprofile";
export const drivercarURL       = "http://localhost/carbooking/media/drivercarimage";
export const dummycarUrl      = 'http://localhost/carbooking/media/others';
export const galleryUrl      = 'http://localhost/carbooking/media/gallery/';
export const favouritesUrl      = 'http://localhost/carbooking/media/favourites';
*/

/*Live */


export const apiUrl           = "https://car-c.uxt.design/carbooking/api/";
export const baseUrl          = "https://car-c.uxt.design/carbooking";
export const carImageUrl      = "https://car-c.uxt.design/carbooking/media/cars";
export const licenseURL       = "https://car-c.uxt.design/carbooking/media/driverlicense";
export const insuranceURL     = "https://car-c.uxt.design/carbooking/media/driverinsurance";
export const profileURL       = "https://car-c.uxt.design/carbooking/media/driverprofile";
export const drivercarURL     = "https://car-c.uxt.design/carbooking/media/drivercarimage";
export const sampleFileUrl    = "https://car-c.uxt.design/carbooking/media/sample_file/";
export const dummycarUrl      = 'https://car-c.uxt.design/carbooking/media/others';
export const galleryUrl       = 'http://car-c.uxt.design/carbooking/media/gallery/';
export const favouritesUrl    = 'https://car-c.uxt.design/carbooking/media/favourites';
